export default {
  'app.menu.dashboard': 'Dashboard',
  'app.menu.companies': 'Companies',
  'app.menu.companyDetail': 'Company Detail',
  'app.menu.quotes': 'Quotes',
  'app.menu.quoteDetail': 'Quote Detail',
  'app.menu.salesReps': 'Sales Reps',
  'app.menu.orders': 'Orders',
  'app.menu.advancedQuantityManagement': 'Advanced Quantity Management',
  'app.menu.integration': 'Integration',
  'app.menu.settings': 'Settings',
  'app.menu.users': 'Users',
  'app.menu.roles': 'Roles & Permissions',
  'app.menu.role': 'Role',
}
