import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  SnackbarContent,
  IconButton,
  createStyles,
  withStyles,
} from '@material-ui/core'
import {
  amber,
  green,
} from '@material-ui/core/colors'
import {
  CheckCircle,
  Error,
  Info,
  Warning,
  Close as CloseIcon,
} from '@material-ui/icons'

const styles = createStyles((theme) => ({
  root: {
    margin: `${theme.spacing(1)}px 0`,
    maxWidth: 500,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
}

class B3SnackbarContent extends Component {
  static propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    duration: PropTypes.number,
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
      message: PropTypes.node.isRequired,
      icon: PropTypes.string.isRequired,
      iconVariant: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }

  static defaultProps = {
    message: '',
    duration: 0,
    onClose: () => {},
  }

  componentDidMount() {
    const {
      duration,
    } = this.props
    if (duration) {
      this.autoClose = setTimeout(this.handleClose, duration)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoClose)
  }

  handleClose = () => {
    const {
      onClose,
      id,
    } = this.props
    onClose({
      id,
    })
  }

  render() {
    const {
      message,
      variant,
      classes,
    } = this.props

    const Icon = variantIcon[variant]
    return (
      <SnackbarContent
        className={clsx(classes[variant], classes.root)}
        aria-describedby="client-snackbar"
        message={(
          <span className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {Object.prototype.toString.call(message).substr(-6, 5) === 'Error' ? String(message) : message }
          </span>
        )}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    )
  }
}

export default withStyles(styles)(B3SnackbarContent)
