import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  makeStyles,
  InputBase,
  Divider,
  IconButton,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    height: 33,
  },
  active: {
    borderColor: `${theme.palette.primary.main}`,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: theme.typography.fontWeightLight,
  },
  iconButton: {
    padding: 2,
    margin: 2,
  },
  divider: {
    height: 20,
    margin: 3,
  },
  dividerActive: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}))

const B3Search = (props) => {
  const {
    placeholder,
    value,
    onSearch,
    onChange,
    disabled,
  } = props
  const classes = useStyles()
  const [isFocus, setFocus] = useState(false)

  const handleSearchChange = (e) => {
    onChange(e.target.value)
  }

  const handleClear = () => {
    onChange('')
    onSearch()
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <div className={clsx(
      classes.root,
      {
        [classes.active]: isFocus,
      },
    )}
    >
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={handleSearchChange}
        onKeyUp={handleKeyUp}
        value={value}
        disabled={disabled}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {
        value && (
          <IconButton
            className={classes.iconButton}
            onClick={handleClear}
          >
            <ClearIcon color={isFocus ? 'primary' : ''} />
          </IconButton>
        )
      }
      <Divider
        className={clsx(
          classes.divider,
          {
            [classes.dividerActive]: isFocus,
          },
        )}
        orientation="vertical"
      />
      <IconButton
        className={classes.iconButton}
        onClick={onSearch}
        disabled={disabled}
      >
        <SearchIcon />
      </IconButton>
    </div>
  )
}

B3Search.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

B3Search.defaultProps = {
  placeholder: 'search…',
  value: '',
  onSearch: () => {},
  onChange: () => {},
  disabled: false,
}

export default B3Search
