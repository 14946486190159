import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'

function B3Select(props) {
  const {
    options,
    value,
    onChange,
    disabled,
    label,
    required = false,
    onOpen,
    error,
    helperText,
  } = props

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <FormControl
      fullWidth
      required={required}
      error={error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onOpen={onOpen}
      >
        {
          options.map((option) => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))
        }
      </Select>
      {
        helperText.length > 0 && (
          <FormHelperText>{helperText}</FormHelperText>
        )
      }
    </FormControl>
  )
}

B3Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  onOpen: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

B3Select.defaultProps = {
  value: '',
  onChange: noop,
  disabled: false,
  label: 'Please Select',
  required: false,
  onOpen: noop,
  error: false,
  helperText: '',
}

export default B3Select
