import React from 'react'
import PropTypes from 'prop-types'
import { DropzoneArea } from 'material-ui-dropzone'
import { noop } from 'lodash'
import uuid from 'uuid/v1'

import {
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'

import {
  B3ConfirmDialog,
  B3Spin,
} from '..'

import locales from '../../locales/en-US'

const useStyles = makeStyles((theme) => ({
  dropzonePadding: {
    padding: `0 ${theme.spacing(5)}px`,
    minHeight: 'auto',
    borderWidth: 1,
    paddingBottom: '1em',
    marginBottom: '1em',
  },
  dropzoneParagraph: {
    fontSize: theme.typography.h5.fontSize,
    color: '#656565',
  },
  downloadTitle: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

const B3CsvUpload = (props) => {
  const classes = useStyles()

  const {
    files,
    filesLimitation,
    dropzoneText,
    showPreviews,
    dialogTitle,
    subtitle,
    onCancel,
    onConfirm,
    onChange,
    isUpLoading,
    templateUrl,
    acceptedFiles,
    showAlerts,
  } = props

  const getBlob = (buffer, format) => {
    try {
      return new Blob(buffer, {
        type: format,
      })
    } catch (e) {
      const blob = new (window.BlobBuilder || window.WebKitBlobBuilder || window.MSBlobBuilder)()

      buffer.forEach((buf) => {
        blob.append(buf)
      })

      return blob.getBlob(format)
    }
  }

  const convertDataToBlob = (file) => {
    const bytes = atob(file.data.split(',')[1])
    const bytesCode = new ArrayBuffer(bytes.length)
    const byteArray = new Uint8Array(bytesCode)

    for (let i = 0; i < bytes.length; i += 1) {
      byteArray[i] = bytes.charCodeAt(i)
    }

    const blob = getBlob([bytesCode], file.type)
    return blob
  }

  const handleFileChange = (evt) => {
    const $previewEl = document.querySelector('.MuiDialogContent-root .MuiGrid-container')
    if ($previewEl) $previewEl.style.setProperty('display', 'none')
    evt.forEach((file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function onload() {
        const base64Data = {
          data: this.result,
          type: file.type,
        }
        onChange({
          id: uuid(),
          file,
          base64Data,
          blob: convertDataToBlob(base64Data),
        })
      }
    })

    if (!evt.length) onChange(null)
  }

  const handleDialogClose = () => onCancel()

  const handleDialogConfirm = () => {
    onConfirm(files)
  }

  const handleDownloadTemplate = () => {
    window.open(templateUrl, '_blank')
  }

  return (
    <B3ConfirmDialog
      isopen
      title={dialogTitle}
      onClose={handleDialogClose}
      onConfirm={handleDialogConfirm}
      isSpinning={isUpLoading}
      isSubmitDisabled={isUpLoading || !files.length}
      isCancelDisabled={isUpLoading}
      confirmText="Save"
    >
      <DialogContent>
        <B3Spin
          isSpinning={isUpLoading}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
          >
            {subtitle}
          </Typography>
          <Typography
            className={classes.downloadTitle}
            variant="subtitle1"
            color="primary"
            gutterBottom
            onClick={handleDownloadTemplate}
          >
            Download CSV Template
          </Typography>
          <DropzoneArea
            onChange={handleFileChange}
            showAlerts={showAlerts}
            acceptedFiles={acceptedFiles}
            filesLimit={filesLimitation}
            dropzoneText={dropzoneText}
            showPreviewsInDropzone={false}
            showPreviews={showPreviews}
            useChipsForPreview
            showFileNames
            dropzoneClass={classes.dropzonePadding}
            dropzoneParagraphClass={classes.dropzoneParagraph}
          />
        </B3Spin>
      </DialogContent>
    </B3ConfirmDialog>
  )
}

B3CsvUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  filesLimitation: PropTypes.number,
  dropzoneText: PropTypes.string,
  showPreviews: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onChange: PropTypes.func,
  dialogTitle: PropTypes.string,
  subtitle: PropTypes.string,
  isUpLoading: PropTypes.bool,
  templateUrl: PropTypes.string,
  showAlerts: PropTypes.bool,
}

B3CsvUpload.defaultProps = {
  files: [],
  acceptedFiles: [],
  filesLimitation: 1,
  dropzoneText: locales['app.tip.uploadCsvPlaceholder'],
  showPreviews: true,
  dialogTitle: '',
  subtitle: '',
  onCancel: noop,
  onConfirm: noop,
  onChange: noop,
  isUpLoading: false,
  templateUrl: '',
  showAlerts: false,
}

export default B3CsvUpload
