import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  styled,
  DialogContent,
  DialogContentText,
  Tooltip,
  Box,
  ButtonGroup,
} from '@material-ui/core'

import {
  Publish as ImportIcon,
  GetApp as ExportIcon,
} from '@material-ui/icons'

import {
  B3Card,
  B3ConfirmDialog,
  B3CsvUpload,
} from '../../components'

import {
  toggleSalesRepCsvReadyStatus,
  toggleShouldSalesRepListUpdate,
} from '../../store/actions/salesRep'

import SalesRepForm from './SalesRepForm'
import SalesRepTable from './SalesRepTable'
import SalesRepsPopup from './SalesRepsPopup'
import b3request from '../../service'
import { snackbar } from '../../utils'
import locales from '../../locales/en-US'
import store from '../../store'

const TitleWrapper = styled('div')((props) => ({
  display: 'flex',
  alignItems: 'center',

  '& > span.label': {
    paddingRight: props.theme.spacing(2),
  },
}))

const ExtraContainer = styled('div')((props) => ({
  display: 'flex',
  '& .mr': {
    marginRight: props.theme.spacing(),
  },
}))

const mapStateToProps = (state) => {
  const {
    salesRep: {
      isImportSalesRepCsvReady,
      shouldSalesRepListUpdate,
      salesRepTemplateUrl,
      isExportSalesRepCsvReady,
    },
  } = state
  return {
    isImportSalesRepCsvReady,
    shouldSalesRepListUpdate,
    salesRepTemplateUrl,
    isExportSalesRepCsvReady,
  }
}

@connect(mapStateToProps, {
  toggleSalesRepCsvReadyStatus,
  toggleShouldSalesRepListUpdate,
})
class SalesRep extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isImportSalesRepCsvReady: PropTypes.oneOf(['0', '1']).isRequired,
    shouldSalesRepListUpdate: PropTypes.bool.isRequired,
    toggleSalesRepCsvReadyStatus: PropTypes.func.isRequired,
    toggleShouldSalesRepListUpdate: PropTypes.func.isRequired,
    salesRepTemplateUrl: PropTypes.string.isRequired,
    isExportSalesRepCsvReady: PropTypes.oneOf(['0', '1']).isRequired,
  }

  constructor() {
    super()
    this.state = {
      groupBy: '1',
      orderBy: 'desc',
      isSaleRepFormShow: false,
      isDeleteSalesRepDialogShow: false,
      isDeletingSalesRep: false,
      currentSalesRepId: '',
      list: [],
      isLoading: false,
      pagination: {
        totalCount: 0,
        offset: 0,
        limit: 10,
      },
      isSalesRepImportOpen: false,
      salesRepImportFiles: [],
      salesRepUploadTitle: 'Sales Rep Bulk Creation',
      salesRepUploadSubtitle: 'Create new sales reps or assign companies to sales rep using the CSV file.',
      isSalesRepImportUploading: false,
      isSalesRepsPopupOpen: false,
      popupId: '',
    }
  }

  componentDidMount() {
    this.getSalesReps()
    this.unsubscribe = store.subscribe(() => {
      const { shouldSalesRepListUpdate } = this.props
      if (shouldSalesRepListUpdate) this.getSalesReps()
    })
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get allIds() {
    const {
      groupBy,
      list,
    } = this.state
    return groupBy === '1' ? list.map((item) => item.salesRepId) : list.map((item) => item.companyId)
  }

  get deleteSalesRepDialogDescription() {
    const {
      groupBy,
      list,
      currentSalesRepId,
    } = this.state
    if (groupBy !== '1') return ''
    const {
      firstName = '',
      lastName = '',
    } = list.filter((salesRep) => salesRep.salesRepId === currentSalesRepId)[0]
    return `${locales['app.tips.salesRep.deleteConfirm']}: ${firstName} ${lastName}?`
  }

  getSalesReps = async () => {
    const { toggleShouldSalesRepListUpdate } = this.props
    this.setState({
      isLoading: true,
    })
    const {
      groupBy,
      orderBy,
      pagination: {
        offset,
        limit,
      },
    } = this.state
    const {
      list = [],
      pagination,
    } = await b3request.salesReps.getSalesRepsAndCompanies({
      groupBy,
      orderBy,
      offset,
      limit,
    })
    this.setState({
      list,
      pagination,
      isLoading: false,
    })
    toggleShouldSalesRepListUpdate(false)
  }

  handleGroupChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      groupBy: event.target.value,
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
  }

  handleSalesRepFromShow = () => {
    this.setState({
      isSaleRepFormShow: true,
    })
  }

  handleSalesRepFormCancel = (refresh) => {
    this.setState({
      isSaleRepFormShow: false,
      currentSalesRepId: '',
    })
    if (refresh) this.getSalesReps()
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getSalesReps)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getSalesReps)
  }

  toggleOrderBy = () => {
    const {
      orderBy,
      pagination,
    } = this.state
    this.setState({
      orderBy: orderBy === 'desc' ? 'asc' : 'desc',
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
  }

  handleSalesRepDeleteDialogShow = (currentSalesRepId) => () => {
    this.setState({
      isDeleteSalesRepDialogShow: true,
      currentSalesRepId,
    })
  }

  handleSalesRepDeleteCancel = () => {
    this.setState({
      isDeleteSalesRepDialogShow: false,
      currentSalesRepId: '',
    })
  }

  handleSalesRepDelete = async () => {
    this.setState({
      isDeletingSalesRep: true,
    })
    const {
      currentSalesRepId,
    } = this.state
    try {
      await b3request.salesReps.deleteSalesRepById(currentSalesRepId)
      snackbar.success(locales['app.tips.salesRep.deleteSalesSuccessfully'])
      this.getSalesReps()
    } catch (error) {
      snackbar.error(locales['app.tips.salesRep.deleteSalesRepFailed'])
    }
    this.setState({
      isDeleteSalesRepDialogShow: false,
      isDeletingSalesRep: false,
    })
  }

  handleSalesRepEdit = (currentSalesRepId) => () => {
    this.setState({
      isSaleRepFormShow: true,
      currentSalesRepId,
    })
  }

  toCompany = (companyId) => () => {
    const {
      history,
    } = this.props
    history.push(`/companies/${companyId}`)
  }

  handleSalesRepImportOpen = () => {
    this.setState({
      isSalesRepImportOpen: true,
      isSalesRepImportUploading: false,
    })
  }

  hhandleSalesRepImportChange = (file) => {
    let salesRepImportFiles = [file]

    if (!file) salesRepImportFiles = []
    this.setState({
      salesRepImportFiles,
    })
  }

  handleSalesRepmportCancel = () => {
    this.setState({
      isSalesRepImportOpen: false,
      salesRepImportFiles: [],
    })
  }

  handleSalesRepImportConfirm = async (files) => {
    const { toggleSalesRepCsvReadyStatus } = this.props

    this.setState({
      isSalesRepImportUploading: true,
    })

    const stateKey = 'isImportSalesRepCsvReady'

    const data = new FormData()
    data.append('salesRepFile', files[0].file)

    try {
      const { isReady } = await b3request.salesReps.uploadSalesRepInfoCsv(data) || {}
      if (isReady !== undefined) {
        toggleSalesRepCsvReadyStatus({ stateKey, status: isReady })
        snackbar.success(locales['app.tips.salesRep.import.uploadSuccess'])
        this.setState({
          isSalesRepImportOpen: false,
          salesRepImportFiles: [],
        })
      }
    } catch (error) {
      snackbar.error(error.message)
    }

    this.setState({
      isSalesRepImportUploading: false,
    })
  }

  handleSalesRepPopUpShow = (salesRep) => () => {
    this.setState({
      isSalesRepsPopupOpen: true,
      popupId: salesRep.salesRepId || salesRep.companyId,
    })
  }

  handleSalesRepsPopupClose = () => {
    this.setState({
      isSalesRepsPopupOpen: false,
    })
  }

  handleExport = async () => {
    this.setState({
      isLoading: true,
    })
    const { toggleSalesRepCsvReadyStatus } = this.props
    try {
      await b3request.salesReps.exportSalesRepCsv()
      snackbar.info(locales['app.tips.csv.export'])
      toggleSalesRepCsvReadyStatus({ stateKey: 'isExportSalesRepCsvReady', status: '0' })
    } catch (error) {
      snackbar.error(locales['app.tips.csv.export.failed'])
      toggleSalesRepCsvReadyStatus({ stateKey: 'isExportSalesRepCsvReady', status: '1' })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const {
      groupBy,
      isSaleRepFormShow,
      list,
      isLoading,
      pagination,
      orderBy,
      isDeleteSalesRepDialogShow,
      isDeletingSalesRep,
      currentSalesRepId,
      isSalesRepImportOpen,
      salesRepImportFiles,
      salesRepUploadTitle,
      salesRepUploadSubtitle,
      isSalesRepImportUploading,
      popupId,
      isSalesRepsPopupOpen,
    } = this.state

    const {
      isImportSalesRepCsvReady,
      salesRepTemplateUrl,
      isExportSalesRepCsvReady,
    } = this.props

    return (
      <B3Card
        title={(
          <TitleWrapper>
            <span className="label">
              Group by:
            </span>
            <RadioGroup
              row
              name="groupBy"
              size="small"
              value={groupBy}
              onChange={this.handleGroupChange}
            >
              <FormControlLabel disabled={isLoading} value="1" control={<Radio />} label="Sales Rep" />
              <FormControlLabel disabled={isLoading} value="2" control={<Radio />} label="Company" />
            </RadioGroup>
          </TitleWrapper>
        )}
        extra={
          () => {
            const AddNew = () => (groupBy === '1' ? (
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleSalesRepFromShow}
                disabled={isLoading}
              >
                Add New
              </Button>
            ) : null)
            return (
              <ExtraContainer>
                <Box mr={1}>
                  <ButtonGroup>
                    <React.B3PermissionContainer
                      permissions={['13200']}
                    >
                      {(props) => (
                        <Tooltip
                          title="Import Sales reps to your store"
                          placement="left-start"
                        >
                          <Button
                            {...props}
                            variant="contained"
                            color="primary"
                            disabled={isImportSalesRepCsvReady === '0'}
                            onClick={this.handleSalesRepImportOpen}
                          >
                            <ImportIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </React.B3PermissionContainer>
                    <React.B3PermissionContainer
                      permissions={['13200']}
                    >
                      {(props) => (
                        <Tooltip title="Export your Sales Reps to a CSV file" placement="top-start">
                          <Button
                            {...props}
                            variant="contained"
                            color="primary"
                            onClick={this.handleExport}
                            disabled={isExportSalesRepCsvReady === '0'}
                          >
                            <ExportIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </React.B3PermissionContainer>
                  </ButtonGroup>
                </Box>
                <React.B3PermissionContainer
                  permissions={['11107', '11108', '11204', '13301']}
                >
                  <AddNew />
                </React.B3PermissionContainer>
              </ExtraContainer>
            )
          }
        }
        isLoading={isLoading}
      >
        <SalesRepTable
          list={list}

          groupBy={groupBy}
          pagination={pagination}
          orderBy={orderBy}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          toggleOrderBy={this.toggleOrderBy}
          onRemoveSalesRep={this.handleSalesRepDeleteDialogShow}
          onEditSalesRep={this.handleSalesRepEdit}
          onShowSalesRepPopUp={this.handleSalesRepPopUpShow}
          toCompany={this.toCompany}
        />
        {
          isSaleRepFormShow && (
            <SalesRepForm
              onCancel={this.handleSalesRepFormCancel}
              id={currentSalesRepId}
            />
          )
        }
        {
          isDeleteSalesRepDialogShow && (
            <B3ConfirmDialog
              onClose={this.handleSalesRepDeleteCancel}
              onConfirm={this.handleSalesRepDelete}
              isSubmitDisabled={isDeletingSalesRep}
              isCancelDisabled={isDeletingSalesRep}
              isSpinning={isDeletingSalesRep}
            >
              <DialogContent>
                <DialogContentText>
                  {this.deleteSalesRepDialogDescription}
                </DialogContentText>
              </DialogContent>
            </B3ConfirmDialog>
          )
        }
        {
          isSalesRepImportOpen && (
            <B3CsvUpload
              files={salesRepImportFiles}
              dialogTitle={salesRepUploadTitle}
              subtitle={salesRepUploadSubtitle}
              templateUrl={salesRepTemplateUrl}
              onCancel={this.handleSalesRepmportCancel}
              onConfirm={this.handleSalesRepImportConfirm}
              onChange={this.hhandleSalesRepImportChange}
              isUpLoading={isSalesRepImportUploading}
            />
          )
        }
        {
          isSalesRepsPopupOpen && (
          <SalesRepsPopup
            groupBy={groupBy}
            popupId={popupId}
            onClose={this.handleSalesRepsPopupClose}
          />
          )
        }
      </B3Card>
    )
  }
}

export default SalesRep
