import b3request from './base'

// get quotes
export const getQuotes = (params) => b3request.get('/api/v2/quotes', { params })

// get quote detail
export const getQuoteById = (id) => b3request.get(`/api/v2/quotes/${id}`)

// post sent email by quoteId
export const sentEmail = (data = {
  email: '',
  quoteId: '',
}) => b3request.post('/api/v2/emails/quotes', data)

// update quote status

export const updateQuoteStatusById = (id, params) => b3request.put(`/api/v2/backend/quotes/${id}`, params)
