import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Formik,
} from 'formik'

import * as Yup from 'yup'

import { isEmpty } from 'lodash'

import {
  DialogContent,
  TextField,
  Grid,
  styled,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@material-ui/core'

import {
  B3ConfirmDialog,
  B3Spin,
  StandardB3CountryGroup,
} from '../../components'

import b3request from '../../service'

import { snackbar } from '../../utils'

import locales from '../../locales/en-US'

import { re } from '../../constants'

const FormContainer = styled(Grid)({
  minWidth: 400,
  marginBottom: 16,
})

const IndentFormControlLabel = styled(FormControlLabel)((props) => ({
  paddingLeft: props.theme.spacing(3),
}))

/**
 * define address form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} name key of each field
 * @property {string} label display name
 * @property {string} initialValue initial value of each field
 * @property {boolen} isRequired if the field value is required
 * @property {object} validator Yup object for validataion
 * @property {number} xs the gird of Mui for layout
 */

/**
 * @const
 * @type {Array.<formFieldSchema>} address book fields
 */
const addressFields = [{
  name: 'label',
  label: 'Address Label',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .max(200),
  xs: 12,
}, {
  name: 'firstName',
  label: 'First Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(50)
    .required(locales['app.validation.errors.required.companyName']),
  xs: 6,
}, {
  name: 'lastName',
  label: 'Last Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(50)
    .required(locales['app.validation.errors.required.lastName']),
  xs: 6,
}, {
  name: 'addressLine1',
  label: 'Address Line 1',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(200)
    .required(locales['app.validation.errors.required.addressLine1']),
  xs: 12,
}, {
  name: 'addressLine2',
  label: 'Address Line 2',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .max(200),
  xs: 12,
}, {
  name: 'city',
  label: 'City',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(200)
    .required(locales['app.validation.errors.required.city']),
  xs: 4,
}, {
  name: 'zipCode',
  label: 'Zipcode',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(50)
    .required(locales['app.validation.errors.required.zipCode']),
  xs: 4,
}, {
  name: 'phoneNumber',
  label: 'Phone Number',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber']),
  xs: 4,
}, {
  name: 'country',
  label: 'Country',
  initialValue: {
    countryName: '',
    countryCode: '',
  },
  isRequired: true,
  validator: Yup.object()
    .shape({
      countryName: Yup.string().required(locales['app.validation.errors.required.country']),
      countryCode: Yup.string(),
    })
    .required(locales['app.validation.errors.required.country']),
  xs: 6,
}, {
  name: 'state',
  label: 'State',
  initialValue: {
    stateName: '',
    stateCode: '',
  },
  isRequired: true,
  validator: Yup.object()
    .shape({
      stateName: Yup.string().required(locales['app.validation.errors.required.state']),
      stateCode: Yup.string(),
    })
    .required(locales['app.validation.errors.required.state']),
  xs: 6,
}]

/**
 * generate form validation schema shape
 *
 * @const {object} addressFilesSchema
 */
const addressFilesSchema = addressFields.reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {})

/**
 * generate form initial fields value
 *
 * @const {object} addressInitialValues
 */
const addressInitialValues = addressFields.reduce((values, currentItem) => {
  values[currentItem.name] = currentItem.initialValue
  return values
}, {})

class AddressBookForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    addressId: PropTypes.string,
    companyId: PropTypes.string.isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  static defaultProps = {
    addressId: '',
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      addressFieldsInitialValues: {
        ...addressInitialValues,
        isShipping: '0',
        isBilling: '0',
        isDefaultShipping: '0',
        isDefaultBilling: '0',
        label: '',
      },
      states: [],
    }
  }

  componentDidMount() {
    this.getAddress()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  get isAddNew() {
    const { addressId } = this.props
    return !addressId
  }

  get snackbarSuccessInfo() {
    return locales[`app.tips.addressBook.${this.isAddNew ? 'addNew' : 'update'}AddressBookSuccessfully`]
  }

  get dialogTitle() {
    return `${this.isAddNew ? 'Add' : 'Update'} Address`
  }

  getAddress = async () => {
    if (this.isAddNew) {
      return false
    }
    this.setState({
      isLoading: true,
    })

    const {
      companyId,
      addressId,
      countries,
    } = this.props

    const {
      addressFieldsInitialValues,
    } = this.state

    try {
      const data = await b3request.companies.getAddressById(companyId, addressId)
      const { states = [] } = countries.filter((country) => country.countryName === data.country.countryName)[0] || {}
      return this.setState({
        addressFieldsInitialValues: {
          ...addressFieldsInitialValues,
          ...data,
        },
        states,
      })
    } catch (error) {
      return snackbar.error('Unknown Error!')
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const {
      onCancel,
      companyId,
      addressId,
      countries = [],
    } = this.props
    const {
      addressFieldsInitialValues,
      isLoading,
      states = [],
    } = this.state
    return (
      <Formik
        initialValues={addressFieldsInitialValues}
        enableReinitialize
        validationSchema={Yup.object().shape(addressFilesSchema)}
        onSubmit={async (values, { setSubmitting }) => {
          // different ajax call between add new address and update address
          const request = this.isAddNew ? b3request.companies.createAddress(companyId, values) : b3request.companies.updateAddress(companyId, addressId, values)
          const resp = await request
          // if we need to reload default addresses.
          const isCompanyDefaultAddressChanged = (values.isDefaultBilling === '1'
            || values.isDefaultShipping === '1'
            || values.isDefaultBilling !== addressFieldsInitialValues.isDefaultBilling
            || values.isDefaultShipping !== addressFieldsInitialValues.isDefaultShipping
          )
          if (resp.addressId) {
            onCancel(true, isCompanyDefaultAddressChanged)
            snackbar.success(this.snackbarSuccessInfo)
          }
          setSubmitting(false)
        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldValue,
          }) => {
            /**
             * Generate field props
             * @function getFieldProps
             * @param {string} label
             * @param {string} name
             * @param {boolean} required
             */
            const getFieldProps = (label, name, required) => ({
              label,
              name,
              value: values[name],
              onChange: handleChange,
              onBlur: handleBlur,
              error: touched[name] && Boolean(errors[name]),
              helperText: touched[name] ? errors[name] : '',
              required,
              fullWidth: true,
              disabled: isSubmitting,
            })

            const handleDialogClose = () => {
              // can't make the dialog close if ajax is calling.
              if (isSubmitting) return false
              return onCancel()
            }

            return (
              <B3ConfirmDialog
                isOpen
                onClose={handleDialogClose}
                title={this.dialogTitle}
                confirmText="save"
                onConfirm={handleSubmit}
                isCancelDisabled={isSubmitting}
                isSubmitDisabled={isSubmitting || !isEmpty(errors) || !dirty}
                isSpinning={isSubmitting || isLoading}
              >
                <DialogContent>
                  <B3Spin
                    isSpinning={isLoading}
                  >
                    <FormLabel>Address Type:</FormLabel>
                    <Grid
                      container
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.isShipping === '1'}
                              onChange={() => {
                                if (values.isShipping === '1') {
                                  setFieldValue('isDefaultShipping', '0')
                                }
                                setFieldValue('isShipping', values.isShipping === '0' ? '1' : '0')
                              }}
                              value={values.isShipping}
                              disabled={isSubmitting}
                            />
                          )}
                          label="Shipping"
                        />
                        {
                          values.isShipping === '1' && (
                            <IndentFormControlLabel
                              control={(
                                <Checkbox
                                  checked={values.isDefaultShipping === '1'}
                                  onChange={() => {
                                    setFieldValue('isDefaultShipping', values.isDefaultShipping === '0' ? '1' : '0')
                                  }}
                                  value={values.isDefaultShipping}
                                  disabled={isSubmitting}
                                />
                              )}
                              label="Default Shipping Address"
                            />
                          )
                        }
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.isBilling === '1'}
                              onChange={() => {
                                if (values.isBilling === '1') {
                                  setFieldValue('isDefaultBilling', '0')
                                }
                                setFieldValue('isBilling', values.isBilling === '0' ? '1' : '0')
                              }}
                              value={values.isBilling}
                              disabled={isSubmitting}
                            />
                          )}
                          label="Billing"
                        />
                        {
                          values.isBilling === '1' && (
                            <IndentFormControlLabel
                              control={(
                                <Checkbox
                                  checked={values.isDefaultBilling === '1'}
                                  onChange={() => {
                                    setFieldValue('isDefaultBilling', values.isDefaultBilling === '0' ? '1' : '0')
                                  }}
                                  value={values.isDefaultBilling}
                                  disabled={isSubmitting}
                                />
                              )}
                              label="Default Billing Address"
                            />
                          )
                        }
                      </Grid>
                    </Grid>
                    <FormContainer
                      container
                      spacing={1}
                    >
                      {
                        addressFields.map((field) => {
                          const fieldProps = {
                            ...getFieldProps(field.label, field.name, field.isRequired),
                            countries,
                            states,
                            field,
                          }

                          if (['country', 'state'].includes(field.name)) {
                            return (
                              <StandardB3CountryGroup
                                key={field.name}
                                field={field}
                                fieldProps={fieldProps}
                                countryValue={values.country.countryName}
                                countryChange={(countryName) => {
                                  const country = countries.filter((item) => item.countryName === countryName)[0] || {}
                                  setFieldValue('state', {
                                    stateName: '',
                                    stateCode: '',
                                  })
                                  setFieldValue('country', {
                                    countryName: country.countryName,
                                    countryCode: country.countryCode,
                                  })
                                  this.setState({
                                    states: country.states,
                                  })
                                }}
                                states={states}
                                stateValue={values.state.stateName}
                                selectStateChange={(stateName) => {
                                  const state = states.filter((item) => item.stateName === stateName)[0]
                                  setFieldValue('state', {
                                    stateName: state.stateName,
                                    stateCode: state.stateCode,
                                  })
                                }}
                                textStateChange={(e) => {
                                  setFieldValue('state', {
                                    stateName: e.target.value,
                                    stateCode: '',
                                  })
                                }}
                                stateHelperText={(touched.state && errors.state && errors.state.stateName) ? errors.state.stateName : ''}
                              />
                            )
                          }

                          return (
                            <Grid item key={field.name} xs={field.xs}>
                              <TextField {...fieldProps} />
                            </Grid>
                          )
                        })
                      }
                    </FormContainer>
                  </B3Spin>
                </DialogContent>
              </B3ConfirmDialog>
            )
          }
        }
      </Formik>
    )
  }
}

export default AddressBookForm
