import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core'
import {
  B3Spin,
  B3Tag,
} from '../../../components'
import { snackbar } from '../../../utils'
import b3request from '../../../service'

import locales from '../../../locales/en-US'
import b3DateFormat from '../../../utils/b3DateFormat'

const StatusTag = ({ statusCode = '1' }) => (
  <B3Tag color={statusCode === '1' ? 'success' : 'error'}>
    {statusCode === '1' ? 'Synced' : 'Failed'}
  </B3Tag>
)

StatusTag.propTypes = {
  statusCode: PropTypes.oneOf(['1', '0']).isRequired,
}

export default class SyncStatus extends Component {
  tableRowKeys = ['localEntity', 'status', 'formatLocallyModified', 'localId', 'formatExternallyModified', 'externalId', 'errorMessage', 'formatLatestAttempt', 'attemptCount']

  constructor() {
    super()
    this.state = {
      isLoading: false,
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      logs: [],
    }
  }

  componentDidMount() {
    this.getLogs()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getLogs)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getLogs)
  }

  getLogs = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
    } = this.state

    try {
      const resp = await b3request.integration.getAcumaticaLogs({
        offset,
        limit,
      })

      this.setState({
        logs: resp.list.map((log) => ({
          ...log,
          formatLocallyModified: b3DateFormat.extendedDisplayFormat(log.locallyModified, true),
          formatExternallyModified: b3DateFormat.extendedDisplayFormat(log.externallyModified, true),
          formatLatestAttempt: b3DateFormat.extendedDisplayFormat(log.latestAttempt, true),
        })),
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const {
      isLoading,
      pagination,
      logs,
    } = this.state
    return (
      <B3Spin
        isSpinning={isLoading}
      >
        <Table>
          <TableHead>
            <TableRow>
              {
                this.tableRowKeys.map((tableRowKey) => (
                  <TableCell key={tableRowKey}>{locales[`acumatica.syncStatus.table.thead.${tableRowKey}`]}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              logs.map((log) => (
                <TableRow
                  key={log.createdAt}
                >
                  {
                    this.tableRowKeys.map((tableRowKey) => (
                      <TableCell key={tableRowKey}>
                        {
                          tableRowKey === 'status'
                            ? <StatusTag statusCode={log.status} />
                            : log[tableRowKey]
                        }
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={pagination.totalCount}
          rowsPerPage={pagination.limit}
          page={this.currentPage}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
        />
      </B3Spin>
    )
  }
}
