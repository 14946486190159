import React from 'react'
import PropTypes from 'prop-types'

import {
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import { B3ConfirmDialog } from '../../components'

function DeleteCompanyUserConfirm(props) {
  const {
    isOpen,
    onConfirm,
    userName,
    isDeleting,
  } = props
  const handleDialogClose = () => {
    // can't make the dialog close if ajax is calling.
    if (isDeleting) return false
    return props.onClose()
  }

  return (
    <B3ConfirmDialog
      isOpen={isOpen}
      onClose={handleDialogClose}
      onConfirm={onConfirm}
      confirmText="Delete"
      isCancelDisabled={isDeleting}
      isSubmitDisabled={isDeleting}
      isSpinning={isDeleting}
    >
      <DialogContent>
        <DialogContentText>
          {`Are you sure delete user : ${userName} ?`}
        </DialogContentText>
      </DialogContent>
    </B3ConfirmDialog>
  )
}

DeleteCompanyUserConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
}

export default DeleteCompanyUserConfirm
