import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  FormControlLabel,
  Checkbox,
  TablePagination,
  RadioGroup,
  Radio,
  Box,
  DialogContentText,
} from '@material-ui/core'

import {
  B3Spin,
  NoData,
  B3Search,
} from '../../components'

import b3request from '../../service'
import getAssignmentsByName from '../../utils/getAssignmentsByName'

const assignments = getAssignmentsByName()

export default class CompanyList extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    assignedCompanies: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      isAssigned: PropTypes.string,
    })).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      companyList: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      q: '',
      assignments,
      assignmentStatus: '1',
    }
  }

  componentDidMount() {
    this.getCompanies()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get hasCompanyList() {
    const { companyList } = this.state
    return Array.isArray(companyList) && companyList.length > 0
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getCompanies)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getCompanies)
  }

  getCompanies = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
      q,
      assignmentStatus: status,
    } = this.state

    const {
      id,
      assignedCompanies,
    } = this.props

    const params = id ? {
      id,
      params: {
        offset,
        limit,
        q,
        status,
      },
    } : {
      groupBy: '2',
      offset,
      limit,
      q,
    }

    const request = id ? b3request.salesReps.getSalesRepsCompanies : b3request.salesReps.getSalesRepsAndCompanies

    try {
      const resp = await request(params)
      const companyList = resp.list.map((company) => {
        if (!id) company.isAssigned = '0'
        const cacheCompany = assignedCompanies.find((assignedCompany) => assignedCompany.companyId === company.companyId) || {}
        return {
          ...company,
          ...cacheCompany,
        }
      })
      this.setState({
        companyList,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch {
      //
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getCompanies)
  }

  handleAssignStatusChange = (event) => {
    const {
      onChange,
    } = this.props
    const {
      pagination,
    } = this.state
    this.setState({
      assignmentStatus: event.target.value,
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getCompanies)
    onChange(null, true)
  }

  handleChangeCompanyAssigned = (customCompany) => () => {
    const {
      companyList,
    } = this.state
    const {
      onChange,
    } = this.props

    const isAssignedReverse = {
      0: '1',
      1: '0',
    }
    const changedCompanyList = companyList.map((company) => {
      const { isAssigned } = customCompany
      if (company.companyId === customCompany.companyId) {
        const reverseAssigned = isAssignedReverse[isAssigned]
        customCompany.isAssigned = reverseAssigned
        company.isAssigned = reverseAssigned
      }
      return {
        ...company,
      }
    })

    this.setState({
      companyList: changedCompanyList,
    })

    onChange(customCompany)
  }

  render() {
    const {
      isLoading,
      companyList,
      pagination,
      q,
      assignments,
      assignmentStatus,
    } = this.state

    const {
      disabled,
      id,
    } = this.props
    return (
      <B3Spin
        isSpinning={isLoading}
      >
        <Grid container>
          <Grid item xs={12}>
            <DialogContentText>Company Assignment:</DialogContentText>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box pr={2}>
              <B3Search
                value={q}
                onChange={this.handleSearchChange}
                onSearch={this.handleSearch}
                placeholder="search company name…"
              />
            </Box>
          </Grid>
          {
            id && (
              <Grid item xs={6}>
                <RadioGroup
                  row
                  value={assignmentStatus}
                  onChange={this.handleAssignStatusChange}
                >
                  {
                    assignments.map((assignment) => (
                      <FormControlLabel
                        key={assignment.status}
                        value={assignment.status}
                        label={assignment.label}
                        control={<Radio />}
                      />
                    ))
                  }
                </RadioGroup>
              </Grid>
            )
          }
        </Grid>
        {
          this.hasCompanyList ? (
            <Grid
              container
            >
              {
                companyList.map((company) => (
                  <Grid
                    item
                    xs={12}
                    key={company.companyId}
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={company.isAssigned === '1'}
                          onChange={this.handleChangeCompanyAssigned(company)}
                          disabled={disabled}
                        />
                      )}
                      label={`${company.companyName}`}
                    />
                  </Grid>
                ))
              }
              <Grid
                item
                xs={12}
              >
                <TablePagination
                  align="left"
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={pagination.totalCount}
                  rowsPerPage={pagination.limit}
                  page={this.currentPage}
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                />
              </Grid>
            </Grid>
          ) : (
            <NoData text="No companies" />
          )
        }
      </B3Spin>
    )
  }
}
