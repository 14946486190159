import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { isEmpty, isEqual } from 'lodash'

import {
  Grid,
  styled,
  TextField,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import {
  B3ConfirmDialog,
  B3Spin,
} from '../../components'

import b3request from '../../service'

import CompanyList from './CompanyList'
import { snackbar } from '../../utils'
import locales from '../../locales/en-US'
import { re } from '../../constants'

const FormContainer = styled(Grid)({
  minWidth: 400,
  marginBottom: 16,
})

/**
 * define company infomation form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} name key of each field
 * @property {string} label display name
 * @property {string} initialValue initial value of each field
 * @property {boolen} isRequired if the field value is required
 * @property {object} validator Yup object for validataion
 * @property {number} xs the gird of Mui for layout
 */

/**
 * @const
 * @type {Array.<formFieldSchema>} user form fields value
 * @todo confirm the max length of the each field with backend
 */
const userFields = [{
  name: 'email',
  label: 'Email Address',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  xs: 6,
}, {
  name: 'firstName',
  label: 'First Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.firstName']),
  xs: 6,
}, {
  name: 'lastName',
  label: 'Last Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.lastName']),
  xs: 6,
}, {
  name: 'phoneNumber',
  label: 'Contact Phone Number',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber']),
  xs: 6,
}]

/**
 * generate form validation schema shape
 * @const {object} userFieldsSchema
 */
const userFieldsSchema = userFields.reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {})

export default class SalesRepForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  }

  initAssignedCompanies = []

  constructor(props) {
    super()
    this.state = {
      initiaFormlValues: userFields.reduce((values, currentItem) => {
        values[currentItem.name] = currentItem.initialValue
        return values
      }, {}),
      isLoading: false,
      isEmailValid: false,
      assignedCompanies: [],
      id: props.id || '',
    }
  }

  componentDidMount() {
    this.getSalesRepInfo()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get dialogTitle() {
    const {
      id,
    } = this.props
    return `${id ? 'Edit' : 'Add'} Sales Rep`
  }

  get isAssignedCompaniesChanged() {
    const { assignedCompanies } = this.state
    return !isEqual(assignedCompanies, this.initAssignedCompanies)
  }

  getSalesRepInfo = async () => {
    const {
      id,
    } = this.state
    if (id) {
      this.setState({
        isLoading: true,
      })
      const {
        email,
        firstName,
        lastName,
        phoneNumber,
      } = await b3request.users.getUserById(id)
      const {
        initiaFormlValues,
      } = this.state
      this.setState({
        initiaFormlValues: {
          ...initiaFormlValues,
          email,
          firstName,
          lastName,
          phoneNumber,
        },
        isEmailValid: true,
        isLoading: false,
      })
    }
  }

  handleClose = () => {
    const {
      onCancel,
    } = this.props
    onCancel()
  }

  handleAssignedCompaniesChange = (company, clear) => {
    const { assignedCompanies } = this.state
    if (clear) {
      this.setState({
        assignedCompanies: [],
      })
      return
    }
    const companyIndex = assignedCompanies.findIndex(({ companyId: assignedCompanyId }) => assignedCompanyId === company.companyId)
    if (companyIndex === -1) assignedCompanies.push(company)
    else assignedCompanies.splice(companyIndex, 1)
    this.setState({
      assignedCompanies: assignedCompanies.map(({
        companyId,
        isAssigned,
      }) => ({
        companyId,
        isAssigned,
      })),
    })
  }

  toggleAllAssignedCompaniesShow = () => {
    const { isAllAssignedCompaniesShow } = this.state
    this.setState({
      isAllAssignedCompaniesShow: !isAllAssignedCompaniesShow,
    })
  }

  render() {
    const {
      initiaFormlValues,
      isLoading,
      isEmailValid,
      assignedCompanies,
      id,
    } = this.state

    const {
      onCancel,
      id: initId,
    } = this.props

    return (
      <Formik
        enableReinitialize
        initialValues={initiaFormlValues}
        validationSchema={Yup.object().shape(userFieldsSchema)}
        onSubmit={async (values, { setSubmitting }) => {
          if (initId) {
            try {
              await b3request.users.updateUserInfo(initId, values)
              await b3request.salesReps.updateAssignedCompaniesBySalesRepId(initId, { companies: assignedCompanies })
              snackbar.success(locales['app.tips.salesRep.updateSalesRepSuccessfully'])
              onCancel(true)
            } catch (error) {
              snackbar.error(locales['app.tips.salesRep.updateSalesRepFailed'])
              setSubmitting(false)
            }
          } else {
            try {
              const { userId } = await b3request.users.createUser({
                ...values,
                role: '3',
              })
              await b3request.salesReps.updateAssignedCompaniesBySalesRepId(userId, { companies: assignedCompanies })
              snackbar.success(locales['app.tips.salesRep.createSalesRepSuccessfully'])
              onCancel(true)
            } catch (error) {
              snackbar.error(locales['app.tips.salesRep.failed'])
              setSubmitting(false)
            }
          }
        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            isSubmitting,
            setFieldValue,
            setStatus,
            handleSubmit,
            status = {
              errors: {},
            },
          }) => {
            /**
             * Generate field props
             * @function getFieldProps
             * @param {string} label
             * @param {string} name
             * @param {boolean} required
             */
            const getFieldProps = (label, name, required) => ({
              label,
              name,
              value: values[name],
              onChange: handleChange,
              onBlur: handleBlur,
              error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
              helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
              required,
              fullWidth: true,
            })
            const setFieldsValue = (fields) => {
              Object.keys(fields).forEach((key) => {
                setFieldValue(key, fields[key])
              })
            }
            return (
              <B3ConfirmDialog
                onClose={this.handleClose}
                title={this.dialogTitle}
                isOpen
                confirmText="Save"
                onConfirm={handleSubmit}
                isCancelDisabled={isSubmitting}
                isSubmitDisabled={isSubmitting || !isEmpty(errors) || (!dirty && !this.isAssignedCompaniesChanged)}
                isSpinning={isSubmitting || isLoading}
              >
                <DialogContent>
                  <B3Spin
                    isSpinning={isSubmitting || isLoading}
                  >
                    <DialogContentText>Provide the following information:</DialogContentText>
                    <FormContainer
                      container
                      spacing={1}
                    >
                      {
                        userFields.map((field) => {
                          const filedsProps = field.name === 'email'
                            ? {
                              // normal props
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                              ),
                              // rewrite onBlur prop of email field
                              onBlur: async (e) => {
                                // use default blur method to validate the email first with email schema
                                handleBlur(e)
                                if (errors.email || values.email === '') {
                                  return false
                                }
                                // if email is validated by schema, should make ajax call to validate if the email is existed in another company
                                this.setState({
                                  isLoading: true,
                                })
                                // ajax call
                                const {
                                  id,
                                } = this.props
                                const {
                                  isValid,
                                  userInfo: {
                                    // fields' default value is empty
                                    firstName = '',
                                    lastName = '',
                                    phoneNumber = '',
                                    email = values.email,
                                    role,
                                    id: userId,
                                  },
                                } = await b3request.companies.validateUserEmail(values.email, '3', id)
                                // set form fields value by the response of ajax call
                                setFieldsValue({
                                  firstName,
                                  lastName,
                                  role,
                                  email,
                                  phoneNumber,
                                })

                                const validSuccess = isValid === '1'
                                this.setState({
                                  isEmailValid: validSuccess,
                                  id: validSuccess ? userId : '',
                                  isLoading: false,
                                })

                                // deal with status errors
                                const statusErrors = status.errors
                                if (validSuccess) {
                                  delete statusErrors.email
                                } else {
                                  statusErrors.email = 'email exists in another company'
                                }
                                setStatus({
                                  errors: statusErrors,
                                })
                                return null
                              },
                              disabled: !!initId,
                            }
                            : {
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                              ),
                              disabled: !id && (!isEmailValid || isLoading || isSubmitting),
                            }
                          return (
                            <Grid item key={field.name} xs={field.xs}>
                              <TextField
                                {...filedsProps}
                              />
                            </Grid>
                          )
                        })
                      }
                    </FormContainer>
                  </B3Spin>
                  {
                    (isEmailValid || id) && (
                      <CompanyList
                        onChange={this.handleAssignedCompaniesChange}
                        disabled={isSubmitting}
                        id={id}
                        assignedCompanies={assignedCompanies}
                      />
                    )
                  }
                </DialogContent>
              </B3ConfirmDialog>
            )
          }
        }
      </Formik>
    )
  }
}
