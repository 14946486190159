import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  Formik,
} from 'formik'

import * as Yup from 'yup'

import { isEmpty } from 'lodash'

import {
  DialogContent,
  TextField,
  Grid,
  styled,
  MenuItem,
} from '@material-ui/core'

import {
  B3ConfirmDialog,
  B3Spin,
} from '../../components'

import b3request from '../../service'

import locales from '../../locales/en-US'

import {
  snackbar,
  getUserRoleByCode,
  COMPANYSTATUS,
  checkPermissions,
} from '../../utils'

import { re } from '../../constants'

const FormContainer = styled(Grid)({
  minWidth: 400,
  marginBottom: 16,
})

/**
 * define company infomation form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} name key of each field
 * @property {string} label display name
 * @property {string} initialValue initial value of each field
 * @property {boolen} isRequired if the field value is required
 * @property {object} validator Yup object for validataion
 * @property {number} xs the gird of Mui for layout
 */

/**
 * @const
 * @type {Array.<formFieldSchema>} user form fields value
 * @todo confirm the max length of the each field with backend
 */
const userFields = [{
  name: 'email',
  label: 'Email Address',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  xs: 6,
}, {
  name: 'role',
  label: 'Role',
  initialValue: '',
  isRequired: true,
  select: true,
  options: ['0', '1', '2'],
  validator: Yup.string()
    .max(255)
    .required(),
  xs: 6,
}, {
  name: 'firstName',
  label: 'First Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.firstName']),
  xs: 6,
}, {
  name: 'lastName',
  label: 'Last Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.lastName']),
  xs: 6,
}, {
  name: 'customerPhone',
  label: 'Contact Phone Number',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber']),
  xs: 12,
}]

/**
 * generate form validation schema shape
 * @const {object} userFieldsSchema
 */
const userFieldsSchema = userFields.reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {})

class UserForm extends Component {
  static defaultProps = {
    companyStatus: '',
  }

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    afterAdminAdd: PropTypes.func.isRequired,
    confirmText: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    companyStatus: PropTypes.string,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      initialValues: userFields.reduce((values, currentItem) => {
        values[currentItem.name] = currentItem.initialValue
        return values
      }, {}),
      isLoading: false,
    }
  }

  componentDidMount() {
    const { userId } = this.props
    if (userId) {
      this.getUser()
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  getUser = async () => {
    // show the loading mask and icon first
    this.setState({
      isLoading: true,
      isEmailValid: true,
    })

    // need to get the company id from react router.
    const {
      match,
      userId,
    } = this.props

    const {
      initialValues,
    } = this.state

    // make request
    const resp = await b3request.companies.getCompanyUserById(match.params.id, userId)
    this.setState({
      initialValues: {
        ...initialValues,
        ...resp,
      },
    })
    this.setState({
      isLoading: false,
    })
  }

  render() {
    const {
      initialValues,
      isLoading,
      isEmailValid,
    } = this.state
    const {
      onCancel,
      confirmText,
      match,
      userId,
      companyStatus,
      history,
      location,
      afterAdminAdd,
    } = this.props
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(userFieldsSchema)}
        onSubmit={async (values, { setSubmitting }) => {
          const request = userId
            ? b3request.companies.updateCompanyUserById(match.params.id, userId, values)
            : b3request.users.createUser({
              ...values,
              companyId: match.params.id,
              phoneNumber: values.customerPhone,
            })
          const resp = await request

          if (resp.companyId || resp.userId) {
            onCancel(true)
            snackbar.success(`${confirmText} ${companyStatus === COMPANYSTATUS.INACTIVE ? 'admin' : 'user'} successfully!`)
            if (companyStatus === COMPANYSTATUS.INACTIVE) {
              if (checkPermissions(['11300'])) {
                history.replace(location.pathname)
                afterAdminAdd()
              } else history.goBack()
            }
          }
          setSubmitting(false)
        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldValue,
            setStatus,
            status = {
              errors: {},
            },
          }) => {
            /**
             * Generate field props
             * @function getFieldProps
             * @param {string} label
             * @param {string} name
             * @param {boolean} required
             */
            const getFieldProps = (label, name, required) => ({
              label,
              name,
              value: values[name],
              onChange: handleChange,
              onBlur: handleBlur,
              error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
              helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
              required,
              fullWidth: true,
            })

            const handleDialogClose = () => {
              // can't make the dialog close if ajax is calling.
              if (isSubmitting) return false
              return onCancel()
            }

            const setFieldsValue = (fields) => {
              Object.keys(fields).forEach((key) => {
                setFieldValue(key, fields[key])
              })
            }

            return (
              <B3ConfirmDialog
                isOpen
                onClose={handleDialogClose}
                title={`${confirmText} user`}
                confirmText="save"
                onConfirm={handleSubmit}
                isCancelDisabled={isSubmitting}
                isSubmitDisabled={isSubmitting || !isEmpty(errors) || !dirty}
                isSpinning={isSubmitting || isLoading}
              >
                <DialogContent>
                  <B3Spin
                    isSpinning={isSubmitting || isLoading}
                  >
                    <FormContainer
                      container
                      spacing={1}
                    >
                      {
                        userFields.map((field) => {
                          let filedsProps = {}
                          switch (field.name) {
                            case 'email': {
                              filedsProps = {
                                // normal props
                                ...getFieldProps(
                                  field.label,
                                  field.name,
                                  field.isRequired,
                                ),
                                // rewrite onBlur prop of email field
                                onBlur: async (e) => {
                                  // use default blur method to validate the email first with email schema
                                  handleBlur(e)
                                  if (errors.email || values.email === '') {
                                    return false
                                  }
                                  // if email is validated by schema, should make ajax call to validate if the email is existed in another company
                                  this.setState({
                                    isLoading: true,
                                  })
                                  // ajax call
                                  const {
                                    match,
                                  } = this.props
                                  const {
                                    isValid,
                                    userInfo: {
                                      // fields' default value is empty
                                      firstName = '',
                                      lastName = '',
                                      phoneNumber = '',
                                      role = '0',
                                    } = {},
                                  } = await b3request.companies.validateUserEmail(values.email, '1', match.params.id)
                                  // set form fields value by the response of ajax call
                                  setFieldsValue({
                                    firstName,
                                    lastName,
                                    role: companyStatus === COMPANYSTATUS.INACTIVE ? '0' : role,
                                    customerPhone: phoneNumber,
                                  })

                                  this.setState({
                                    isEmailValid: isValid === '1',
                                    isLoading: false,
                                  })

                                  // deal with status errors
                                  const statusErrors = status.errors
                                  if (isValid === '1') {
                                    delete statusErrors.email
                                  } else {
                                    statusErrors.email = 'email exists in another company'
                                  }
                                  setStatus({
                                    errors: statusErrors,
                                  })
                                  return true
                                },
                                disabled: !!userId,
                              }
                              break
                            }
                            case 'role': {
                              if (companyStatus === COMPANYSTATUS.INACTIVE) {
                                filedsProps = {
                                  ...getFieldProps(
                                    field.label,
                                    field.name,
                                    field.isRequired,
                                  ),
                                  value: '0',
                                  disabled: true,
                                }
                                break
                              }
                            }
                            default: filedsProps = {
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                              ),
                              disabled: !isEmailValid || isLoading || isSubmitting,
                            }
                          }
                          return (
                            <Grid item key={field.name} xs={field.xs}>
                              <TextField
                                {...filedsProps}
                                select={field.select}
                              >
                                {
                                  Array.isArray(field.options) && field.options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {getUserRoleByCode(option).text}
                                    </MenuItem>
                                  ))
                                }
                              </TextField>
                            </Grid>
                          )
                        })
                      }
                    </FormContainer>
                  </B3Spin>
                </DialogContent>
              </B3ConfirmDialog>
            )
          }
        }
      </Formik>
    )
  }
}

export default withRouter(UserForm)
