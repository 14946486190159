import {
  DashboardOutlined as DashboardIcon,
  BusinessOutlined as CompaniesIcon,
  PeopleOutline as SalesRepIcon,
  AddShoppingCartOutlined as QuantityIcon,
  SettingsOutlined as SettingsIcon,
  ListAltOutlined as QuotesIcon,
  RestoreOutlined as OrderIcon,
  DeviceHubOutlined as IntegrationIcon,
  PersonOutlined as UsersIcon,
  AccountCircle as RolesIcon,
} from '@material-ui/icons'

import {
  Dashboard,
  Companies,
  Company,
  SalesRep,
  AdvancedQuantityManagement,
  Notification,
  Settings,
  Quotes,
  Quote,
  Orders,
  Integration,
  Users,
  Roles,
  Role,
} from '../pages'

import locales from '../locales/en-US'

const routes = [{
  path: '/dashboard',
  primary: locales['app.menu.dashboard'],
  icon: DashboardIcon,
  component: Dashboard,
  isMenuItem: true,
  permissions: [],
}, {
  path: '/notifications/:id',
  primary: 'Notification',
  component: Notification,
  isMenuItem: false,
  permissions: ['10001', '10002'],
}, {
  path: '/companies',
  primary: locales['app.menu.companies'],
  icon: CompaniesIcon,
  component: Companies,
  isMenuItem: true,
  permissions: ['11100'],
}, {
  path: '/companies/:id',
  primary: locales['app.menu.companyDetail'],
  component: Company,
  isMenuItem: false,
  permissions: ['11105'],
},
{
  path: '/quotes',
  primary: locales['app.menu.quotes'],
  icon: QuotesIcon,
  component: Quotes,
  isMenuItem: true,
  permissions: ['12100'],
}, {
  path: '/quotes/:id',
  primary: locales['app.menu.quoteDetail'],
  component: Quote,
  isMenuItem: false,
  permissions: ['12101'],
}, {
  path: '/sales-reps',
  primary: locales['app.menu.salesReps'],
  icon: SalesRepIcon,
  component: SalesRep,
  isMenuItem: true,
  permissions: ['13101'],
}, {
  path: '/orders',
  primary: locales['app.menu.orders'],
  icon: OrderIcon,
  component: Orders,
  isMenuItem: true,
  permissions: ['14100'],
}, {
  path: '/advanced-quantity-management',
  primary: locales['app.menu.advancedQuantityManagement'],
  icon: QuantityIcon,
  component: AdvancedQuantityManagement,
  isMenuItem: true,
  permissions: [],
}, {
  path: '/integration',
  primary: locales['app.menu.integration'],
  icon: IntegrationIcon,
  component: Integration,
  isMenuItem: true,
  permissions: [],
}, {
  path: '/settings',
  primary: locales['app.menu.settings'],
  icon: SettingsIcon,
  component: Settings,
  isMenuItem: true,
  permissions: ['16101', '16102', '16103'],
}, {
  path: '/users',
  primary: locales['app.menu.users'],
  icon: UsersIcon,
  component: Users,
  isMenuItem: true,
  permissions: ['15100'],
}, {
  path: '/roles',
  primary: locales['app.menu.roles'],
  icon: RolesIcon,
  component: Roles,
  isMenuItem: true,
  permissions: ['15102'],
}, {
  path: '/role',
  primary: locales['app.menu.role'],
  component: Role,
  isMenuItem: false,
  permissions: ['15103'],
}]

export default routes
