import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual } from 'lodash'
import { withRouter } from 'react-router-dom'

import {
  Grid,
  TextField,
  Button,
  ButtonGroup,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import {
  Clear as ClearIcon,
} from '@material-ui/icons'

import {
  styled,
} from '@material-ui/styles'

import {
  Formik,
} from 'formik'

import * as Yup from 'yup'

import {
  B3Spin,
  B3CatalogPicker,
  StandardB3CountryGroup,
} from '../../components'

import b3request from '../../service'
import { snackbar } from '../../utils'
import locales from '../../locales/en-US'
import { re } from '../../constants'
import getTextFieldPropsByCode from '../../utils/getTextFieldPropsByCode'

const StyledIconButton = styled(IconButton)({
  padding: 4,
  marginLeft: 4,
  display: 'inline-block',
})

/**
 * define company infomation form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} name key of each field
 * @property {string} label display name
 * @property {boolen} isRequired if the field value is required
 * @property {object} validator Yup object for validataion
 * @property {number} xs the gird of Mui for layout
 */

/**
 * @const
 * @type {Array.<formFieldSchema>} company infomation fields value
 * @todo confirm the max length of the each field with backend
 */

const companyInfomationFields = [{
  name: 'companyName',
  label: 'Company Name',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.companyName']),
  xs: 6,
}, {
  name: 'phoneNumber',
  label: 'Phone Number',
  isRequired: true,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber'])
    .required(locales['app.validation.errors.required.phoneNumber']),
  xs: 6,
}, {
  name: 'companyEmail',
  label: 'Company Email Address',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  xs: 6,
}, {
  name: 'addressLine1',
  label: 'Address Line 1',
  validator: Yup.string()
    .max(255),
  xs: 12,
}, {
  name: 'addressLine2',
  label: 'Address Line 2',
  validator: Yup.string()
    .max(255),
  xs: 12,
}, {
  name: 'country',
  label: 'Country',
  validator: Yup.string()
    .max(255),
  xs: 4,
}, {
  name: 'state',
  label: 'State',
  validator: Yup.string()
    .max(255),
  xs: 4,
}, {
  name: 'city',
  label: 'City',
  validator: Yup.string()
    .max(255),
  xs: 4,
}, {
  name: 'zipCode',
  label: 'Zip Code',
  validator: Yup.string()
    .max(255),
  xs: 4,
}]

/**
 * generate form validation schema shape
 *
 * @const {object} companyFieldsSchemaShape
 */
const getCompanyFieldsSchemaShape = (companyInfomationFields) => companyInfomationFields.reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {
  catalogName: Yup.string()
    .max(255),
})

const companyFieldsSchemaShape = getCompanyFieldsSchemaShape(companyInfomationFields)

class BasicInformation extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    isGettingBasicInfo: PropTypes.bool.isRequired,
    basicInformation: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      catalogId: PropTypes.string,
      catalogName: PropTypes.string,
      city: PropTypes.string,
      companyEmail: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      companyStatus: PropTypes.string,
      description: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      updatedAt: PropTypes.string,
      zipCode: PropTypes.string,
      extraFields: PropTypes.arrayOf(PropTypes.shape({
        dataType: PropTypes.string,
        fieldName: PropTypes.string,
        fieldValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        isRequired: PropTypes.string,
        labelName: PropTypes.string,
      })).isRequired,
    }).isRequired,
    handleCompayCatalogIdChange: PropTypes.func.isRequired,
    getCompanyInfoById: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isCatalogPickerOpen: false,
      isEditing: false,
      companyInfomationFields,
      companyFieldsSchemaShape,
      computedBasicInformation: {},
      basicInformation: {},
      countries: [],
      states: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      basicInformation: {
        extraFields,
        ...basicFields
      },
    } = props

    let {
      companyInfomationFields,
      companyFieldsSchemaShape,
    } = state

    if (isEqual(props.basicInformation, state.basicInformation)) return null

    companyInfomationFields = [
      ...companyInfomationFields.filter((item) => Object.keys(basicFields).includes(item.name)),
      ...extraFields.map((item) => {
        const {
          fieldName: name,
          labelName,
          dataType,
          isRequired,
        } = item
        const textFieldProps = getTextFieldPropsByCode(dataType)
        const label = labelName.replace(labelName[0], labelName[0].toUpperCase())

        const typeValidate = dataType === '1' ? 'number' : 'string'
        const validator = isRequired === '1'
          ? Yup[typeValidate]().required(`${label} is required`)
          : Yup[typeValidate]()

        return {
          name,
          label,
          isRequired: !!+isRequired,
          validator,
          xs: dataType === '2' ? 12 : 4,
          textFieldProps,
        }
      }),
    ]

    companyFieldsSchemaShape = getCompanyFieldsSchemaShape(companyInfomationFields)

    const computedBasicInformation = {
      ...basicFields,
      ...extraFields.reduce((result, { fieldName, fieldValue }) => {
        result[fieldName] = fieldValue
        return result
      }, {}),
    }

    return {
      ...state,
      basicInformation: props.basicInformation,
      computedBasicInformation,
      companyFieldsSchemaShape,
      companyInfomationFields,
    }
  }

  componentDidMount() {
    this.getCountries()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  handleCancelClick = () => {
    this.setState({
      isEditing: false,
    })
  }

  handleEditClick = () => {
    this.setState({
      isEditing: true,
    })
  }

  showCatalogPicker = () => {
    this.setState({
      isCatalogPickerOpen: true,
    })
  }

  handleCatalogPickerHide = (isCatalogChanged = false) => {
    this.setState({
      isCatalogPickerOpen: false,
    })
    const {
      getCompanyInfoById,
    } = this.props
    if (isCatalogChanged) getCompanyInfoById()
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const {
      getCompanyInfoById,
      match,
      basicInformation: {
        extraFields,
        ...basicFields
      },
    } = this.props

    const params = Object.keys(basicFields).reduce((result, key) => {
      result[key] = values[key]
      return result
    }, {})
    params.extraFields = extraFields.map(({ fieldName }) => {
      const fieldValue = values[fieldName]
      return {
        fieldName,
        fieldValue,
      }
    })

    const resp = await b3request.companies.updateCompanyBasicInfoByCompanyId(match.params.id, params)
    if (resp.companyId) {
      snackbar.success(locales['app.tips.company.updatedCompanyInfoSuccessfully'])
      this.setState({
        isEditing: false,
      })
    }

    getCompanyInfoById()
    setSubmitting(false)
  }

  handlePriceListClear = async () => {
    const {
      basicInformation: {
        companyId,
      },
      getCompanyInfoById,
    } = this.props
    const catalogId = ''
    try {
      await b3request.companies.updateCompanyBasicInfoByCompanyId(companyId, {
        catalogId,
      })
      snackbar.success(locales['app.tips.company.updatedPriceListSuccessfully'])
    } finally {
      this.setState({
        isEditing: false,
      }, getCompanyInfoById)
    }
  }

  getCountries = async () => {
    let countries = JSON.parse(window.sessionStorage.getItem('b2bcountries'))
    if (!countries) {
      const data = await b3request.companies.getCountries()
      countries = data.list
      window.sessionStorage.setItem('b2bcountries', JSON.stringify(countries))
    }
    this.setState({
      countries,
    })
  }

  render() {
    const {
      isEditing,
      isCatalogPickerOpen,
      companyInfomationFields,
      companyFieldsSchemaShape,
      computedBasicInformation,
      countries,
      states,
    } = this.state

    const {
      isGettingBasicInfo,
      basicInformation,
      handleCompayCatalogIdChange,
    } = this.props

    return (
      <>
        {
        /**
         * Formik for form validation
         */
        }
        <Formik
          enableReinitialize // make the initialValues to be reactive by state change
          initialValues={computedBasicInformation}
          validationSchema={Yup.object().shape(companyFieldsSchemaShape)}
          onSubmit={this.handleSubmit}
        >
          {
            ({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
              isSubmitting,
              setFieldValue,
              handleReset,
            }) => {
              /**
               * Generate field props
               * @function getFieldProps
               * @param {string} label
               * @param {string} name
               * @param {boolean} required
               */
              const getFieldProps = (label, name, required, textFieldProps) => ({
                label,
                name,
                value: values[name] || '',
                onChange: handleChange,
                onBlur: handleBlur,
                error: touched[name] && Boolean(errors[name]),
                helperText: touched[name] ? errors[name] : '',
                required,
                fullWidth: true,
                disabled: !isEditing,
                ...textFieldProps,
              })
              const inputProps = values.catalogName ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      onClick={this.handlePriceListClear}
                      disabled={!isEditing}
                    >
                      <ClearIcon />
                    </StyledIconButton>
                  </InputAdornment>),
              } : null
              return (
                <B3Spin
                  isSpinning={isGettingBasicInfo || isSubmitting}
                  tip="loading…"
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        label="Price List"
                        value={values.catalogName || ''}
                        disabled={!isEditing}
                        onFocus={this.showCatalogPicker}
                        fullWidth
                        InputProps={inputProps}
                      />
                    </Grid>
                    {
                      companyInfomationFields.map((field) => {
                        if (values[field.name] === undefined) return null

                        const fieldProps = {
                          ...getFieldProps(field.label, field.name, field.isRequired, field.textFieldProps || {}),
                          countries,
                          states,
                          field,
                        }

                        if (['country', 'state'].includes(field.name)) {
                          return (
                            <StandardB3CountryGroup
                              key={field.name}
                              field={field}
                              fieldProps={fieldProps}
                              countryValue={values.country}
                              countryChange={(countryName) => {
                                const country = countries.filter((item) => item.countryName === countryName)[0] || {}
                                setFieldValue('state', '')
                                setFieldValue('country', country.countryName)
                                this.setState({
                                  states: country.states,
                                })
                              }}
                              states={states}
                              stateValue={values.state}
                              selectStateChange={(stateName) => {
                                const state = states.filter((item) => item.stateName === stateName)[0]
                                setFieldValue('state', state.stateName)
                              }}
                              textStateChange={(e) => {
                                setFieldValue('state', e.target.value)
                              }}
                              stateHelperText={(touched.state && errors.state) ? errors.state : ''}
                            />
                          )
                        }

                        return (
                          <Grid
                            key={field.name}
                            item
                            xs={field.xs}
                          >
                            <TextField {...getFieldProps(
                              field.label,
                              field.name,
                              field.isRequired,
                              field.textFieldProps || {},
                            )}
                            />
                          </Grid>
                        )
                      })
                    }
                    <Grid
                      item
                      xs={12}
                    >
                      {
                        isEditing ? (
                          <ButtonGroup>
                            <Button
                              onClick={() => {
                                handleReset()
                                this.handleCancelClick()
                              }}
                            >
                              cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSubmit}
                              disabled={isSubmitting || !isEmpty(errors) || !dirty}
                            >
                              Save
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <React.B3PermissionContainer
                            permissions={['11300']}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleEditClick}
                            >
                              Edit
                            </Button>
                          </React.B3PermissionContainer>
                        )
                      }
                    </Grid>
                  </Grid>
                </B3Spin>
              )
            }
          }
        </Formik>
        {
          isCatalogPickerOpen && (
            <B3CatalogPicker
              isOpen={isCatalogPickerOpen}
              selectedCatalogId={basicInformation.catalogId}
              onSelectedCatalogIdChange={handleCompayCatalogIdChange}
              companyId={basicInformation.companyId}
              onHide={this.handleCatalogPickerHide}
            />
          )
        }
      </>
    )
  }
}

export default withRouter(BasicInformation)
