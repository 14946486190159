import React from 'react'
import { DataUsageRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import PropsTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  noDataRoot: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.grey[500],
  },
  noDataText: {
    paddingTop: theme.spacing(0.5),
  },
}))

const NoData = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.noDataRoot}>
      <DataUsageRounded fontSize="large" />
      <span className={classes.noDataText}>{text}</span>
    </div>
  )
}

NoData.propTypes = {
  text: PropsTypes.string,
}

NoData.defaultProps = {
  text: 'No Data',
}

export default NoData
