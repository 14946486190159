import b3storage from './b3storage'

const checkPermissions = (permissions) => {
  const currentUserPermissions = b3storage.permissionModules?.val ?? []
  const isAllowed = !permissions.length || permissions.every((permission) => currentUserPermissions.includes(permission))

  return isAllowed
}

export default checkPermissions

export const checkOneOfPermissions = (permissions = []) => permissions.some((permission) => checkPermissions(permission))
