import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { isEmpty } from 'lodash'

import {
  Grid,
  styled,
  TextField,
  DialogContent,
  Select,
  MenuItem,
  Typography,
  Box,
  Collapse,
} from '@material-ui/core'

import {
  Alert,
} from '@material-ui/lab'

import {
  B3ConfirmDialog,
} from '../../components'

import b3request from '../../service'

import { snackbar } from '../../utils'
import locales from '../../locales/en-US'
import { re } from '../../constants'
import { userStatusText } from '../../utils/getUserStatusBycode'

const FormContainer = styled(Grid)({
  minWidth: 400,
  marginBottom: 16,
})

/**
 * define company infomation form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} email key of each field
 * @property {string} status display name
 * @property {string} roleId initial value of each field
 */

const userFields = [{
  name: 'email',
  label: 'Email',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
}, {
  name: 'status',
  label: 'Status',
  initialValue: '1',
}]

const permissionFields = [{
  name: 'roleId',
  label: 'User Role',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required(locales['app.validation.errors.required.permission']),
}]

/**
 * generate form validation schema shape
 * @const {object} userFieldsSchema
 */
const userFieldsSchema = [...userFields, ...permissionFields].reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {})

export default class UserForm extends Component {
  static defaultProps = {
    id: '',
  }

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    id: PropTypes.string,
  }

  constructor() {
    super()
    this.state = {
      initiaFormlValues: [...userFields, ...permissionFields].reduce((values, currentItem) => {
        values[currentItem.name] = currentItem.initialValue
        return values
      }, {}),
      isLoading: false,
      roles: [],
      isShowOwnerHelper: false,
    }
  }

  componentDidMount() {
    this.getAdminUserInfo()
    this.getRoles()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get dialogTitle() {
    const {
      id,
    } = this.props
    return `${id ? 'Edit' : 'Add New'} User`
  }

  getAdminUserInfo = async () => {
    const {
      id,
    } = this.props

    if (id) {
      this.setState({
        isLoading: true,
      })
      const {
        email,
        roleId,
        status,
      } = await b3request.users.getAdminUser(id)
      const {
        initiaFormlValues,
      } = this.state
      this.setState({
        initiaFormlValues: {
          ...initiaFormlValues,
          email,
          roleId,
          status,
        },
        isLoading: false,
      })
    }
  }

  getRoles = async () => {
    this.setState({
      isLoading: true,
    })

    try {
      const { list } = await b3request.roles.getRoles({
        offset: 0,
        limit: 100,
        action: 'addUser',
      })
      this.setState({
        roles: list,
      })
    } catch (error) {
      snackbar.error(error)
    }
    this.setState({
      isLoading: false,
    })
  }

  handleClose = () => {
    const {
      onCancel,
    } = this.props
    onCancel()
  }

  handleRoleSelect = (handleChange) => (e) => {
    const {
      roles,
    } = this.state
    const roleId = e.target.value

    const isShowOwnerHelper = roles.find((role) => role.id === roleId)?.roleName === 'Store Owner'
    this.setState({
      isShowOwnerHelper,
    })

    handleChange(e)
  }

  render() {
    const {
      initiaFormlValues,
      isLoading,
      roles,
      isShowOwnerHelper,
    } = this.state

    const {
      onCancel,
      id,
    } = this.props

    return (
      <Formik
        enableReinitialize
        initialValues={initiaFormlValues}
        validationSchema={Yup.object().shape(userFieldsSchema)}
        onSubmit={async (values, { setSubmitting }) => {
          if (id) {
            try {
              await b3request.users.updateAdminUser(id, values)
              snackbar.success(locales['app.tips.users.updateUsersSuccessfully'])
              onCancel(true)
            } catch (error) {
              snackbar.error(locales['app.tips.users.updateUsersFailed'])
              setSubmitting(false)
            }
          } else {
            try {
              await b3request.users.createAdminUser({
                ...values,
              })
              snackbar.success(locales['app.tips.users.createUsersSuccessfully'])
              onCancel(true)
            } catch (error) {
              snackbar.error(locales['app.tips.users.createUsersFailed'])
              setSubmitting(false)
            }
          }
        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            isSubmitting,
            handleSubmit,
            status = {
              errors: {},
            },
          }) => {
            /**
             * Generate field props
             * @function getFieldProps
             * @param {object} field
             */
            const getFieldProps = (field, isShowHelper = true) => {
              const { name } = field
              const helperText = isShowHelper ? {
                helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
              } : {}
              return ({
                label: field.label,
                name,
                value: values[name],
                onChange: handleChange,
                onBlur: handleBlur,
                error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
                required: field.required,
                fullWidth: true,
                disabled: isLoading || isSubmitting,
                ...helperText,
              })
            }

            return (
              <B3ConfirmDialog
                onClose={this.handleClose}
                title={this.dialogTitle}
                isOpen
                confirmText="Save"
                onConfirm={handleSubmit}
                isCancelDisabled={isSubmitting}
                isSubmitDisabled={isSubmitting || !isEmpty(errors) || !dirty}
                isSpinning={isSubmitting || isLoading}
              >
                <DialogContent>
                  <FormContainer
                    container
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        User Info
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...getFieldProps(userFields[0])}
                        disabled={!!id}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        {...getFieldProps(userFields[1], false)}
                      >
                        {
                          Object.entries(userStatusText).map(([key, value]) => (
                            <MenuItem value={key} key={key}>{ value }</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>
                    <Box mt={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Role
                        </Typography>
                      </Grid>
                    </Box>
                    <Grid item xs={12}>
                      <Select
                        {...getFieldProps(permissionFields[0], false)}
                        displayEmpty
                        onChange={this.handleRoleSelect(handleChange)}
                      >
                        <MenuItem value="" disabled>Please select</MenuItem>
                        {
                          roles.map((role) => (
                            <MenuItem value={role.id} key={role.id}>{ role.roleName }</MenuItem>
                          ))
                        }
                      </Select>
                      <Box mt={2}>
                        <Collapse in={isShowOwnerHelper}>
                          <Alert
                            severity="warning"
                          >
                            {locales['app.tips.users.transferOwner']}
                          </Alert>
                        </Collapse>
                      </Box>
                    </Grid>
                  </FormContainer>
                </DialogContent>
              </B3ConfirmDialog>
            )
          }
        }
      </Formik>
    )
  }
}
