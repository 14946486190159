import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  styled,
  Typography,
  Button,
  TableBody,
  Table,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  IconButton,
  Menu,
  MenuItem,
  DialogContent,
} from '@material-ui/core'

import {
  FilterList,
  Check as CheckIcon,
  MoreVert as MoreIcon,
} from '@material-ui/icons'

import {
  B3Spin,
  B3Search,
  NoData,
  B3ConfirmDialog,
} from '../../components'

import b3request from '../../service'
import { snackbar, checkOneOfPermissions } from '../../utils'

import AddressBookFilterForm from './AddressBookFilterForm'
import AddressBookForm from './AddressBookForm'

const DefaultBox = styled('div')((props) => ({
  border: `1px solid ${props.theme.palette.grey[300]}`,
  marginTop: props.theme.spacing(2),
  borderRadius: 4,
}))
const DefaultBoxTitle = styled(Typography)((props) => ({
  padding: props.theme.spacing(),
  borderBottom: `1px solid ${props.theme.palette.grey[300]}`,
  backgroundColor: props.theme.palette.grey[100],
  fontWeight: 'bold',
}))
const DefaultBoxContent = styled('div')((props) => ({
  padding: props.theme.spacing(),
}))

const HeaderWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

const HeaderRest = styled('div')((props) => ({
  flex: 1,
  padding: props.theme.spacing(2),
}))

const Sidebar = styled(Grid)((props) => ({
  paddingLeft: props.theme.spacing(2),
}))

const ActionButton = styled(IconButton)({
  padding: 4,
})

const AddressesTable = styled(Table)((props) => ({
  '& .name': {
    width: 1,
  },
  '& .phone': {
    width: 1,
  },
  '& .billing-shipping': {
    width: 1,
    textAlign: 'center',
  },
  '& .action': {
    width: 1,
  },
  '& .MuiTableCell-root': {
    paddingLeft: props.theme.spacing(),
    paddingRight: props.theme.spacing(),
  },
  '& .address': {
    '& > span': {
      display: 'block',
    },
  },
}))

const FilterIcon = styled(FilterList)({
  marginLeft: -4,
  marginRight: 8,
})

const DefaultAddress = ({ addressData }) => (
  <DefaultBoxContent>
    {
      addressData.label ? (
        <p>
          {addressData.label}
        </p>
      ) : ''
    }
    <p>
      {addressData.firstName}
      &nbsp;
      {addressData.lastName}
    </p>
    <p>
      {addressData.addressLine1}
      &nbsp;
      {addressData.addressLine2}
    </p>
    <p>
      {addressData.city}
      ,&nbsp;
      {addressData.state.stateName}
      &nbsp;
      {addressData.zipCode}
    </p>
    <p>
      {addressData.country.countryName}
    </p>
    <p>
      Phone: &nbsp;
      {addressData.phoneNumber}
    </p>
  </DefaultBoxContent>
)
DefaultAddress.propTypes = {
  addressData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    country: PropTypes.shape({
      countryName: PropTypes.string.isRequired,
    }),
    city: PropTypes.string.isRequired,
    state: PropTypes.shape({
      stateName: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default class AddressBook extends Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    getSummary: PropTypes.func.isRequired,
  }

  confirmActions = [
    'Are you sure you want to set this as default billing address?',
    'Are you sure you want to set this as default shipping address?',
    'Are you sure you want to delete this address?',
  ]

  constructor() {
    super()
    this.state = {
      countries: [],
      isLoadingAddresses: false,
      isLoadingDefaultAddresses: false,
      isFilterFormShow: false,
      q: '',
      filters: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        country: '',
        state: '',
        phoneNumber: '',
        zipCode: '',
        label: '',
        addressType: {
          isShipping: '',
          isBilling: '',
        },
      },
      actionsPopoverAnchorEl: null,
      actionsRowId: null,
      isAddressBookDialogShow: false,
      isConfirmDialogShow: false,
      isConfirming: false,
      confirmType: 2,
      defaultAddresses: {
        shipping: {},
        billing: {},
      },
      addresses: [],
      pagination: {
        totalCount: 0,
        offset: 0,
        limit: 5,
      },
    }
  }

  componentDidMount() {
    this.getAddressBook()
    this.getDefaultAddresses()
    this.getCountries()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  get hasDefaultBillingAddress() {
    const {
      defaultAddresses,
    } = this.state
    return Object.keys(defaultAddresses.billing).length > 0
  }

  get hasDefaultShippingAddress() {
    const {
      defaultAddresses,
    } = this.state
    return Object.keys(defaultAddresses.shipping).length > 0
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  get confirmTitle() {
    const {
      confirmType,
    } = this.state
    return this.confirmActions[confirmType]
  }

  get confirmContent() {
    const {
      actionsRowId,
      addresses = [],
    } = this.state
    const [currentAddress] = addresses.filter((address) => address.addressId === actionsRowId)
    if (!currentAddress) {
      return ''
    }
    return `${currentAddress.firstName} ${currentAddress.lastName}, ${currentAddress.addressLine1}${currentAddress.addressLine2 ? ` ${currentAddress.addressLine2},` : ''} ${currentAddress.city}, ${currentAddress.state.stateName}, ${currentAddress.zipCode}, ${currentAddress.country.countryName}`
  }

  getCountries = async () => {
    let countries = JSON.parse(window.sessionStorage.getItem('b2bcountries'))
    if (!countries) {
      const data = await b3request.companies.getCountries()
      countries = data.list
      window.sessionStorage.setItem('b2bcountries', JSON.stringify(countries))
    }
    this.setState({
      countries,
    })
  }

  getAddressBook = async () => {
    this.setState({
      isLoadingAddresses: true,
    })

    const {
      companyId,
    } = this.props

    const {
      pagination,
      q,
      filters,
    } = this.state

    try {
      const resp = await b3request.companies.getAddressBook(companyId, {
        offset: pagination.offset,
        limit: pagination.limit,
        q,
        filters,
      })
      this.setState({
        addresses: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          limit: resp.pagination.limit,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoadingAddresses: false,
      })
    }
  }

  getDefaultAddresses = async () => {
    this.setState({
      isLoadingDefaultAddresses: true,
    })

    const {
      companyId,
    } = this.props

    try {
      const {
        shipping = {},
        billing = {},
      } = await b3request.companies.getDefaultAddresses(companyId, {})
      this.setState({
        defaultAddresses: {
          shipping,
          billing,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoadingDefaultAddresses: false,
      })
    }
  }

  toggleFilterFormShow = () => {
    const {
      isFilterFormShow,
    } = this.state
    this.setState({
      isFilterFormShow: !isFilterFormShow,
    })
  }

  handleMoreActionsClick = (actionsRowId) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: null,
    })
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getAddressBook)
  }

  openAddressBookForm = () => {
    this.setState({
      isAddressBookDialogShow: true,
    })
  }

  closeAddressBookForm = (reloadAddressBook, reloadDefaultAddresses) => {
    const {
      getSummary,
    } = this.props
    this.setState({
      isAddressBookDialogShow: false,
    })
    this.handleMoreActionsPopoverClose()

    if (reloadAddressBook) {
      this.getAddressBook()
      getSummary()
    }
    if (reloadDefaultAddresses) this.getDefaultAddresses()
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getAddressBook)
  }

  openConfirmDialog = (confirmType) => () => {
    this.setState({
      isConfirmDialogShow: true,
      confirmType,
    })
  }

  closeConfirmDialog = () => {
    const {
      isConfirming,
    } = this.state
    if (!isConfirming) {
      this.setState({
        isConfirmDialogShow: false,
      })
      this.handleMoreActionsPopoverClose()
    }
  }

  handleConfirm = async () => {
    const {
      confirmType,
      actionsRowId,
      addresses,
      pagination,
    } = this.state
    const {
      companyId,
      getSummary,
    } = this.props
    this.setState({
      isConfirming: true,
    })
    try {
      const currentAddress = addresses.filter((address) => address.addressId === actionsRowId)[0]
      let request
      switch (confirmType) {
        case 0:
          request = b3request.companies.updateAddress(companyId, actionsRowId, {
            ...currentAddress,
            isBilling: '1',
            isDefaultBilling: '1',
          })
          break
        case 1:
          request = b3request.companies.updateAddress(companyId, actionsRowId, {
            ...currentAddress,
            isShipping: '1',
            isDefaultShipping: '1',
          })
          break
        case 2:
          request = b3request.companies.deleteAddressLogically(companyId, actionsRowId, '0')
          break
        default:
          break
      }
      const resp = await request
      if (resp.addressId) {
        this.setState({
          pagination: {
            ...pagination,
            offset: 0,
          },
          isConfirmDialogShow: false,
        }, () => {
          this.getAddressBook()
          this.getDefaultAddresses()
          if (confirmType === 2) getSummary()
        })
      }
    } catch (error) {
      snackbar.error('Unknown Error!')
    } finally {
      this.setState({
        isConfirming: false,
      })
      this.handleMoreActionsPopoverClose()
    }
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleFiltersChange = (filters) => {
    this.setState({
      filters,
    })
  }

  handleFiltersClear = () => {
    this.setState({
      filters: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        country: '',
        state: '',
        phoneNumber: '',
        zipCode: '',
        label: '',
        addressType: {
          isShipping: '',
          isBilling: '',
        },
      },
    }, this.handleConditionApply)
  }

  handleConditionApply = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getAddressBook)
  }

  render() {
    const {
      isLoadingAddresses,
      isLoadingDefaultAddresses,
      defaultAddresses,
      isFilterFormShow,
      addresses,
      pagination,
      actionsPopoverAnchorEl,
      actionsRowId,
      q,
      isAddressBookDialogShow,
      isConfirmDialogShow,
      isConfirming,
      countries,
      filters,
    } = this.state

    const {
      companyId,
    } = this.props

    return (
      <>
        <Grid
          container
        >
          <Grid
            item
            xs={9}
          >
            <B3Spin
              isSpinning={isLoadingAddresses}
            >
              <Grid
                container
              >

                <HeaderWrapper
                  item
                  xs={12}
                >
                  <B3Search
                    value={q}
                    onChange={this.handleSearchChange}
                    onSearch={this.handleConditionApply}
                    placeholder="Search by keyword…"
                  />
                  <HeaderRest>
                    <Button
                      variant="outlined"
                      onClick={this.toggleFilterFormShow}
                    >
                      <FilterIcon />
                      Filters
                    </Button>
                  </HeaderRest>
                  <React.B3PermissionContainer
                    permissions={['11205']}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.openAddressBookForm}
                    >
                      Add New Address
                    </Button>
                  </React.B3PermissionContainer>
                </HeaderWrapper>
                {
                  isFilterFormShow && (
                    <Grid
                      item
                      xs={12}
                    >
                      <AddressBookFilterForm
                        countries={countries}
                        filters={filters}
                        onChange={this.handleFiltersChange}
                        onClear={this.handleFiltersClear}
                        onApply={this.handleConditionApply}
                      />
                    </Grid>
                  )
                }
                <Grid
                  item
                  xs={12}
                >
                  {
                    addresses.length === 0 ? <NoData /> : (
                      <>
                        <AddressesTable>
                          <TableHead>
                            <TableRow>
                              <TableCell className="name">Name</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>Address Label</TableCell>
                              <TableCell className="phone">Phone</TableCell>
                              <TableCell className="billing-shipping">Shipping</TableCell>
                              <TableCell className="billing-shipping">Billing</TableCell>
                              {
                                checkOneOfPermissions([['11109', '11303'], ['11400']]) && (
                                  <TableCell className="action">Action</TableCell>
                                )
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              addresses.map((address) => (
                                <TableRow
                                  hover
                                  key={address.addressId}
                                >
                                  <TableCell className="name">
                                    {address.firstName}
                                    &nbsp;
                                    {address.lastName}
                                  </TableCell>
                                  <TableCell className="address">
                                    <Typography variant="inherit">
                                      {address.addressLine1}
                                      &nbsp;
                                      {address.addressLine2 && (
                                        <span>
                                          {address.addressLine2}
                                          ,&nbsp;
                                        </span>
                                      )}
                                    </Typography>
                                    <Typography variant="inherit">
                                      {address.city}
                                      ,&nbsp;
                                      {address.state.stateName}
                                      &nbsp;
                                      {address.zipCode}
                                      ,&nbsp;
                                      {address.country.countryName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>{address.label}</TableCell>
                                  <TableCell>{address.phoneNumber}</TableCell>
                                  <TableCell className="billing-shipping">{address.isShipping === '1' && <CheckIcon />}</TableCell>
                                  <TableCell className="billing-shipping">{address.isBilling === '1' && <CheckIcon />}</TableCell>
                                  {
                                    checkOneOfPermissions([['11109', '11303'], ['11400']]) && (
                                      <TableCell>
                                        <ActionButton
                                          onClick={this.handleMoreActionsClick(address.addressId)}
                                        >
                                          <MoreIcon />
                                        </ActionButton>
                                        <Menu
                                          anchorEl={actionsPopoverAnchorEl}
                                          open={actionsRowId === address.addressId}
                                          onClose={this.handleMoreActionsPopoverClose}
                                        >
                                          <React.B3PermissionContainer
                                            permissions={['11109', '11303']}
                                          >
                                            {({
                                              ref,
                                            }) => (
                                              <>
                                                <MenuItem
                                                  onClick={this.openAddressBookForm}
                                                  ref={ref}
                                                >
                                                  <Typography variant="subtitle2">
                                                    Edit
                                                  </Typography>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={this.openConfirmDialog(0)}
                                                  ref={ref}
                                                >
                                                  <Typography variant="subtitle2">
                                                    Set as default billing
                                                  </Typography>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={this.openConfirmDialog(1)}
                                                  ref={ref}
                                                >
                                                  <Typography variant="subtitle2">
                                                    Set as default shipping
                                                  </Typography>
                                                </MenuItem>
                                              </>
                                            )}
                                          </React.B3PermissionContainer>
                                          <React.B3PermissionContainer
                                            permissions={['11400']}
                                          >
                                            {({
                                              ref,
                                            }) => (
                                              <MenuItem
                                                ref={ref}
                                                onClick={this.openConfirmDialog(2)}
                                              >
                                                <Typography variant="subtitle2">
                                                  Delete
                                                </Typography>
                                              </MenuItem>
                                            )}
                                          </React.B3PermissionContainer>
                                        </Menu>
                                      </TableCell>
                                    )
                                  }
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </AddressesTable>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20]}
                          component="div"
                          count={pagination.totalCount}
                          rowsPerPage={pagination.limit}
                          page={this.currentPage}
                          onChangePage={this.handlePageChange}
                          onChangeRowsPerPage={this.handleRowsPerPageChange}
                        />
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </B3Spin>
          </Grid>
          <Sidebar
            item
            xs={3}
          >
            <B3Spin
              isSpinning={isLoadingDefaultAddresses}
            >
              <DefaultBox>
                <DefaultBoxTitle>
                  Default Billing Address
                </DefaultBoxTitle>
                <DefaultBoxContent>
                  {
                    this.hasDefaultBillingAddress ? (
                      <DefaultAddress
                        addressData={defaultAddresses.billing}
                      />
                    ) : 'The customer does not have a default billing address.'
                  }
                </DefaultBoxContent>
              </DefaultBox>
              <DefaultBox>
                <DefaultBoxTitle>Default Shipping Address</DefaultBoxTitle>
                <DefaultBoxContent>
                  {
                    this.hasDefaultShippingAddress ? (
                      <DefaultAddress
                        addressData={defaultAddresses.shipping}
                      />
                    ) : 'The customer does not have a default shipping address.'
                  }
                </DefaultBoxContent>
              </DefaultBox>
            </B3Spin>
          </Sidebar>
        </Grid>
        {
          isAddressBookDialogShow && (
            <AddressBookForm
              onCancel={this.closeAddressBookForm}
              addressId={actionsRowId}
              companyId={companyId}
              countries={countries}
            />
          )
        }
        <B3ConfirmDialog
          isOpen={isConfirmDialogShow}
          onClose={this.closeConfirmDialog}
          title={this.confirmTitle}
          onConfirm={this.handleConfirm}
          isSubmitDisabled={isConfirming}
          isCancelDisabled={isConfirming}
          isSpinning={isConfirming}
          onCancel={this.closeConfirmDialog}
        >
          <DialogContent>
            {this.confirmContent}
          </DialogContent>
        </B3ConfirmDialog>
      </>
    )
  }
}
