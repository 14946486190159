import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'

import {
  snackbar,
} from '../../utils'

import {
  B3Spin,
} from '..'

import b3request from '../../service'

export default class B3ExpiredNotification extends Component {
  constructor() {
    super()
    this.state = {
      notificationTitle: '',
      notificationContent: '',
      isDialogOpen: true,
      isSpinning: true,
    }
  }

  componentDidMount() {
    this.getExpiredNotification()
  }

  async getExpiredNotification() {
    try {
      const {
        title,
        content,
      } = await b3request.storeConfig.getExpiredNotification()
      this.setState({
        notificationTitle: title,
        notificationContent: content,
        isSpinning: false,
      })
    } catch (error) {
      snackbar.error(error.message)
    }
  }

  handleClose = () => {
    this.setState({
      isDialogOpen: false,
    })
  }

  render() {
    const {
      isDialogOpen,
      notificationTitle,
      notificationContent,
      isSpinning,
    } = this.state

    return (
      <Dialog open={isDialogOpen}>
        <B3Spin
          isSpinning={isSpinning}
          tip="Loading…"
        >
          <DialogTitle>{notificationTitle}</DialogTitle>
          <DialogContent
            dividers
            dangerouslySetInnerHTML={{
              __html: notificationContent,
            }}
          />
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              ok
            </Button>
          </DialogActions>
        </B3Spin>
      </Dialog>
    )
  }
}
