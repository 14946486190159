import React, { useState, useEffect } from 'react'
import { withRouter, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import b3request from '../../service'

import { B3Spin } from '..'

import { b3storage, getUrlQueries } from '../../utils'

const B3AuthRoute = (props) => {
  const [isCheckingTokenStatus, setIsCheckingTokenStatus] = useState(true)
  const { history } = props
  useEffect(() => {
    const storehash = getUrlQueries('storehash')

    const redirectToLogin = () => {
      b3storage.authToken.remove()
      b3storage.storehash.val = storehash
      history.push('/login')
    }

    if (!b3storage.authToken.val || storehash !== b3storage.storehash.val) {
      redirectToLogin()
    } else {
      b3request.users.checkTokenStatus({ storehash })
        .then((resp) => {
          if (resp.authToken) {
            b3storage.authToken.val = resp.authToken
            setIsCheckingTokenStatus(false)
          } else {
            redirectToLogin()
          }
        })
        .catch(() => {
          redirectToLogin()
        })
    }
  }, [history])

  return isCheckingTokenStatus ? <B3Spin isStretch /> : <Route {...props} />
}

B3AuthRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(B3AuthRoute)
