import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  IconButton,
  styled,
  Typography,
} from '@material-ui/core'

import {
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons'

const BackButton = styled(IconButton)({
  padding: 3,
  marginRight: 8,
})

const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Title = styled(Typography)((props) => ({
  marginRight: props.theme.spacing(),
}))
function B3Back(props) {
  const {
    title,
    history,
  } = props

  return (
    <TitleWrapper>
      <BackButton
        color="primary"
        onClick={history.goBack}
      >
        <ArrowBackIcon />
      </BackButton>
      <Title
        variant="h6"
      >
        { title }
      </Title>
    </TitleWrapper>
  )
}

B3Back.propTypes = {
  title: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

B3Back.defaultProps = {
  title: 'Back',
}

export default withRouter(B3Back)
