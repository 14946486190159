const userRoleText = {
  0: 'Admin',
  1: 'Senior Buyer',
  2: 'Junior Buyer',
}

const userRoleColor = {
  0: 'info',
  1: 'warning',
  2: 'error',
}

const adminRoleType = {
  0: 'System built-in',
  1: 'User created',
}

export default (code) => ({
  text: userRoleText[code],
  color: userRoleColor[code],
  adminRoleType: adminRoleType[code],
})
