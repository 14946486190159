export default {
  'acumatica.tabTitle.entitySetting': 'Entity Setting',
  'acumatica.tabTitle.syncStatus': 'Sync Status',
  'acumatica.syncStatus.table.button.sync': 'Sync',
  'acumatica.syncStatus.table.thead.localEntity': 'Local Entity',
  'acumatica.syncStatus.table.thead.status': 'Status',
  'acumatica.syncStatus.table.thead.formatLocallyModified': 'Locally Modified',
  'acumatica.syncStatus.table.thead.localId': 'local ID',
  'acumatica.syncStatus.table.thead.formatExternallyModified': 'Externally Modified',
  'acumatica.syncStatus.table.thead.externalId': 'external ID',
  'acumatica.syncStatus.table.thead.errorMessage': 'Error',
  'acumatica.syncStatus.table.thead.formatLatestAttempt': 'Latest Attempt',
  'acumatica.syncStatus.table.thead.attemptCount': 'Attempt Count',
  'acumatica.entitySetting.table.thead.active': 'Active',
  'acumatica.entitySetting.table.thead.localEntity': 'Local Entity',
  'acumatica.entitySetting.table.thead.externalEntity': 'External Entity',
  'acumatica.entitySetting.table.thead.direction': 'Direction',
}
