import React from 'react'
import PropTypes from 'prop-types'

import B3CountryGroup from './B3CountryGroup'

const StandardB3CountryGroup = ({
  field,
  fieldProps,
  countryValue,
  countryChange,
  states,
  stateValue,
  selectStateChange,
  textStateChange,
  stateHelperText,
}) => {
  switch (field.name) {
    case 'country': {
      fieldProps = {
        ...fieldProps,
        value: countryValue,
        onChange: countryChange,
      }
      break
    }
    default: {
      fieldProps = states.length ? {
        ...fieldProps,
        value: stateValue,
        onChange: selectStateChange,
      } : {
        ...fieldProps,
        value: stateValue,
        onChange: textStateChange,
        helperText: stateHelperText,
      }
    }
  }

  return (
    <B3CountryGroup {...fieldProps} />
  )
}

StandardB3CountryGroup.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    xs: PropTypes.number,
  }).isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      stateName: PropTypes.string,
    }),
  ),
  stateValue: PropTypes.string,
  fieldProps: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      countryName: PropTypes.string,
    }),
  ),
  countryValue: PropTypes.string,
  countryChange: PropTypes.func.isRequired,
  selectStateChange: PropTypes.func.isRequired,
  textStateChange: PropTypes.func.isRequired,
  stateHelperText: PropTypes.string,
}

StandardB3CountryGroup.defaultProps = {
  states: [],
  countries: [],
  countryValue: '',
  stateValue: '',
  stateHelperText: '',
}

export default StandardB3CountryGroup
