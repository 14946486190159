import b3request from './base'

// get sales reps
export const getSalesReps = (params) => b3request.get('/api/v2/sales-reps', { params })

// get sales reps with companies or companies with sales reps
export const getSalesRepsAndCompanies = (params) => b3request.get('/api/v2/sales-reps/companies', { params })

// delete sales rep
export const deleteSalesRepById = (id) => b3request.delete(`/api/v2/sales-reps/${id}`)

// get assigned companies by sales rep id
export const getAssignedCompaniesBySalesRepId = (saleRepId) => b3request.get(`/api/v2/sales-reps/${saleRepId}/companies`)

// update assigned companies by sales rep id
export const updateAssignedCompaniesBySalesRepId = (saleRepId, companies) => b3request.put(`/api/v2/sales-reps/${saleRepId}/companies/backend`, companies)

// upload sales rep info csv
export const uploadSalesRepInfoCsv = (data) => b3request.post('/api/v2/sales-reps/companies/bulk-creation/upload', data)

// get sales rep companies
export const getSalesRepsCompanies = ({
  id,
  params,
}) => b3request.get(`/api/v2/sales-reps/${id}/companies/backend`, { params })

export const exportSalesRepCsv = () => b3request.post('/api/v2/sales-reps/companies/export')
