export const textFieldProps = {
  0: {
    type: 'text',
  },
  1: {
    type: 'number',
  },
  2: {
    multiline: true,
    rows: 2,
    variant: 'outlined',
  },
}

export default (code) => (textFieldProps[code])
