import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Typography,
  withStyles,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import {
  MoreVert as MoreIcon,
} from '@material-ui/icons'

import {
  B3Card,
  B3TableCell as TableCell,
  NoData,
  B3ConfirmDialog,
} from '../../components'

import b3request from '../../service'

import {
  getUserRoleByCode,
  snackbar,
  checkOneOfPermissions,
} from '../../utils'
import locales from '../../locales/en-US'

class Roles extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      editIconButton: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor() {
    super()

    this.state = {
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      roles: [],
      isLoading: false,
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      isDeleteroleDialogShow: false,
      isDeletingrole: false,
    }
  }

  componentDidMount() {
    this.getRoles()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  getRoles = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: { offset, limit },
    } = this.state
    try {
      const resp = await b3request.roles.getRoles({
        offset,
        limit,
      })
      this.setState({
        roles: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: page * pagination.limit,
        },
      },
      this.getRoles,
    )
  }

  handleRowsPerPageChange = (event) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: 0,
          limit: event.target.value,
        },
      },
      this.getRoles,
    )
  }

  handleMoreActionsClick = (actionsRowId) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
    })
  }

  handleDeleteroleShow = (actionsRowId) => async () => {
    this.setState({
      actionsRowId,
      isDeleteroleDialogShow: true,
    })
  }

  handleDeleteroleCancel = () => {
    this.setState({
      isDeleteroleDialogShow: false,
      actionsRowId: '',
    })
  }

  handleroleDelete = async () => {
    const { pagination } = this.state
    this.setState({
      isDeletingrole: true,
    })
    const {
      actionsRowId,
    } = this.state

    try {
      await b3request.roles.deleteRole(actionsRowId)
      snackbar.success(locales['app.tips.roles.deleteRoleSuccessfully'])
      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getRoles)
    } catch {
      //
    }
    this.setState({
      isDeleteroleDialogShow: false,
      isDeletingrole: false,
    })
  }

  handleRouteToDetail = (state) => () => {
    const {
      history,
    } = this.props
    history.push({
      pathname: '/role',
      state,
    })
  }

  render() {
    const { classes } = this.props
    const {
      roles,
      isLoading,
      pagination,
      actionsPopoverAnchorEl,
      actionsRowId,
      isDeleteroleDialogShow,
      isDeletingrole,
    } = this.state

    return (
      <B3Card
        isLoading={isLoading}
        extra={(
          <React.B3PermissionContainer
            permissions={['15201', '15104']}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleRouteToDetail({
                mode: 'add',
              })}
            >
              Add New Role
            </Button>
          </React.B3PermissionContainer>
        )}
      >
        {roles.length === 0 ? (
          <NoData />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '60%' }}>Role Name</TableCell>
                <TableCell>Type</TableCell>
                {
                  checkOneOfPermissions([['15103'], ['15104', '15301'], ['15401']]) && (
                    <TableCell style={{ width: 100 }}>Actions</TableCell>
                  )
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {roles.map((role) => (
                <TableRow hover key={role.id}>
                  <TableCell>
                    <span>
                      {role.roleName}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>{getUserRoleByCode(role.roleType).adminRoleType}</span>
                  </TableCell>
                  {
                    checkOneOfPermissions([['15103'], ['15104', '15301'], ['15401']]) && (
                      <TableCell>
                        <IconButton
                          className={classes.editIconButton}
                          onClick={this.handleMoreActionsClick(role.id)}
                        >
                          <MoreIcon />
                        </IconButton>
                        <Menu
                          anchorEl={actionsPopoverAnchorEl}
                          open={actionsRowId === role.id}
                          onClose={this.handleMoreActionsPopoverClose}
                        >
                          <React.B3PermissionContainer
                            permissions={['15103', '15104']}
                          >
                            {({
                              ref,
                            }) => (
                              <MenuItem
                                onClick={this.handleRouteToDetail({
                                  mode: 'view',
                                  id: role.id,
                                })}
                                ref={ref}
                              >
                                <Typography variant="subtitle2">
                                  View
                                </Typography>
                              </MenuItem>
                            )}
                          </React.B3PermissionContainer>
                          {
                            role.roleType === '1' && [
                              <React.B3PermissionContainer
                                key="edit"
                                permissions={['15104', '15301']}
                              >
                                {({
                                  ref,
                                }) => (
                                  <MenuItem
                                    onClick={this.handleRouteToDetail({
                                      mode: 'edit',
                                      id: role.id,
                                    })}
                                    ref={ref}
                                  >
                                    <Typography variant="subtitle2">
                                      Edit
                                    </Typography>
                                  </MenuItem>
                                )}
                              </React.B3PermissionContainer>,
                              <React.B3PermissionContainer
                                permissions={['15401']}
                                key="delete"
                              >
                                {({
                                  ref,
                                }) => (
                                  <MenuItem
                                    ref={ref}
                                    onClick={this.handleDeleteroleShow(role.id)}
                                  >
                                    <Typography variant="subtitle2">
                                      Delete
                                    </Typography>
                                  </MenuItem>
                                )}
                              </React.B3PermissionContainer>,
                            ]
                          }
                        </Menu>
                      </TableCell>
                    )
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pagination.totalCount}
          rowsPerPage={pagination.limit}
          page={this.currentPage}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
        />

        {
          isDeleteroleDialogShow && (
            <B3ConfirmDialog
              onClose={this.handleDeleteroleCancel}
              onConfirm={this.handleroleDelete}
              isSubmitDisabled={isDeletingrole}
              isCancelDisabled={isDeletingrole}
              isSpinning={isDeletingrole}
              confirmText="Delete"
            >
              <DialogContent>
                <DialogContentText>
                  {locales['app.tips.roles.deleteConfirm']}
                </DialogContentText>
              </DialogContent>
            </B3ConfirmDialog>
          )
        }
      </B3Card>
    )
  }
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  editIconButton: {
    padding: 4,
    marginLeft: 4,
    display: 'inline-block',
  },
}

export default withStyles(styles)(Roles)
