import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  Button,
  styled,
} from '@material-ui/core'

import { toggleQtyExportReadyStatus } from '../../store/actions/qty'
import {
  toggleCompanyCsvReadyStatus,
  toggleShouldCompanyListUpdate,
} from '../../store/actions/company'

import {
  toggleSalesRepCsvReadyStatus,
  toggleShouldSalesRepListUpdate,
} from '../../store/actions/salesRep'

import { toggleExportOrdersReadyStatus } from '../../store/actions/orders'

import b3request from '../../service'
import locales from '../../locales/en-US'
import { snackbar, checkPermissions, env } from '../../utils'

const ReportButton = styled(Button)((props) => ({
  marginLeft: props.theme.spacing(),
  color: '#fff',
  borderColor: '#fff',
}))

const SnackbarSuccess = withRouter(({ notificationId, history }) => {
  const message = 'A quantity process is completed.'
  const toNotification = () => {
    history.push(`/notifications/${notificationId}`)
  }
  return notificationId ? (
    <>
      {message}
      <ReportButton
        size="small"
        variant="outlined"
        color="default"
        onClick={toNotification}
      >
        Report
      </ReportButton>
    </>
  ) : (
    <>{message}</>
  )
})

const showSnackbarDownload = (url, tips) => snackbar.success((
  <>
    { tips }
    <ReportButton
      size="small"
      variant="outlined"
      color="default"
      onClick={() => {
        window.open(url, '_blank')
      }}
    >
      Download
    </ReportButton>
  </>
), 0)

class B3Socket extends Component {
  static propTypes = {
    toggleQtyExportReadyStatus: PropTypes.func.isRequired,
    toggleCompanyCsvReadyStatus: PropTypes.func.isRequired,
    toggleShouldCompanyListUpdate: PropTypes.func.isRequired,
    toggleSalesRepCsvReadyStatus: PropTypes.func.isRequired,
    toggleShouldSalesRepListUpdate: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      ws: null,
    }
  }

  componentDidMount() {
    this.setWebsocket()
  }

  componentWillUnmount() {
    const { ws } = this.state

    if (ws) ws.close()
  }

  setWebsocket = async () => {
    const {
      toggleQtyExportReadyStatus,
      toggleCompanyCsvReadyStatus,
      toggleShouldCompanyListUpdate,
      toggleSalesRepCsvReadyStatus,
      toggleShouldSalesRepListUpdate,
    } = this.props
    const {
      isReady = '0',
      socketId = '',
    } = await b3request.qty.getSocketId() || {}

    const getImportFunc = (toggleFunc) => (stateKey, templateKey, data) => {
      if (!data) return
      const {
        fileUrl,
        isReady: status,
      } = data
      toggleFunc({ stateKey, status })
      toggleFunc({ stateKey: templateKey, status: fileUrl })
    }

    const setCompanyImport = getImportFunc(toggleCompanyCsvReadyStatus)
    const setSalesRepImport = getImportFunc(toggleSalesRepCsvReadyStatus)

    toggleQtyExportReadyStatus(isReady)

    if (socketId) {
      const url = `${env.socketUrl}/${socketId}/`
      const ws = window.WebSocket
        ? new window.WebSocket(url)
        : new window.MozWebSocket(url)
      this.setState({
        ws,
      })
      ws.onopen = () => {}
      ws.onerror = () => {}
      ws.onmessage = (evt) => {
        const {
          message = '',
          data = {},
        } = JSON.parse(evt.data)

        const {
          addressUpload,
          companyUpload,
          salesRepUpload,
        } = data

        setCompanyImport('isImportCompanyAddressesCsvReady', 'addressesTemplateUrl', addressUpload)
        setCompanyImport('isImportCompaniesCsvReady', 'companiesTemplateUrl', companyUpload)
        setSalesRepImport('isImportSalesRepCsvReady', 'salesRepTemplateUrl', salesRepUpload)

        if (message.toUpperCase() !== 'SUCCESS') {
          return snackbar.error(evt.data.message)
        }

        // qty management doesn't has a channel field in response
        if (!data.channel) {
          toggleQtyExportReadyStatus(data.isReady)
          return checkPermissions(['10001', '10002']) && snackbar.success(<SnackbarSuccess notificationId={data.notificationId} />, 0)
        }

        switch (data.channel) {
          case 'company':
            // enable export button on companies page
            toggleCompanyCsvReadyStatus({ stateKey: 'isExportCompanyCsvReady', status: '1' })
            // show a snackbar to display the file link
            showSnackbarDownload(data.url, locales['app.tips.csv.export.company.ready'])
            break
          case 'address:upload':
            toggleCompanyCsvReadyStatus({
              stateKey: 'isImportCompanyAddressesCsvReady',
              status: '1',
            })
            break
          case 'company:upload':
            toggleCompanyCsvReadyStatus({
              stateKey: 'isImportCompaniesCsvReady',
              status: '1',
            })
            toggleShouldCompanyListUpdate(true)
            break
          case 'salesRep:upload':
            toggleSalesRepCsvReadyStatus({
              stateKey: 'isImportSalesRepCsvReady',
              status: '1',
            })
            toggleShouldSalesRepListUpdate(true)
            break
          case 'order:export':
            toggleExportOrdersReadyStatus({
              stateKey: 'isExportOrdersReady',
              status: '1',
            })
            showSnackbarDownload(data.url, locales['app.tips.csv.export.order.ready'])
            break
          case 'salesRep:export':
            toggleSalesRepCsvReadyStatus({
              stateKey: 'isExportSalesRepCsvReady',
              status: '1',
            })
            showSnackbarDownload(data.url, locales['app.tips.csv.export.sales.rep.ready'])
            break
          default:
            break
        }
        return true
      }
    }
  }

  render() {
    return null
  }
}

export default connect(null, {
  toggleQtyExportReadyStatus,
  toggleCompanyCsvReadyStatus,
  toggleShouldCompanyListUpdate,
  toggleSalesRepCsvReadyStatus,
  toggleShouldSalesRepListUpdate,
})(B3Socket)
