import React from 'react'
import { render } from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import theme from './config/theme'
import App from './App'
import { Login, Register } from './pages'
import {
  B3AuthRoute,
  B3PermissionContainer,
} from './components'
import store from './store'

React.B3PermissionContainer = B3PermissionContainer

render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <B3AuthRoute path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.querySelector('#bundleb2b'),
)
