import b3request from './base'

// get companies
export const getCompanies = (params) => b3request.get('/api/v2/companies', { params })

// create company
export const createCompany = (data) => b3request.post('/api/v2/companies', data)

// delete company
export const deleteCompanyById = (companyId) => b3request.delete(`/api/v2/companies/${companyId}`)

// get company infomation by company id
export const getCompanyBasicInfoById = (id) => b3request.get(`/api/v2/companies/${id}/basic-info`)

// update company infomation by company id
export const updateCompanyBasicInfoByCompanyId = (companyId, info) => b3request.put(`/api/v2/companies/${companyId}/basic-info`, info)

// get company stats by company id
export const getCompanyStatsById = (id) => b3request.get(`/api/v2/companies/${id}/stats`)

// get company users by company id
export const getCompanyUsersByCompanyId = (companyId, params) => b3request.get(`/api/v2/companies/${companyId}/users`, { params })

// get company user by company id and user id
export const getCompanyUserById = (companyId, userId) => b3request.get(`/api/v2/companies/${companyId}/users/${userId}`)

// update company user by company id and user id
export const updateCompanyUserById = (companyId, userId, params) => b3request.put(`/api/v2/companies/${companyId}/users/${userId}`, params)

// delete company user by company id and user id
export const deleteCompanyUserById = (companyId, userId) => b3request.delete(`/api/v2/companies/${companyId}/users/${userId}`)

// get company payment methods by company id
export const getPaymentMethodsByCompanyId = (companyId) => b3request.get(`/api/v2/companies/${companyId}/payments`)

// update company payment methods by company id
export const updatePaymentMethodsByCompanyId = (companyId, params) => b3request.put(`/api/v2/companies/${companyId}/payments`, params)

// get sales reps of company by companyId
export const getSalesRepsByCompanyId = (companyId) => b3request.get(`/api/v2/companies/${companyId}/sales-reps`)

// update sales reps of company by companyId
export const updateSalesRepsByCompanyId = (companyId, params) => b3request.put(`/api/v2/companies/${companyId}/sales-reps/backend`, params)

// validate compoany user email
export const validateUserEmail = (email, role, companyId = '') => b3request.get(`/api/v2/companies/validations/backend/user-emails/${email}`, { params: { role, companyId } })

// get customer groups
export const getCustomerGoups = (params) => b3request.get('/api/v2/customer-groups', { params })

// convert company from Customer group
export const convertCompanyFromCustomerGroup = (data) => b3request.post('/api/v2/companies/convert-from-customer-group', data)

// get addressbook
export const getAddressBook = (companyId, data) => b3request.post(`/api/v2/companies/${companyId}/addresses/searches`, data)

// get default addresses
export const getDefaultAddresses = (companyId, params) => b3request.get(`/api/v2/companies/${companyId}/default-addresses`, { params })

// get address by company id and address id
export const getAddressById = (companyId, addressId) => b3request.get(`/api/v2/companies/${companyId}/addresses/${addressId}`)

// create address
export const createAddress = (companyId, data) => b3request.post(`/api/v2/companies/${companyId}/addresses`, data)

// update address
export const updateAddress = (companyId, addressId, data) => b3request.put(`/api/v2/companies/${companyId}/addresses/${addressId}`, data)

// delete an address logically
export const deleteAddressLogically = (companyId, addressId, isActive) => b3request.put(`/api/v2/companies/${companyId}/addresses/${addressId}/activation`, { isActive })

// get countries from BC
export const getCountries = () => b3request.get('/api/v2/companies/addresses/countries')

// begin to export CSV
export const beginToExportCompanyInfoToCsv = () => b3request.post('/api/v2/companies/begin-export')

// get company info CSV upload
export const getCompaniesInfoToCsv = () => b3request.get('/api/v2/companies/bulk-creation/upload')

// get address CSV upload
export const getCompaniesAddressesInfoToCsv = () => b3request.get('/api/v2/companies/addresses/bulk-creation/upload')

// upload company info csv
export const uploadCompaniesInfoCsv = (data) => b3request.post('/api/v2/companies/bulk-creation/upload', data)

// upload company address csv
export const uploadCompanyaddressesInfoCsv = (data) => b3request.post('/api/v2/companies/addresses/bulk-creation/upload', data)

// get companies extra fields
export const getCompaniesExtraFields = () => b3request.get('/api/v2/companies/extra_fields')

export const getCompanySalesReps = ({
  id,
  params,
}) => b3request.get(`/api/v2/companies/${id}/sales-reps/backend`, { params })
