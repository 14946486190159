import React, { Component } from 'react'

import Acumatica from './Acumatica'

export default class Integrations extends Component {
  render() {
    return (
      <Acumatica />
    )
  }
}
