import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Divider,
  styled,
  Link,
} from '@material-ui/core'

import { connect } from 'react-redux'

import {
  B3Card,
} from '../../components'

import b3request from '../../service'
import { env } from '../../utils'

import Dropfile from './Dropfile'

const StyledButton = styled(Button)((props) => ({
  marginTop: props.theme.spacing(),
  marginBottom: props.theme.spacing(2),
}))

const ActionTitle = styled(Typography)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(),
}))

class AdvancedQuantityManagement extends PureComponent {
  static propTypes = {
    isQtyReady: PropTypes.oneOf(['0', '1']).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileUrl: '',
    }
    this.downloadExportedExcelLink = createRef()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  toggleLoading = (isLoading) => {
    this.setState({
      isLoading,
    })
  }

  handleExport = async () => {
    this.setState({
      isLoading: true,
    })
    const { fileUrl } = await b3request.qty.getExportQtyData()
    if (fileUrl) {
      this.setState({
        fileUrl,
      }, () => {
        this.downloadExportedExcelLink.current.click()
      })
    }
    this.setState({
      isLoading: false,
    })
  }

  render() {
    const {
      isLoading,
      fileUrl,
    } = this.state
    const {
      isQtyReady,
    } = this.props
    return (
      <B3Card
        title="Advanced Quantity Management"
        isLoading={isLoading}
      >
        <Typography paragraph>
          This feature allows you to control the quantity field for products. This gives you the ability to set desired Interval Quantities and Minimum Quantities for your eCommerce store.
        </Typography>
        <Typography paragraph>
          Interval Quantities Control: Regulate the number of products a customer can purchase at a time. This is ideal for products that are packaged in containers or boxes. For example, if the interval quantity is 5, customers can only purchase 5, 10, 15, etc. units of the product.
        </Typography>
        <Typography paragraph>
          Minimum Quantities Control: Customers will need to purchase a minimum quantity of the specific product. This is ideal for wholesale or bulk products. For example, if the minimum quantity is set to 10, the customer must buy at least 10 units of the product. They cannot purchase any number lower than 10.
        </Typography>
        <Divider />
        <React.B3PermissionContainer
          permissions={['16106']}
        >
          <ActionTitle variant="h5">
            Export
          </ActionTitle>
          <Typography paragraph>
            To back up or create a copy, export price lists as Excel (.xls) files.
          </Typography>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={isQtyReady === '0'}
            onClick={this.handleExport}
          >
            Export
          </StyledButton>
          {(fileUrl && isQtyReady === '1') && (
            <Typography paragraph>
              The file is downloading. If nothing appears, please click
              <Link color="secondary" ref={this.downloadExportedExcelLink} href={fileUrl}> here </Link>
              to manually download the file.
            </Typography>
          )}
          <Divider />
        </React.B3PermissionContainer>
        <React.B3PermissionContainer
          permissions={['16105']}
        >
          <ActionTitle variant="h5">
            Import
          </ActionTitle>
          <Typography paragraph>
            Drag and drop Excel files to upload instantly or click to browse and select the right Excel files.
          </Typography>
          {
            isQtyReady === '1' && <Link href={env.qtyTemplateUrl}>Download Template</Link>
          }
          <Dropfile
            toggleLoading={this.toggleLoading}
          />
        </React.B3PermissionContainer>

      </B3Card>
    )
  }
}

const mapStateToProp = (state) => ({
  isQtyReady: state.qty.isQtyReady,
})

export default connect(mapStateToProp)(AdvancedQuantityManagement)
