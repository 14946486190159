import React, { Component } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
} from '@material-ui/core'
import {
  B3Spin,
} from '../../../components'
import { snackbar } from '../../../utils'
import b3request from '../../../service'

import locales from '../../../locales/en-US'

export default class EntitySetting extends Component {
  tableRowKeys = ['active', 'localEntity', 'externalEntity', 'direction']

  constructor() {
    super()
    this.state = {
      isLoading: false,
      switches: [{
        switchName: 'company',
        isActive: '0',
        localEntity: 'Company',
        externalEntity: 'Customer',
        direction: 'Bidirectional',
      },
      {
        switchName: 'address',
        isActive: '0',
        localEntity: 'Company Address',
        externalEntity: 'Customer Location',
        direction: 'Bidirectional',
      },
      {
        switchName: 'user',
        isActive: '0',
        localEntity: 'Company User',
        externalEntity: 'Contact',
        direction: 'Bidirectional',
      },
      {
        switchName: 'order',
        isActive: '0',
        localEntity: 'Order',
        externalEntity: 'Sales Order',
        direction: 'Export',
      },
      {
        switchName: 'shipment',
        isActive: '0',
        localEntity: 'Shipment',
        externalEntity: 'Shipment',
        direction: 'import',
      }],
    }
  }

  componentDidMount() {
    this.getAcumaticaSwitches()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  handleSwitchChange = async (event) => {
    this.setState({
      isLoading: true,
    })

    try {
      const { switches } = this.state
      const { name: switchName } = event.target
      const currentSwitch = switches.find((item) => item.switchName === switchName)
      if (currentSwitch) {
        const resp = await b3request.integration.updateAcumaticSwitch({
          switchName,
          isActive: currentSwitch.isActive === '1' ? '0' : '1',
        })
        if (resp.switchName) {
          this.getAcumaticaSwitches()
        }
      }
    } catch (error) {
      snackbar.error(error.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  getAcumaticaSwitches = async () => {
    this.setState({
      isLoading: true,
    })
    try {
      const { switches } = await b3request.integration.getAcumaticaSwitches()
      const { switches: oldSwitches } = this.state
      const newSwitches = oldSwitches.map((switchItem) => {
        const isActive = switches.find((item) => item.switchName === switchItem.switchName)?.isActive ?? oldSwitches.isActive
        return {
          ...switchItem,
          isActive,
        }
      })
      this.setState({
        switches: newSwitches,
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const {
      isLoading,
      switches,
    } = this.state
    return (
      <B3Spin
        isSpinning={isLoading}
      >
        <Table>
          <TableHead>
            <TableRow>
              {
                this.tableRowKeys.map((tableRowKey) => (
                  <TableCell key={tableRowKey}>{locales[`acumatica.entitySetting.table.thead.${tableRowKey}`]}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              switches.map((switchItem) => (
                <TableRow key={switchItem.switchName}>
                  <TableCell>
                    <Switch
                      checked={switchItem.isActive === '1'}
                      onChange={this.handleSwitchChange}
                      name={switchItem.switchName}
                    />
                  </TableCell>
                  <TableCell>{switchItem.localEntity}</TableCell>
                  <TableCell>{switchItem.externalEntity}</TableCell>
                  <TableCell>{switchItem.direction}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </B3Spin>
    )
  }
}
