import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  TablePagination,
  styled,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'

import {
  MoreVert as MoreIcon,
} from '@material-ui/icons'

import {
  NoData,
  B3Spin,
  B3Tag,
  B3Search,
} from '../../components'

import {
  snackbar,
  getUserRoleByCode,
  checkOneOfPermissions,
  COMPANYSTATUS,
  getUrlQueries,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

import b3request from '../../service'
import locales from '../../locales/en-US'

import DeleteCompanyUserConfirm from './DeleteCompanyUserConfirm'
import UserForm from './UserForm'

const HeaderWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

const HeaderRest = styled('div')((props) => ({
  flex: 1,
  padding: props.theme.spacing(2),
}))

const RoleFilter = (props) => {
  const {
    roleList,
    checkedList,
    onChange,
  } = props
  return (
    <HeaderRest>
      <FormGroup row>
        {
          roleList.map((role) => (
            <FormControlLabel
              key={role}
              control={(
                <Checkbox
                  checked={checkedList.includes(role)}
                  value={role}
                  onChange={onChange}
                />
              )}
              label={getUserRoleByCode(role).text}
            />
          ))
        }
      </FormGroup>
    </HeaderRest>
  )
}

RoleFilter.propTypes = {
  roleList: PropTypes.arrayOf(PropTypes.oneOf(['0', '1', '2'])).isRequired,
  checkedList: PropTypes.arrayOf(PropTypes.oneOf(['0', '1', '2'])).isRequired,
  onChange: PropTypes.func.isRequired,
}

class CompanyUsers extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      ellipsis: PropTypes.string.isRequired,
      editIconButton: PropTypes.string.isRequired,
    }).isRequired,
    getSummary: PropTypes.func.isRequired,
    initCompanyPage: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      isDeleteCompanyUserConfirmOpen: false,
      isUserFormOpen: false,
      actionsUserName: '',
      users: [],
      pagination: {
        offset: 0,
        limit: 6,
        totalCount: 0,
      },
      filter: {
        role: ['0', '1', '2'],
      },
      actionsRowId: '',
      actionsPopoverAnchorEl: null,
      userFormConfirmText: 'Add',
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  get companyStatus() {
    const { location } = this.props
    const { search } = location
    return getUrlQueries('companyStatus', search)
  }

  getUsers = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      match,
    } = this.props

    const {
      filter: {
        role,
      },
      pagination,
      pagination: {
        offset,
        limit,
      },
      q,
    } = this.state

    try {
      const resp = await b3request.companies.getCompanyUsersByCompanyId(match.params.id, {
        role,
        offset,
        limit,
        q,
      })

      this.setState({
        users: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch {
      //
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getUsers)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getUsers)
  }

  handleMoreActionsClick = (actionsRowId, actionsUserName) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
      actionsUserName,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      actionsUserName: '',
      isDeleteCompanyUserConfirmOpen: false,
    })
  }

  handleUserEdit = () => {
    this.setState({
      isUserFormOpen: true,
      userFormConfirmText: 'Edit',
    })
  }

  handleUserAdd = () => {
    this.setState({
      isUserFormOpen: true,
      userFormConfirmText: 'Add',
    })
  }

  handleUserFormCancel = (reload) => {
    this.setState({
      isUserFormOpen: false,
    }, this.handleMoreActionsPopoverClose)
    if (reload) this.getUsers()
  }

  handleUserDelete = () => {
    this.setState({
      isDeleteCompanyUserConfirmOpen: true,
    })
  }

  handleDeleteCompanyUserConfirm = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      actionsRowId,
    } = this.state
    const {
      match,
      getSummary,
    } = this.props
    try {
      const resp = await b3request.companies.deleteCompanyUserById(match.params.id, actionsRowId)
      const {
        pagination,
      } = this.state
      if (resp.userId) {
        snackbar.success(locales['app.tips.company.deletedCompanyUserSuccessfully'])
        getSummary()
      }
      this.setState({
        isDeleteCompanyUserConfirmOpen: false,
        actionsRowId: '',
        actionsUserName: '',
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getUsers)
    } catch (error) {
      snackbar.error(error.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleRoleChange = (e) => {
    const {
      filter: {
        role,
      },
      filter,
    } = this.state
    const isChecked = role.includes(e.target.value)
    const newRole = isChecked ? role.filter((roleItem) => roleItem !== e.target.value) : [...role, e.target.value]
    this.setState({
      filter: {
        ...filter,
        role: newRole,
      },
    }, this.getUsers)
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getUsers)
  }

  render() {
    const {
      isLoading,
      users,
      pagination,
      actionsPopoverAnchorEl,
      actionsRowId,
      actionsUserName,
      q,
      filter: {
        role,
      },
      isDeleteCompanyUserConfirmOpen,
      isUserFormOpen,
      userFormConfirmText,
    } = this.state
    const {
      classes,
      initCompanyPage,
    } = this.props
    return (
      <B3Spin
        isSpinning={isLoading}
        tip="loading…"
      >
        <Grid
          container
        >
          <HeaderWrapper
            item
            xs={12}
          >
            {this.companyStatus !== COMPANYSTATUS.INACTIVE && (
              <>
                <B3Search
                  value={q}
                  onChange={this.handleSearchChange}
                  onSearch={this.handleSearch}
                  placeholder="search user name…"
                />
                <RoleFilter
                  roleList={['0', '1', '2']}
                  checkedList={role}
                  onChange={this.handleRoleChange}
                />
              </>
            )}
            <React.B3PermissionContainer
              permissions={['11107', '11204']}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleUserAdd}
              >
                {this.companyStatus === COMPANYSTATUS.INACTIVE ? 'Add Admin' : 'Add User'}
              </Button>
            </React.B3PermissionContainer>

          </HeaderWrapper>
          <Grid
            item
            xs={12}
          >
            {
              users.length === 0 ? <NoData /> : (
                <>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>User Name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Updated At</TableCell>
                        {
                          checkOneOfPermissions([['11103', '11301'], ['11402']]) && (
                            <TableCell style={{ width: '80px' }}>Actions</TableCell>
                          )
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow
                          hover
                          key={user.id}
                        >
                          <TableCell>
                            <span className={classes.ellipsis}>
                              {`${user.firstName} ${user.lastName}`}
                            </span>
                          </TableCell>
                          <TableCell>
                            {user.phoneNumber}
                          </TableCell>
                          <TableCell>
                            {user.email}
                          </TableCell>
                          <TableCell>
                            <B3Tag
                              color={getUserRoleByCode(user.role).color}
                            >
                              {getUserRoleByCode(user.role).text}
                            </B3Tag>
                          </TableCell>
                          <TableCell>{b3DateFormat.displayFormat(user.updatedAt)}</TableCell>
                          {
                            checkOneOfPermissions([['11103', '11301'], ['11402']]) && (
                              <TableCell>
                                <IconButton
                                  className={classes.editIconButton}
                                  onClick={this.handleMoreActionsClick(user.id, `${user.firstName} ${user.lastName}`)}
                                >
                                  <MoreIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={actionsPopoverAnchorEl}
                                  open={actionsRowId === user.id}
                                  onClose={this.handleMoreActionsPopoverClose}
                                >
                                  <React.B3PermissionContainer
                                    permissions={['11103', '11301']}
                                  >
                                    {({
                                      ref,
                                    }) => (
                                      <MenuItem
                                        ref={ref}
                                        onClick={this.handleUserEdit}
                                      >
                                        <Typography variant="subtitle2">
                                          Edit
                                        </Typography>
                                      </MenuItem>
                                    )}
                                  </React.B3PermissionContainer>
                                  <React.B3PermissionContainer
                                    permissions={['11402']}
                                  >
                                    {({
                                      ref,
                                    }) => (
                                      <MenuItem
                                        ref={ref}
                                        onClick={this.handleUserDelete}
                                      >
                                        <Typography variant="subtitle2">
                                          Delete
                                        </Typography>
                                      </MenuItem>
                                    )}
                                  </React.B3PermissionContainer>
                                </Menu>
                              </TableCell>
                            )
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[6, 10, 20]}
                    component="div"
                    count={pagination.totalCount}
                    rowsPerPage={pagination.limit}
                    page={this.currentPage}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                  />
                </>
              )
            }
          </Grid>
        </Grid>
        {
          isUserFormOpen && (
            <UserForm
              onCancel={this.handleUserFormCancel}
              confirmText={userFormConfirmText}
              afterAdminAdd={initCompanyPage}
              userId={actionsRowId}
              companyStatus={this.companyStatus}
            />
          )
        }
        <DeleteCompanyUserConfirm
          isOpen={isDeleteCompanyUserConfirmOpen}
          onClose={this.handleMoreActionsPopoverClose}
          onConfirm={this.handleDeleteCompanyUserConfirm}
          isDeleting={isLoading}
          userName={actionsUserName}
        />
      </B3Spin>
    )
  }
}
const styles = {
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: 'calc(100% - 40px)',
  },
  editIconButton: {
    padding: 4,
    marginLeft: 4,
    display: 'inline-block',
  },
}

export default withStyles(styles)(withRouter(CompanyUsers))
