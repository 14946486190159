import React from 'react'
import PropTypes from 'prop-types'

import {
  TextField,
  styled,
  Button,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
} from '@material-ui/core'

import {
  B3Select,
} from '../../components'


const Wrapper = styled('div')((props) => ({
  padding: props.theme.spacing(2),
  backgroundColor: props.theme.palette.grey[100],
}))

const FormContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const FormItem = styled('div')({
  flexBasis: '20%',
  padding: 8,
  '&.c2': {
    flexBasis: '40%',
  },
})

const ActionContainer = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: props.theme.spacing(),
}))

export default function AddressBookFilterForm(props) {
  const {
    filters,
    countries,
    onChange,
    onClear,
    onApply,
  } = props

  const { states = [] } = countries.filter((country) => country.countryName === filters.country)[0] || {}

  const handleTextFieldChange = (key) => (e) => {
    onChange({
      ...filters,
      [key]: e.target.value,
    })
  }

  const handleSelectChange = (key) => (v) => {
    const newFilters = {
      [key]: v,
    }

    if (key === 'country') {
      newFilters.state = ''
    }

    onChange({
      ...filters,
      ...newFilters,
    })
  }

  const toggleCheckobxChange = (key) => () => {
    onChange({
      ...filters,
      addressType: {
        ...filters.addressType,
        [key]: filters.addressType[key] !== '1' ? '1' : '',
      },
    })
  }

  return (
    <Wrapper>
      <FormContainer>
        <FormItem>
          <TextField
            fullWidth
            value={filters.firstName}
            label="First Name"
            onChange={handleTextFieldChange('firstName')}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.lastName}
            label="Last Name"
            onChange={handleTextFieldChange('lastName')}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.address}
            label="Address"
            onChange={handleTextFieldChange('address')}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.city}
            label="City"
            onChange={handleTextFieldChange('city')}
          />
        </FormItem>
        <FormItem>
          <B3Select
            options={countries.map((country) => country.countryName)}
            value={filters.country}
            label="Country"
            onChange={handleSelectChange('country')}
          />
        </FormItem>
        <FormItem>
          {
            states.length > 0 ? (
              <B3Select
                options={states.map((state) => state.stateName)}
                value={filters.state}
                label="State"
                onChange={handleSelectChange('state')}
              />
            ) : (
              <TextField
                fullWidth
                value={filters.state}
                label="State"
                onChange={handleTextFieldChange('state')}
              />
            )
          }
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.zipCode}
            label="Zipcode"
            onChange={handleTextFieldChange('zipCode')}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.phoneNumber}
            label="Phone"
            onChange={handleTextFieldChange('phoneNumber')}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            value={filters.label}
            label="Address Label"
            onChange={handleTextFieldChange('label')}
          />
        </FormItem>
        <FormItem
          className="c2"
        >
          <div>
            <FormLabel>Address Type:</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filters.addressType.isShipping === '1'}
                    onChange={toggleCheckobxChange('isShipping')}
                    value="Shipping"
                  />
                )}
                label="Shipping"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filters.addressType.isBilling === '1'}
                    onChange={toggleCheckobxChange('isBilling')}
                    value="Billing"
                  />
                )}
                label="Billing"
              />
            </FormGroup>
          </div>
        </FormItem>
      </FormContainer>
      <ActionContainer>
        <ButtonGroup
          size="small"
        >
          <Button
            onClick={() => onClear()}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onApply()}
          >
            Apply
          </Button>
        </ButtonGroup>
      </ActionContainer>
    </Wrapper>
  )
}

AddressBookFilterForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    addressType: PropTypes.shape({
      isShipping: PropTypes.string.isRequired,
      isBilling: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
}
