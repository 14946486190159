import { combineReducers } from 'redux'

import global from './global'
import qty from './qty'
import settings from './settings'
import company from './company'
import salesRep from './salesRep'
import orders from './orders'

export default combineReducers({
  global,
  qty,
  settings,
  company,
  salesRep,
  orders,
})
