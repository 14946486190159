export default {
  'app.validation.errors.required.companyName': 'Company Name is a required field',
  'app.validation.errors.required.email': 'Email is a required field',
  'app.validation.errors.required.lastName': 'Last Name is a required field',
  'app.validation.errors.required.firstName': 'First Name is a required field',
  'app.validation.errors.required.phoneNumber': 'Phone Number is a required field',
  'app.validation.errors.required.addressLine1': 'Address Line 1 is a required field',
  'app.validation.errors.required.city': 'City is a required field',
  'app.validation.errors.required.country': 'Country is a required field',
  'app.validation.errors.required.zipCode': 'Zipcode is a required field',
  'app.validation.errors.required.state': 'State is a required field',
  'app.validation.errors.required.password': 'Password is a required field',
  'app.validation.errors.required.confirmed.password': 'Confirmed Password is a required field',
  'app.validation.errors.required.permisstion': 'User role is a required field',
  'app.validation.errors.required.roleName': 'Role Name is a required field',

  'app.validation.errors.invalid.phoneNumber': 'Please enter a valid phone number',
  'app.validation.errors.invalid.email': 'Please enter a valid email address',
  'app.validation.errors.min.password': 'Password must be at least 6 characters',
  'app.validation.errors.max.password': 'Password must be at most 16 characters',
  'app.validation.errors.password.not.match': 'Passwords must match',
}
