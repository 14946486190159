export default [
  {
    title: 'View Dashboard',
    permissionModuleId: [],
    child: [
      {
        title: 'View Notifications',
        permissionModuleId: ['10000'],
        child: [
          {
            title: 'View Notification Detail',
            permissionModuleId: ['10001', '10002'],
          },
        ],
      },
    ],
  },
  {
    title: 'View Companies',
    permissionModuleId: ['11100'],
    child: [
      {
        title: 'Add New Company',
        permissionModuleId: ['11200', '11106', '11107', '11201'],
      },
      {
        title: 'Delete Company',
        permissionModuleId: ['11401'],
      },
      {
        title: 'Update Price List',
        permissionModuleId: ['11113', '11300'],
      },
      {
        title: 'Export Company CSV',
        permissionModuleId: ['11500'],
      },
      {
        title: 'Import Companies',
        permissionModuleId: ['11203', '11206'],
      },
      {
        title: 'View Company Detail',
        permissionModuleId: ['11105'],
        child: [
          {
            title: 'View Basic Info',
            permissionModuleId: ['11101'],
            child: [
              {
                title: 'Update Basic Info',
                permissionModuleId: ['11300'],
              },
            ],
          },
          {
            title: 'View Company Users',
            permissionModuleId: ['11102'],
            child: [
              {
                title: 'Add User',
                permissionModuleId: ['11107', '11204'],
              },
              {
                title: 'Update User',
                permissionModuleId: ['11103', '11301'],
              },
              {
                title: 'Delete User',
                permissionModuleId: ['11402'],
              },
            ],
          },
          {
            title: 'View Sales Rep[s]',
            permissionModuleId: ['13102'],
            child: [
              {
                title: 'Update Sales Rep[s]',
                permissionModuleId: ['11304'],
              },
            ],
          },
          {
            title: 'View Payment Methods',
            permissionModuleId: ['11104'],
            child: [
              {
                title: 'Update Payment Methods',
                permissionModuleId: ['11302'],
              },
            ],
          },
          {
            title: 'View Address Book',
            permissionModuleId: ['11111', '11112', '11110'],
            child: [
              {
                title: 'Add New Address',
                permissionModuleId: ['11205'],
              },
              {
                title: 'Update Address',
                permissionModuleId: ['11109', '11303'],
              },
              {
                title: 'Delete Address',
                permissionModuleId: ['11400'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'View Quotes',
    permissionModuleId: ['12100'],
    child: [
      {
        title: 'View Quote',
        permissionModuleId: ['12101'],
      },
      {
        title: 'Approve/Reject Quote',
        permissionModuleId: ['12300'],
      },
    ],
  },
  {
    title: 'View Sales Reps',
    permissionModuleId: ['13101'],
    child: [
      {
        title: 'Import Sales Reps',
        permissionModuleId: ['13200'],
      },
      {
        title: 'Export Sales Reps',
        permissionModuleId: ['13200'],
      },
      {
        title: 'Add New Sales Rep',
        permissionModuleId: ['11107', '11204', '13301'],
      },
      {
        title: 'Delete Sales Rep',
        permissionModuleId: ['13400'],
      },
      {
        title: 'Sales Rep/View Companies',
        permissionModuleId: ['11108'],
      },
      {
        title: 'Company/View Sales Reps',
        permissionModuleId: ['13102'],
      },
      {
        title: 'Edit Company',
        permissionModuleId: ['11105'],
      },
      {
        title: 'Edit Sales Rep',
        permissionModuleId: ['13100', '11108', '13300', '13301'],
      },
    ],
  },
  {
    title: 'View Orders',
    permissionModuleId: ['14100'],
    child: [
      {
        title: 'Export Orders',
        permissionModuleId: ['14500'],
        child: [
          {
            title: 'Choice SalesRep',
            permissionModuleId: ['13101'],
          },
        ],
      },
    ],
  },
  {
    title: 'View Advanced Quantity Management',
    permissionModuleId: [],
    child: [
      {
        title: 'Export Advanced Quantity Management',
        permissionModuleId: ['16106'],
      },
      {
        title: 'Import Advanced Quantity Management',
        permissionModuleId: ['16105'],
      },
    ],
  },
  // {
  //   title: 'View Integration',
  //   permissionModuleId: ['17100'],
  //   child: [
  //     {
  //       title: 'Update Entity',
  //       permissionModuleId: [],
  //     },
  //     {
  //       title: 'View Sync Status',
  //       permissionModuleId: [],
  //     }
  //   ]
  // },
  {
    title: 'View Settings',
    permissionModuleId: [],
    child: [
      {
        title: 'Update Settings',
        permissionModuleId: ['16101', '16102', '16103', '16104'],
      },
    ],
  },
  {
    title: 'View Users',
    permissionModuleId: ['15100'],
    child: [
      {
        title: 'Add New User',
        permissionModuleId: ['15102', '15200'],
      },
      {
        title: 'Update User',
        permissionModuleId: ['15102', '15101', '15300'],
      },
      {
        title: 'Delete User',
        permissionModuleId: ['15400'],
      },
    ],
  },
  {
    title: 'View Roles',
    permissionModuleId: ['15102'],
    child: [
      {
        title: 'View Role',
        permissionModuleId: ['15103', '15104'],
        child: [
          {
            title: 'Update Role',
            permissionModuleId: ['15301'],
          },
        ],
      },
      {
        title: 'Add New Role',
        permissionModuleId: ['15201'],
      },
      {
        title: 'Delete Role',
        permissionModuleId: ['15401'],
      },
    ],
  },
]
