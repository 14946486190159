import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { isEqual, cloneDeep } from 'lodash'
import {
  Button,
  ButtonGroup,
} from '@material-ui/core'

import {
  B3Spin,
} from '../../components'

import b3request from '../../service'
import { snackbar } from '../../utils'
import locales from '../../locales/en-US'

import SalesRepsList from './SalesRepsList'

class SalesRepAssignments extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    getSummary: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  initAssignedSalesReps = []

  constructor() {
    super()
    this.state = {
      assignedSalesReps: [],
      isEditting: false,
      isLoadingAssignedSalesRep: false,
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = () => false
  }

  handleEditClick = () => {
    this.setState({
      isEditting: true,
    })
  }

  handleEdittingCancel = () => {
    this.setState({
      isEditting: false,
      assignedSalesReps: cloneDeep(this.initAssignedSalesReps),
    })
  }

  handleSave = async () => {
    this.setState({
      isLoadingAssignedSalesRep: true,
    })

    const {
      match,
    } = this.props

    const {
      assignedSalesReps,
    } = this.state

    try {
      await b3request.companies.updateSalesRepsByCompanyId(match.params.id, { salesReps: assignedSalesReps })
      snackbar.success(locales['app.salesRep.updateSalesRepSuccessfully'])
      this.initAssignedSalesReps = cloneDeep(assignedSalesReps)
      this.setState({
        assignedSalesReps: [],
      })
      const {
        getSummary,
      } = this.props
      getSummary()
    } catch (error) {
      snackbar.error(locales['app.salesRep.updateSalesRepFailed'])
    }

    this.setState({
      isLoadingAssignedSalesRep: false,
      isEditting: false,
    })
  }

  handleAssignedSalesRepsChange = (salesRep, clear) => {
    const { assignedSalesReps } = this.state
    if (clear) {
      this.setState({
        assignedSalesReps: [],
      })
      return
    }
    const salesRepIndex = assignedSalesReps.findIndex(({ salesRepId: assignedSalesRepId }) => assignedSalesRepId === salesRep.salesRepId)
    if (salesRepIndex === -1) assignedSalesReps.push(salesRep)
    else assignedSalesReps.splice(salesRepIndex, 1)
    this.setState({
      assignedSalesReps: assignedSalesReps.map(({
        salesRepId,
        isAssigned,
      }) => ({
        salesRepId,
        isAssigned,
      })),
    })
  }

  render() {
    const {
      assignedSalesReps,
      isEditting,
      isLoadingAssignedSalesRep,
    } = this.state
    const {
      match,
    } = this.props

    return (
      <B3Spin
        isSpinning={isLoadingAssignedSalesRep}
      >
        <SalesRepsList
          id={match.params.id}
          onChange={this.handleAssignedSalesRepsChange}
          disabled={!isEditting}
          assignedSalesReps={assignedSalesReps}
        />
        {
          isEditting && (
            <ButtonGroup
              size="small"
            >
              <Button
                onClick={this.handleEdittingCancel}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSave}
                disabled={isEqual(this.initAssignedSalesReps, assignedSalesReps)}
              >
                save
              </Button>
            </ButtonGroup>
          )
        }
        {
          !isEditting && (
            <React.B3PermissionContainer
              permissions={['11304']}
            >
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleEditClick}
              >
                Edit
              </Button>
            </React.B3PermissionContainer>
          )
        }
      </B3Spin>
    )
  }
}

export default withRouter(SalesRepAssignments)
