import b3request from './base'

// get export qty data
export const getExportQtyData = () => b3request.get('/api/v2/qty/export')

// upload qty excel
export const uploadQtyExcel = (data) => b3request.post('/api/v2/qty/upload', data)

// get socket id
export const getSocketId = () => b3request.get('/api/v2/qty/upload')
