import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  IconButton,
  Typography,
  withStyles,
  Tooltip,
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  Switch,
  TablePagination,
  Button,
  Fade,
} from '@material-ui/core'

import {
  Help as HelpIcon,
} from '@material-ui/icons'

import {
  LocalizationProvider,
  DateRangeDelimiter,
  DateRangePicker,
} from '@material-ui/pickers'

import MomentUtils from '@material-ui/pickers/adapter/moment'
import moment from 'moment'

import {
  B3Search,
  B3Spin,
} from '../../components'

import b3DateFormat from '../../utils/b3DateFormat'
import b3request from '../../service'
import {
  snackbar,
  checkPermissions,
} from '../../utils'

import locales from '../../locales/en-US'

import { toggleExportOrdersReadyStatus } from '../../store/actions/orders'

class OrdersExport extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      filedContainer: PropTypes.string.isRequired,
      filedLabel: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      actions: PropTypes.string.isRequired,
    }).isRequired,
    orderStatus: PropTypes.arrayOf(PropTypes.object).isRequired,
    isExportOrdersReady: PropTypes.oneOf(['0', '1']).isRequired,
    onClose: PropTypes.func.isRequired,
    toggleExportOrdersReadyStatus: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    const endDate = new Date()
    const beginDate = new Date(endDate.getTime() - 2592000000)

    const exportCheckedStatus = props.orderStatus.map(({
      statusCode,
    }) => statusCode)

    this.state = {
      q: '',
      exportCheckedStatus,
      exportDateRange: [beginDate, endDate],
      salesRepIds: [],
      isCheckedAllSalesRep: true,
      salesReps: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      isSalesRepLoading: false,
      isSendingExport: false,
    }
  }

  get isCheckedAllExportStatus() {
    const {
      exportCheckedStatus,
    } = this.state
    const {
      orderStatus,
    } = this.props

    const isAllChecked = JSON.stringify(exportCheckedStatus.sort()) === JSON.stringify(orderStatus.map(({
      statusCode,
    }) => statusCode).sort())

    return !!exportCheckedStatus.length && isAllChecked
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  get isExportDisabled() {
    const {
      isExportOrdersReady,
    } = this.props
    const {
      isCheckedAllSalesRep,
      salesRepIds,
      exportCheckedStatus,
      isSendingExport,
    } = this.state

    return !(
      !isSendingExport
      && isExportOrdersReady === '1'
      && exportCheckedStatus.length
      && (isCheckedAllSalesRep ? true : salesRepIds.length)
    )
  }

  getSalesReps = async () => {
    this.setState({
      isSalesRepLoading: true,
    })

    const {
      q,
      pagination: {
        offset,
        limit,
      },
    } = this.state
    const {
      list = [],
      pagination,
    } = await b3request.salesReps.getSalesRepsAndCompanies({
      q,
      groupBy: '1',
      orderBy: 'desc',
      offset,
      limit,
    })
    this.setState({
      salesReps: list,
      pagination,
      isSalesRepLoading: false,
    })
  }

  handleToggleAllExportStatus = () => {
    const {
      orderStatus,
    } = this.props

    const newExportCheckedStatus = this.isCheckedAllExportStatus
      ? []
      : [...orderStatus.map(({
        statusCode,
      }) => statusCode)]

    this.setState({
      exportCheckedStatus: newExportCheckedStatus,
    })
  }

  getExportStatusChecked = (statusCode) => {
    const {
      exportCheckedStatus,
    } = this.state

    return exportCheckedStatus.includes(statusCode)
  }

  handleToggleExportStatusChecked = (statusCode) => () => {
    const {
      exportCheckedStatus,
    } = this.state

    const statusIndex = exportCheckedStatus.indexOf(statusCode)
    if (statusIndex === -1) exportCheckedStatus.push(statusCode)
    else exportCheckedStatus.splice(statusIndex, 1)

    this.setState({
      exportCheckedStatus: [...exportCheckedStatus],
    })
  }

  handleExportDateRange = (exportDateRange) => {
    this.setState({
      exportDateRange,
    })
  }

  handleExportOrders = async () => {
    const {
      onClose,
    } = this.props

    this.setState({
      isSendingExport: true,
    })

    const {
      salesRepIds,
      isCheckedAllSalesRep,
      exportCheckedStatus: status,
      exportDateRange: [
        beginDateAt,
        endDateAt,
      ],
    } = this.state

    const params = {
      status,
      beginDateAt: moment(beginDateAt).utc().format('MM/DD/YYYY'),
      endDateAt: moment(endDateAt).utc().format('MM/DD/YYYY'),
      salesRepIds: isCheckedAllSalesRep ? [] : salesRepIds,
    }

    try {
      const { isReady } = await b3request.orders.exportOrders(params)
      toggleExportOrdersReadyStatus({
        stateKey: 'isExportOrdersReady',
        status: isReady,
      })
      snackbar.info(locales['app.tips.csv.export'])
      onClose()
    } catch {
      // will snack at base service
    }

    this.setState({
      isSendingExport: false,
    })
  }

  handleToggleAllSalesRep = () => {
    const {
      isCheckedAllSalesRep,
      salesReps,
    } = this.state

    const toggledCheck = !isCheckedAllSalesRep

    this.setState({
      isCheckedAllSalesRep: toggledCheck,
    }, () => {
      if (!toggledCheck && !salesReps.length) this.getSalesReps()
    })
  }

  toggleSalesRepIds = (id) => () => {
    const {
      salesRepIds,
    } = this.state

    const index = salesRepIds.indexOf(id)

    this.setState({
      salesRepIds: index === -1 ? [...salesRepIds, id] : [...salesRepIds.filter((salesRepId) => salesRepId !== id)],
    })
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getSalesReps)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getSalesReps)
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
  }

  render() {
    const {
      classes,
      orderStatus,
      onClose,
    } = this.props

    const {
      exportDateRange,
      q,
      isCheckedAllSalesRep,
      salesRepIds,
      salesReps,
      pagination,
      isSalesRepLoading,
      isSendingExport,
    } = this.state

    return ReactDOM.createPortal(
      <Fade in>
        <div
          className={classes.root}
        >
          <Typography className={classes.title} variant="h6">Select Company Orders to Export</Typography>
          <div className={classes.content}>
            <div className={classes.filedContainer}>
              <div className={classes.filedLabel}>
                <Typography variant="subtitle1">Order Type: </Typography>
              </div>
              <Typography variant="subtitle1">Company Orders</Typography>
              <Tooltip
                title="Company Level Orders placed by BundleB2B Companies(Corporate Accounts)"
                placement="top-end"
              >
                <Box ml={1}>
                  <IconButton color="primary" size="small">
                    <HelpIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </div>
            <Divider />
            <div className={classes.filedContainer}>
              <div className={classes.filedLabel}>
                <Typography variant="subtitle1">Order Status: </Typography>
              </div>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.isCheckedAllExportStatus}
                    onChange={this.handleToggleAllExportStatus}
                  />
                )}
                label="All Order Status"
              />
            </div>
            <Divider />
            <Grid container>
              {
                orderStatus.length && orderStatus.map((status) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={status.statusCode}
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={this.getExportStatusChecked(status.statusCode)}
                          onChange={this.handleToggleExportStatusChecked(status.statusCode)}
                        />
                      )}
                      label={status.customLabel}
                    />
                  </Grid>
                ))
              }
            </Grid>
            <Divider />
            <Box mt={2}>
              <div className={classes.filedContainer}>
                <div className={classes.filedLabel}>
                  <Typography variant="subtitle1">Date Range: </Typography>
                </div>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DateRangePicker
                    startText="From"
                    endText="To"
                    displayStaticWrapperAs="desktop"
                    value={exportDateRange}
                    maxDate={new Date()}
                    onChange={this.handleExportDateRange}
                    allowSameDateSelection
                    renderInput={(startProps, endProps) => {
                      const resetProps = (props) => {
                        const {
                          inputProps,
                          inputProps: {
                            value,
                          },
                        } = props

                        return {
                          ...props,
                          helperText: '',
                          inputProps: {
                            ...inputProps,
                            value: b3DateFormat.displayFormat(value, true),
                          },
                        }
                      }

                      startProps = resetProps(startProps)
                      endProps = resetProps(endProps)

                      return (
                        <>
                          <TextField {...startProps} size="small" />
                          <DateRangeDelimiter> to </DateRangeDelimiter>
                          <TextField {...endProps} size="small" />
                        </>
                      )
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box mt={2}>
              <div className={classes.filedContainer}>
                <div className={classes.filedLabel}>
                  <Typography variant="subtitle1">Sales Rep: </Typography>
                </div>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={isCheckedAllSalesRep}
                      onChange={this.handleToggleAllSalesRep}
                      disabled={!checkPermissions(['13101'])}
                      size="small"
                    />
                  )}
                  label="CheckAll"
                />
                <Tooltip
                  title="Filter the export by sales rep. Data includes company level orders placed by all companies under this sales rep."
                  placement="top-end"
                >
                  <IconButton color="primary" size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
            {
              !isCheckedAllSalesRep && (
                <>
                  <Grid
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                    >
                      <Box pr={2}>
                        <B3Search
                          value={q}
                          onChange={this.handleSearchChange}
                          onSearch={this.handleSearch}
                          placeholder="search sales reps"
                          disabled={isCheckedAllSalesRep}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <B3Spin isSpinning={isSalesRepLoading}>
                    <Grid
                      container
                    >
                      {
                        salesReps.map((salesRep) => (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            key={salesRep.salesRepId}
                          >
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={salesRepIds.includes(salesRep.salesRepId)}
                                  onChange={this.toggleSalesRepIds(salesRep.salesRepId)}
                                />
                              )}
                              label={`${salesRep.firstName} ${salesRep.lastName} (${salesRep.email})`}
                            />
                          </Grid>
                        ))
                      }
                      <Grid
                        item
                        xs={12}
                      >
                        <TablePagination
                          align="left"
                          rowsPerPageOptions={[10, 20, 30]}
                          component="div"
                          count={pagination.totalCount}
                          rowsPerPage={pagination.limit}
                          page={this.currentPage}
                          onChangePage={this.handlePageChange}
                          onChangeRowsPerPage={this.handleRowsPerPageChange}
                        />
                      </Grid>
                    </Grid>
                  </B3Spin>
                </>
              )
            }
          </div>
          <div className={classes.actions}>
            <Button disabled={isSendingExport} onClick={onClose}>Cancel</Button>
            <Box ml={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleExportOrders}
                disabled={this.isExportDisabled}
              >
                Export
              </Button>
            </Box>
          </div>
        </div>
      </Fade>,
      document.body,
    )
  }
}

const orderExportStyles = {
  root: {
    position: 'fixed',
    zIndex: 1300,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  title: {
    padding: '16px 24px',
    flex: '0 0 auto',
  },
  content: {
    flex: '1 1 auto',
    padding: '8px 24px',
  },
  actions: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filedLabel: {
    marginRight: 16,
  },
}

const mapStateToProps = (state) => {
  const {
    orders: {
      isExportOrdersReady,
    },
  } = state

  return {
    isExportOrdersReady,
  }
}

export default connect(mapStateToProps, { toggleExportOrdersReadyStatus })(withStyles(orderExportStyles)(OrdersExport))
