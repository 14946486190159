import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import uuid from 'uuid/v1'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  styled,
  Grid,
  Typography,
  IconButton,
  ButtonGroup,
  Button,
} from '@material-ui/core'

import {
  HighlightOffOutlined as RemoveIcon,
} from '@material-ui/icons'

import b3request from '../../service'
import { snackbar } from '../../utils'
import locales from '../../locales/en-US'
import { toggleQtyExportReadyStatus } from '../../store/actions/qty'

const DropzoneWrapper = styled(Grid)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(),
  '& > [class^=drop-wrapper]': {
    backgroundColor: props.theme.palette.grey[100],
    height: 120,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color .6s',
    padding: props.theme.spacing(2),
    textAlign: 'center',

    '&.active, &:hover': {
      backgroundColor: props.theme.palette.grey[300],
      cursor: 'pointer',
      transition: 'background-color .6s',
      border: `1px dashed ${props.theme.palette.grey[400]}`,
    },
  },
}))

class Dropfile extends Component {
  static propTypes = {
    toggleLoading: PropTypes.func.isRequired,
    isQtyReady: PropTypes.oneOf(['0', '1']).isRequired,
    toggleQtyExportReadyStatus: PropTypes.func.isRequired,
  }

  mimeTypes = [
    'application/xls',
    'application/excel',
    'application/vnd.ms-excel',
    'application/msexcel',
    'application/x-excel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/x-dos_ms_excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ]

  validExtName = ['.xls', '.xlsx']

  constructor() {
    super()
    this.state = {
      files: [],
    }
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get hasFiles() {
    const { files } = this.state
    return files.length > 0
  }

  get fileLengthError() {
    const { files } = this.state
    if (files.length > 1) {
      return locales['app.tips.qty.tooManyFileError']
    }
    return ''
  }

  get hasError() {
    const { files } = this.state
    return !!this.fileLengthError || files.filter((file) => !this.isValidFileType(file.blob)).length > 0
  }

  isValidFileType = (blob) => {
    if (!blob.type) {
      return this.validExtName.includes(blob.name.substr(blob.name.lastIndexOf('.')))
    }
    return this.mimeTypes.includes(blob.type)
  }

  handleFileRemove = (id) => () => {
    const { files } = this.state
    this.setState({
      files: files.filter((file) => file.id !== id),
    })
  }

  handleFilesClear = () => {
    this.setState({
      files: [],
    })
  }

  handleImport = async () => {
    const { toggleLoading, toggleQtyExportReadyStatus } = this.props
    const { files } = this.state
    if (files[0]) {
      toggleLoading(true)
      const data = new FormData()
      data.append('file', files[0].blob)
      try {
        const resp = await b3request.qty.uploadQtyExcel(data)
        toggleQtyExportReadyStatus(resp.isReady)
        snackbar.success(locales['app.tips.qty.uploadFileSuccess'])
        this.setState({
          files: [],
        })
      } catch (error) {
        snackbar.error(locales['app.tips.qty.uploadFileError'])
        this.setState({
          files: [],
        })
      }
      toggleLoading(false)
    }
  }

  onDrop = (files) => {
    this.setState({
      files: files.map((blob) => ({
        id: uuid(),
        blob,
      })),
    })
  }

  displayFiles = () => {
    const { files } = this.state
    return files.map((file) => {
      const isValid = this.isValidFileType(file.blob)
      return (
        <Typography
          color="textSecondary"
          key={file.id}
          component="div"
          paragraph
        >
          <Typography>
            {file.blob.name}
            <IconButton
              onClick={this.handleFileRemove(file.id)}
              size="small"
            >
              <RemoveIcon />
            </IconButton>
          </Typography>
          { !isValid && <Typography color="error">File type error, Please upload an excel(.xls) file.</Typography> }
        </Typography>
      )
    })
  }

  render() {
    const { isQtyReady } = this.props
    return (
      <>
        <Dropzone
          onDrop={this.onDrop}
          disabled={isQtyReady === '0'}
        >
          {({ getInputProps, getRootProps, isDragActive }) => (
            <DropzoneWrapper
              item
              xs={6}
            >
              <div {...getRootProps({
                className: clsx({
                  'drop-wrapper': true,
                  active: isDragActive,
                  disabled: isQtyReady === '0',
                }),
              })}
              >
                <input {...getInputProps()} />
                {
                  isQtyReady === '0' && <Typography color="secondary">We are processing your file, please check the dashboard later for the File Upload Summary.</Typography>
                }
                {
                  isQtyReady === '1' && (isDragActive
                    ? <Typography>Please drop the file.</Typography>
                    : <Typography>Drag or click to select an excel(.xls) file here</Typography>
                  )
                }
              </div>
            </DropzoneWrapper>
          )}
        </Dropzone>
        {
          this.fileLengthError && <Typography color="error" paragraph>{this.fileLengthError}</Typography>
        }
        {
          this.displayFiles()
        }
        <ButtonGroup disabled={isQtyReady === '0'}>
          {
            this.fileLengthError && (
              <Button
                onClick={this.handleFilesClear}
                color="secondary"
              >
                Clear files
              </Button>
            )
          }
          <Button
            onClick={this.handleImport}
            color="primary"
            variant="contained"
            disabled={this.hasError || !this.hasFiles}
          >
            import
          </Button>
        </ButtonGroup>
      </>
    )
  }
}

const mapStateToProp = (state) => ({
  isQtyReady: state.qty.isQtyReady,
})

export default connect(mapStateToProp, { toggleQtyExportReadyStatus })(Dropfile)
