const baseAssignments = [
  {
    status: '0',
    label: 'all',
  },
  {
    status: '1',
    label: 'Assigned',
  },
  {
    status: '2',
    label: 'Unassigned',
  },
]

export default () => (baseAssignments)
