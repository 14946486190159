import axios from 'axios'
import { snackbar, env } from '../utils'
import pureApiList from './pureApiList'

const b3request = axios.create({
  baseURL: env.baseUrl,
})

b3request.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    authToken: window.localStorage.getItem('authToken'),
  }
  if (config.params && config.params.orderBy) {
    config.params = {
      ...config.params,
      orderBy: config.params.orderBy.toUpperCase(),
    }
  }
  return config
})

b3request.interceptors.response.use((resp) => {
  const {
    data: {
      code,
      message,
      data,
    },
    config: {
      url,
    },
  } = resp

  const isShowError = !Object.values(pureApiList).includes(url)

  let result = data

  switch (+code) {
    case 200:
      break
    case 401:
    case 403:
      window.location.hash = '#/login'
      if (isShowError) snackbar.error(message)
      result = Promise.reject(result)
      break
    default:
      if (isShowError) snackbar.error(message)
      result = Promise.reject(result)
      break
  }
  return result
})

export default b3request
