import { forwardRef } from 'react'
import { checkPermissions } from '../../utils'

export default forwardRef((props, ref) => {
  const {
    permissions = [],
    children,
    no = null,
    ...extra
  } = props

  const isAllowed = checkPermissions(permissions)

  const C = typeof children === 'function' ? children({
    ref,
    ...extra,
  }) : children

  return isAllowed ? C : no
})
