import * as dashboard from './dashboard'
import * as catalogs from './catalogs'
import * as companies from './companies'
import * as salesReps from './salesReps'
import * as users from './users'
import * as qty from './qty'
import * as storeConfig from './storeConfig'
import * as quotes from './quotes'
import * as orders from './orders'
import * as integration from './integration'
import * as roles from './roles'

export default {
  dashboard,
  catalogs,
  companies,
  salesReps,
  users,
  qty,
  storeConfig,
  quotes,
  orders,
  integration,
  roles,
}
