import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TablePagination,
  List,
  ListItem,
  ListItemText,
  styled,
} from '@material-ui/core'

import {
  Check as CheckIcon,
} from '@material-ui/icons'

import {
  B3Search,
  B3Spin,
} from '../../components'

import b3request from '../../service'

const TitleWrapper = styled('div')({
  marginBottom: 8,
})

const CatalogListItemIcon = styled('span')({
  minWidth: 24,
  textAlign: 'right',
})

export default class B3CatalogPicker extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    selectedCustomerGroupId: PropTypes.string,
    onHide: PropTypes.func,
    onConfirm: PropTypes.func,
    onSelectedCustomerGroupChange: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    selectedCustomerGroupId: '',
    onHide: () => {},
    onConfirm: () => {},
    onSelectedCustomerGroupChange: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      customerGroups: [],
      q: '',
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
    }
  }

  componentDidMount() {
    this.getCustomerGroups()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  getCustomerGroups = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
      q,
    } = this.state
    try {
      const resp = await b3request.companies.getCustomerGoups({
        offset,
        limit,
        q,
      })
      this.setState({
        customerGroups: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getCustomerGroups)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getCustomerGroups)
  }

  handleCustomerGroupItemClick = (selectedCustomerGroup) => () => {
    const {
      onSelectedCustomerGroupChange,
    } = this.props
    onSelectedCustomerGroupChange(selectedCustomerGroup)
  }

  handleQChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getCustomerGroups)
  }

  handleClose = () => {
    const {
      onHide,
    } = this.props
    onHide()
  }

  render() {
    const {
      isOpen,
      selectedCustomerGroupId,
      onConfirm,
    } = this.props
    const {
      customerGroups,
      isLoading,
      pagination,
      q,
    } = this.state
    return (
      <Dialog open={isOpen} onClose={this.handleClose}>
        <DialogTitle>
          <TitleWrapper>Select a customer group</TitleWrapper>
          <B3Search
            value={q}
            onChange={this.handleQChange}
            onSearch={this.handleSearch}
            placeholder="search customer group name…"
            disabled={isLoading}
          />
        </DialogTitle>

        <DialogContent>
          <B3Spin
            isSpinning={isLoading}
            tip="Loading…"
          >
            <List>
              {
                customerGroups.map((customerGroup) => (
                  <ListItem
                    key={customerGroup.id}
                    selected={selectedCustomerGroupId === customerGroup.id}
                    button
                    onClick={this.handleCustomerGroupItemClick(customerGroup)}
                  >
                    <ListItemText>{customerGroup.name}</ListItemText>
                    <CatalogListItemIcon>
                      {
                        selectedCustomerGroupId === customerGroup.id ? <CheckIcon /> : null
                      }
                    </CatalogListItemIcon>
                  </ListItem>
                ))
              }
            </List>
          </B3Spin>
        </DialogContent>
        <DialogActions>
          <TablePagination
            count={pagination.totalCount}
            rowsPerPage={pagination.limit}
            page={this.currentPage}
            component="div"
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
          />
          <Button
            onClick={this.handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
