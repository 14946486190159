import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { upperFirst } from 'lodash'
import uuid from 'uuid'

import {
  Grid,
  Typography,
  makeStyles,
  Box,
  styled,
  Table,
  Divider,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
} from '@material-ui/core'

import { Image as ImageIcon } from '@material-ui/icons'

import {
  B3Card,
  B3Back,
  B3Tag,
  B3TableCell as TableCell,
  NoData,
} from '../../components'

import {
  snackbar,
  getQuotesStatusByCode,
  currencyFormat,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

import b3request from '../../service'

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  titleItem: {
    marginRight: theme.spacing(),
  },
}))

const Titlebar = (props) => {
  const classes = useStyles()
  const { title, status } = props
  return (
    <div className={classes.title}>
      <B3Back className={classes.titleItem} title={title} />
      {status && (
        <B3Tag color={getQuotesStatusByCode(status).color}>
          {getQuotesStatusByCode(status).text}
        </B3Tag>
      )}
    </div>
  )
}
Titlebar.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
}
Titlebar.defaultProps = {
  status: '',
}

const InfoTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(),
  boxSizing: 'border-box',
}))

const InfoCard = (props) => {
  const {
    title,
    textAlign,
    xs,
    mt,
    infos,
  } = props
  return (
    <Grid item xs={xs}>
      <InfoTitle>
        <Box textAlign={textAlign} mt={mt}>
          <Typography display="block" variant="h6" paragraph>
            {title}
          </Typography>
          {infos.map((info) => (
            <Typography key={uuid()} display="block" variant="body2" gutterBottom>
              {info}
            </Typography>
          ))}
        </Box>
      </InfoTitle>
    </Grid>
  )
}
InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  xs: PropTypes.number,
  mt: PropTypes.number,
  infos: PropTypes.arrayOf(PropTypes.string),
}
InfoCard.defaultProps = {
  textAlign: 'left',
  xs: 6,
  mt: 0,
  infos: [],
}

const StyledProductWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .imageBox': {
    width: '20%',
    height: 0,
    boxSizing: 'border-box',
    paddingTop: '20%',
    position: 'relative',
    overflow: 'hidden',
    '& .img': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '8em',
    },
  },
  '& .content': {
    flex: 1,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(),
  },
}))

const StyledTableTh = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}))

const StyledSubtotal = styled(Box)(() => ({
  '& .subWrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .flex-1': {
    flex: 1,
  },
  '& .price-lineHeight': {
    lineHeight: '48px',
  },
  '& .subWrapper .item': {
    width: '20%',
    display: 'flex',
    cursor: 'context-menu',
    borderBottom: '1px solid #eee',
  },
  '& .subWrapper:last-child .item': {
    border: 'none',
  },
}))

class Quote extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      ellipsis: PropTypes.string.isRequired,
      additionalInfo: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      storeInfo: {
        storeName: '',
        storeAddress: '',
        storeCountry: '',
      },
      companyInfo: {
        companyName: '',
        companyAddress: '',
        companyCountry: '',
        companyCountryCode: '',
        companyState: '',
        companyStateCode: '',
        companyCity: '',
        companyZipCode: '',
      },
      quoteInfo: {
        quoteId: '',
        referenceNumber: '',
        createdAt: '',
        expired: '',
        subtotal: '',
        discount: '',
        grandTotal: '',
        status: '',
      },
      salesRepInfo: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
    }
  }

  componentDidMount() {
    this.getQuote()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  getQuote = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      match: {
        params: { id },
      },
    } = this.props
    try {
      const resp = await b3request.quotes.getQuoteById(id)
      const {
        companyInfo,
        storeInfo,
        quoteInfo,
        salesRepInfo,
      } = resp

      this.setState({
        storeInfo,
        companyInfo,
        quoteInfo,
        salesRepInfo,
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  haddleImageError = (productId) => () => {
    const {
      quoteInfo,
      quoteInfo: { productList },
    } = this.state
    const products = productList.map((item) => {
      if (item.productId === productId) item.imageUrl = ''
      return item
    })
    this.setState({
      quoteInfo: {
        ...quoteInfo,
        productList: [...products],
      },
    })
  }

  render() {
    const {
      isLoading,
      companyInfo,
      storeInfo,
      quoteInfo,
      salesRepInfo,
      quoteInfo: { productList = [] },
    } = this.state
    const {
      classes,
    } = this.props

    const convertedStoreInfoToInfoCardProps = {
      title: storeInfo.storeName,
      infos: [storeInfo.storeAddress, storeInfo.storeCountry],
    }
    const convertedQuoteInfoToInfoCardProps = {
      textAlign: 'right',
      title: `Reference Number: ${quoteInfo.referenceNumber}`,
      infos: [
        `Issued on: ${b3DateFormat.displayFormat(quoteInfo.createdAt)}`,
        `Expiration Date: ${b3DateFormat.displayFormat(quoteInfo.expiredAt)}`,
      ],
    }
    const convertedCompanyInfoToInfoCardProps = {
      mt: 2,
      title: companyInfo.companyName,
      infos: [
        `${companyInfo.label || ''}`,
        companyInfo.companyAddress,
        companyInfo.storeCountry,
        `${companyInfo.firstName || ''} ${companyInfo.lastName || ''}`,
        companyInfo.phoneNumber,
        `${companyInfo.companyCity}, ${companyInfo.companyStateCode
        || companyInfo.companyState} ${companyInfo.companyZipCode}`,
        companyInfo.companyCountry,
      ],
    }
    const convertedSalesRepInfoToInfoCardProps = {
      mt: 2,
      textAlign: 'right',
      title: 'Sales Representative:',
      infos: [
        `${salesRepInfo.firstName} ${salesRepInfo.lastName}`,
        `${salesRepInfo.phoneNumber}`,
        `${salesRepInfo.email}`,
      ],
    }

    return (
      <B3Card
        isLoading={isLoading}
        title={<Titlebar title={quoteInfo.referenceNumber} status={quoteInfo.status} />}
      >
        <Grid container>
          <InfoCard {...convertedStoreInfoToInfoCardProps} />
          <InfoCard {...convertedQuoteInfoToInfoCardProps} />
          <InfoCard {...convertedCompanyInfoToInfoCardProps} />
          <InfoCard {...convertedSalesRepInfoToInfoCardProps} />
        </Grid>
        <Box component="div" mt={5}>
          {productList.length === 0 ? (
            <NoData />
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableTh style={{ width: '40%' }}>
                    Product
                  </StyledTableTh>
                  <StyledTableTh>Price</StyledTableTh>
                  <StyledTableTh>Discount</StyledTableTh>
                  <StyledTableTh>New Price</StyledTableTh>
                  <StyledTableTh>Quantity</StyledTableTh>
                  <StyledTableTh>Line Total</StyledTableTh>
                </TableRow>
              </TableHead>

              <TableBody>
                {productList.map((product) => (
                  <TableRow key={product.productId}>
                    <TableCell>
                      <StyledProductWrapper>
                        <div className="imageBox">
                          {product.imageUrl ? (
                            <img
                              className="img"
                              src={product.imageUrl}
                              onError={this.haddleImageError(product.productId)}
                              alt={product.productName}
                            />
                          ) : (
                            <ImageIcon className="img" color="primary" />
                          )}
                        </div>
                        <div className="content">
                          <Typography variant="subtitle1" paragraph>
                            {product.productName}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            SKU:
                            {' '}
                            {product.sku}
                          </Typography>
                          {product.options
                              && Object.entries(product.options).map(([key, v]) => (
                                <Typography
                                  key={key}
                                  variant="body2"
                                  gutterBottom
                                >
                                  {upperFirst(key)}
                                  :
                                  {upperFirst(v)}
                                </Typography>
                              ))}
                        </div>
                      </StyledProductWrapper>
                    </TableCell>
                    <TableCell>
                      {currencyFormat(product.basePrice, quoteInfo.money)}
                    </TableCell>
                    <TableCell>
                      {product.discount}
                      %
                    </TableCell>
                    <TableCell>
                      {currencyFormat(product.newPrice, quoteInfo.money)}
                    </TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>
                      {currencyFormat(parseFloat(product.newPrice * product.quantity), quoteInfo.money)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
        <StyledSubtotal mt={4}>
          <Box className="subWrapper">
            <div className="flex-1" />
            <div className="item">
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                Subtotal
              </Typography>
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                {currencyFormat(quoteInfo.subtotal, quoteInfo.money)}
              </Typography>
            </div>
          </Box>
          <Box className="subWrapper">
            <div className="flex-1" />
            <div className="item">
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                Discount
              </Typography>
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                {currencyFormat(quoteInfo.discount, quoteInfo.money)}
              </Typography>
            </div>
          </Box>
          <Box className="subWrapper">
            <div className="flex-1" />
            <div className="item">
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                Grand Total
              </Typography>
              <Typography
                className={clsx(['flex-1', 'price-lineHeight'])}
                variant="body1"
              >
                {currencyFormat(quoteInfo.grandTotal, quoteInfo.money)}
              </Typography>
            </div>
          </Box>
        </StyledSubtotal>
        {
          quoteInfo.additionalInfo && (
            <>
              <Divider />
              <Box component="div" mt={2}>
                <Typography variant="h6" paragraph>NOTES</Typography>
                <Typography
                  className={classes.additionalInfo}
                  variant="body2"
                >
                  {quoteInfo.additionalInfo}
                </Typography>
              </Box>
            </>
          )
        }
      </B3Card>
    )
  }
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: 'calc(100% - 40px)',
  },
  additionalInfo: {
    textIndent: '2em',
    marginTop: '1em',
  },
}

export default withStyles(styles)(Quote)
