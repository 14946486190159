import {
  TOGGLE_QTY_EXPORT_READY_STATUS,
} from '../actionTypes'

const initState = {
  isQtyReady: '0',
}

export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_QTY_EXPORT_READY_STATUS:
      return {
        ...state,
        isQtyReady: action.payload.status,
      }
    default:
      return state
  }
}
