import {
  TOGGLE_EXPORT_ORDERS_READY_STATUS,
} from '../actionTypes'

const initState = {
  isExportOrdersReady: '1',
}

export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_EXPORT_ORDERS_READY_STATUS:
      return {
        ...state,
        [action.payload.stateKey]: action.payload.status,
      }
    default:
      return state
  }
}
