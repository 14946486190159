import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TablePagination,
  List,
  ListItem,
  ListItemText,
  styled,
} from '@material-ui/core'

import {
  Check as CheckIcon,
} from '@material-ui/icons'

import {
  B3Search,
  B3Spin,
} from '..'

import b3request from '../../service'

import { snackbar } from '../../utils'

import locales from '../../locales/en-US'

const TitleWrapper = styled('div')({
  marginBottom: 8,
})

const CatalogListItemIcon = styled('span')({
  minWidth: 24,
  textAlign: 'right',
})

export default class B3CatalogPicker extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    selectedCatalogId: PropTypes.string,
    companyId: PropTypes.string,
    onHide: PropTypes.func,
    onSelectedCatalogIdChange: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    selectedCatalogId: '',
    companyId: '',
    onHide: () => {},
    onSelectedCatalogIdChange: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      catalogs: [],
      q: '',
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
    }
  }

  componentDidMount() {
    this.getCatalogs()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  getCatalogs = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
      q,
    } = this.state
    try {
      const resp = await b3request.catalogs.getCatalogs({
        offset,
        limit,
        q,
      })
      this.setState({
        catalogs: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getCatalogs)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getCatalogs)
  }

  handleCatalogItemClick = (selectedCatalogId) => () => {
    const {
      onSelectedCatalogIdChange,
    } = this.props
    onSelectedCatalogIdChange(selectedCatalogId)
  }

  handleQChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getCatalogs)
  }

  handleClose = () => {
    const {
      onHide,
    } = this.props
    onHide()
  }

  handleSave = async (catalogId) => {
    const {
      onHide,
      selectedCatalogId,
      companyId,
    } = this.props
    this.setState({
      isLoading: true,
    })

    catalogId = catalogId !== undefined ? catalogId : selectedCatalogId

    try {
      await b3request.companies.updateCompanyBasicInfoByCompanyId(companyId, {
        catalogId,
      })
      snackbar.success(locales['app.tips.company.updatedPriceListSuccessfully'])
      onHide(true)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const {
      isOpen,
      selectedCatalogId,
    } = this.props
    const {
      catalogs,
      isLoading,
      pagination,
      q,
    } = this.state
    return (
      <Dialog open={isOpen} onClose={this.handleClose}>
        <DialogTitle>
          <TitleWrapper>Select a price list</TitleWrapper>
          <B3Search
            value={q}
            onChange={this.handleQChange}
            onSearch={this.handleSearch}
            placeholder="search price list name…"
            disabled={isLoading}
          />
        </DialogTitle>

        <DialogContent>
          <B3Spin
            isSpinning={isLoading}
            tip="Loading…"
          >
            <List>
              {
                catalogs.map((catalog) => (
                  <ListItem
                    key={catalog.catalogId}
                    selected={selectedCatalogId === catalog.catalogId}
                    button
                    onClick={this.handleCatalogItemClick(catalog.catalogId)}
                  >
                    <ListItemText>{catalog.catalogName}</ListItemText>
                    <CatalogListItemIcon>
                      {
                        selectedCatalogId === catalog.catalogId && (<CheckIcon />)
                      }
                    </CatalogListItemIcon>
                  </ListItem>
                ))
              }
            </List>
          </B3Spin>
        </DialogContent>
        <DialogActions>
          <TablePagination
            count={pagination.totalCount}
            rowsPerPage={pagination.limit}
            page={this.currentPage}
            component="div"
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
          />
          <Button
            onClick={this.handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.handleSave()}
            color="primary"
            variant="contained"
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
