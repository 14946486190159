import menu from './menu'
import tips from './tips'
import validationErrors from './validationErrors'
import acumatica from './acumatica'

export default {
  ...menu,
  ...tips,
  ...validationErrors,
  ...acumatica,
}
