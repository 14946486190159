import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiTableCell: {
      root: {
        wordWrap: 'break-word',
        padding: 16,
      },
      head: {
        padding: 16,
        whiteSpace: 'nowrap',
      },
    },
    MuiChip: {
      root: {
        maxWidth: '100%',
      },
      label: {
        width: '100%',
        overflow: 'hidden',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiTab: {
      root: {
        [breakpoints.up('sm')]: {
          minWidth: 'auto',
        },
      },
    },
  },
})

export default theme
