import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { noop } from 'lodash'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  LiveHelpOutlined as HelpIcon,
  ExitToApp as LoginOutIcon,
} from '@material-ui/icons'

import b3request from '../../service'
import locales from '../../locales/en-US'
import { b3storage, snackbar } from '../../utils'

import { Logo } from '..'

const useStyles = makeStyles((theme) => ({
  appBar: {
    flexDirection: 'row',
  },
  brand: {
    backgroundColor: theme.palette.primary.dark,
    padding: `0 ${theme.spacing()}px 0  ${theme.spacing(2)}px`,
    width: 241,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
  },
  mainHeader: {
    flex: 1,
    display: 'flex',
  },
  headerTitle: {
    flex: 1,
  },
}))

const Header = (props) => {
  const {
    toggleNavCollapseGlobal,
    pageTitle,
    history,
  } = props

  const toBundleB2B = () => {
    window.open('https://www.bundleb2b.com/', '_blank')
  }

  const logOut = async () => {
    await b3request.users.logOut({
      authToken: b3storage.authToken.val,
    })
    const removeKeys = ['authToken', 'permissionModules']
    removeKeys.forEach((key) => {
      b3storage[key].remove()
    })
    snackbar.success(locales['app.tips.users.logOut.success'])
    history.push('/login')
  }

  const classes = useStyles()

  return (
    <AppBar
      className={classes.appBar}
      position="relative"
    >
      <Paper
        className={classes.brand}
        elevation={0}
        square
      >
        <Logo />
        <IconButton
          color="inherit"
          onClick={toggleNavCollapseGlobal}
        >
          <MenuIcon
            fontSize="small"
          />
        </IconButton>
      </Paper>
      <Toolbar className={classes.mainHeader}>
        <div className={classes.headerTitle}>
          <Typography variant="h6">{pageTitle}</Typography>
        </div>
        <Tooltip title="Help">
          <IconButton
            color="inherit"
            onClick={toBundleB2B}
          >
            <HelpIcon
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Log Out">
          <IconButton
            color="inherit"
            onClick={logOut}
          >
            <LoginOutIcon
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  toggleNavCollapseGlobal: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

Header.defaultProps = {
  pageTitle: '',
  toggleNavCollapseGlobal: noop,
}

export default withRouter(Header)
