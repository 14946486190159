import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { Grid, TextField } from '@material-ui/core'

import { B3Select } from '..'

const B3CountryGroup = (props) => {
  const {
    field,
    states,
    onChange,
    value,
    disabled,
    countries,
    required,
    onOpen,
    error,
    helperText = '',
  } = props

  if (field.name === 'country') {
    return (
      <Grid item xs={field.xs}>
        <B3Select
          options={countries.map((country) => country.countryName)}
          value={countries.length ? value : ''}
          label={field.label}
          disabled={disabled}
          onChange={onChange}
          required={required}
          onOpen={() => onOpen(field)}
          error={error}
          helperText={helperText}
        />
      </Grid>
    )
  }
  return states.length ? (
    <Grid item xs={field.xs}>
      <B3Select
        options={states.map((state) => state.stateName)}
        value={value}
        label={field.label}
        disabled={disabled}
        onChange={onChange}
        required={required}
        onOpen={() => onOpen(field)}
        error={error}
        helperText={helperText}
      />
    </Grid>
  ) : (
    <Grid item xs={field.xs}>
      <TextField {...props} />
    </Grid>
  )
}

B3CountryGroup.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    xs: PropTypes.number,
  }).isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      stateName: PropTypes.string,
    }),
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      countryName: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

B3CountryGroup.defaultProps = {
  states: [],
  countries: [],
  required: false,
  onOpen: noop,
  error: false,
  helperText: '',
}

export default B3CountryGroup
