export const GLOBAL_TOGGLE_NAV_COLLAPSE = 'GLOBAL_TOGGLE_NAV_COLLAPSE'
export const GLOBAL_SET_PAGE_TITLE = 'GLOBAL_SET_PAGE_TITLE'
export const GLOBAL_CREATE_SNACKBAR_MESSAGE = 'GLOBAL_CREATE_SNACKBAR_MESSAGE'
export const GLOBAL_REMOVE_SNACKBAR_MESSAGE = 'GLOBAL_REMOVE_SNACKBAR_MESSAGE'
export const TOGGLE_GLOBAL_LOADING = 'TOGGLE_GLOBAL_LOADING'

export const TOGGLE_QTY_EXPORT_READY_STATUS = 'TOGGLE_QTY_EXPORT_READY_STATUS'

export const SETTINGS_GET_STORE_CONFIG_SUCCESS = 'SETTINGS_GET_STORE_CONFIG_SUCCESS'
export const SETTINGS_TOGGLE_CONFIG_LOADING_STATUS = 'SETTINGS_TOGGLE_CONFIG_LOADING_STATUS'

export const TOGGLE_COMPANY_CSV_READY_STATUS = 'TOGGLE_COMPANY_CSV_READY_STATUS'
export const TOGGLE_SHOULD_COMPANY_LIST_UPDATE = 'TOGGLE_SHOULD_COMPANY_LIST_UPDATE'
export const SETTING_COMPANIES_EXTRAFIELDS = 'SETTING_COMPANIES_EXTRAFIELDS'

export const TOGGLE_SALES_REP_CSV_READY_STATUS = 'TOGGLE_SALES_REP_CSV_READY_STATUS'
export const TOGGLE_SHOULD_S_REP_TABLE_UPDATE = 'TOGGLE_SHOULD_S_REP_TABLE_UPDATE'

export const TOGGLE_EXPORT_ORDERS_READY_STATUS = 'TOGGLE_EXPORT_ORDERS_READY_STATUS'
