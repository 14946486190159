export default {
  'app.tips.company.updatedPriceListSuccessfully': 'Company price list updated successfully.',
  'app.tips.company.deleteCompanySuccessfully': 'Company deleted successfully.',
  'app.tips.company.updatedCompanyInfoSuccessfully': 'Company information saved successfully.',
  'app.tips.company.deletedCompanyUserSuccessfully': 'Company user deleted successfully.',

  'app.tips.qty.uploadFileSuccess': 'We are processing your file, please check the dashboard later',
  'app.tips.qty.uploadFileError': 'File upload error.',
  'app.tips.qty.tooManyFileError': 'Please upload one file at a time.',

  'app.tips.paymentMethods.updatePaymentMethodsSuccessfully': 'Visible payment methods updated successfully.',
  'app.tips.paymentMethods.updatePaymentMethodsFailed': 'Visible payment methods update has failed.',

  'app.tips.salesRep.updateSalesRepSuccessfully': 'Sales rep updated successfully.',
  'app.tips.salesRep.updateSalesRepFailed': 'Sales rep update has failed.',
  'app.tips.salesRep.createSalesRepSuccessfully': 'Sales rep created successfully.',
  'app.tips.salesRep.createSalesRepFailed': 'Sales rep creation has failed.',
  'app.tips.salesRep.deleteSalesSuccessfully': 'Sales rep deleted successfully.',
  'app.tips.salesRep.deleteSalesRepFailed': 'Sales rep deletion has failed.',
  'app.tips.salesRep.deleteConfirm': 'Are you sure you want to delete sales rep',

  'app.tips.settings.updateStoreConfigSuccessfully': 'Store settings updated successfully.',
  'app.tips.settings.updateStoreConfigFailed': 'Store settings update has failed.',

  'app.tips.company.import.uploadSuccess': 'We are processing your file, please check the dashboard later',

  'app.tip.uploadCsvPlaceholder': 'Click to "Browse File" or "Drag and Drop" to Upload',

  'app.tips.salesRep.import.uploadSuccess': 'We are processing your file, please check the dashboard later',
  'app.tips.users.logOut.success': 'Log out successfully.',
  'app.tips.users.signUp.success': 'Signed Up successfully.',
  'app.tips.users.signUp.failed': 'Sign Up has failed.',
  'app.tips.users.forgetPassword.sendEmail.success': 'Send Email successfully, please check.',

  'app.tips.users.updateUsersSuccessfully': 'User updated successfully.',
  'app.tips.users.updateUsersFailed': 'User update has failed.',
  'app.tips.users.createUsersSuccessfully': 'User created successfully.',
  'app.tips.users.createUsersFailed': 'User creation has failed.',
  'app.tips.users.deleteUsersSuccessfully': 'User deleted successfully.',
  'app.tips.users.deleteUsersFailed': 'User deletion has failed.',
  'app.tips.users.deleteConfirm': 'Are you sure you want to delete the user ?',
  'app.tips.users.transferOwner': 'Are you sure you want to transfer the owner ?',

  'app.tips.roles.updateRoleSuccessfully': 'Role updated successfully.',
  'app.tips.roles.updateRoleFailed': 'Role update has failed.',
  'app.tips.roles.createRoleSuccessfully': 'Role created successfully.',
  'app.tips.roles.createRoleFailed': 'Role creation has failed.',
  'app.tips.roles.deleteRoleSuccessfully': 'Role deleted successfully.',
  'app.tips.roles.deleteConfirm': 'Are you sure you want to delete the role ?',

  'app.tips.csv.export': 'Your CSV file is processing, please check later.',
  'app.tips.csv.export.failed': 'CSV export failed, please try again.',
  'app.tips.csv.export.company.ready': 'Your Companies CSV file is ready.',
  'app.tips.csv.export.sales.rep.ready': 'Your Sales Reps CSV file is ready.',
  'app.tips.csv.export.order.ready': 'Your Orders CSV file is ready.',
}
