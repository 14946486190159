import React, { Component, useState } from 'react'

import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import {
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  TablePagination,
  Menu,
  MenuList,
  MenuItem,
  Typography,
  Link,
  IconButton,
  Grid,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core'

import {
  withStyles,
  styled,
} from '@material-ui/styles'

import {
  Edit as EditIcon,
  MoreVert as MoreIcon,
  Publish as ImportIcon,
  GetApp as ExportIcon,
  Clear as ClearIcon,
} from '@material-ui/icons'

import {
  B3Card,
  B3Tag,
  B3CatalogPicker,
  B3TableCell as TableCell,
  NoData,
  B3Search,
  B3CsvUpload,
  B3Spin,
} from '../../components'

import AddNewCompany from './AddNewCompany'
import CustomerGroupPicker from './CustomerGroupPicker'
import { toggleCompanyCsvReadyStatus, toggleShouldCompanyListUpdate } from '../../store/actions/company'
import store from '../../store'

import b3request from '../../service'

import {
  getCompanyStatusByCode,
  snackbar,
  checkOneOfPermissions,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

import locales from '../../locales/en-US'

import DeleteCompanyConfirm from './DeleteCompanyConfirm'

const DropdownGroup = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { children, action } = props
  return (
    <div>
      {action(handleClick)}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children({ onClose: handleClose })}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

    </div>
  )
}

DropdownGroup.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
}

const ExtraContainer = styled('div')((props) => ({
  display: 'flex',
  '& .first': {
    marginRight: props.theme.spacing(),
  },
}))

class Companies extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      ellipsis: PropTypes.string.isRequired,
      editIconButton: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isExportCompanyCsvReady: PropTypes.oneOf(['0', '1']).isRequired,
    isImportCompaniesCsvReady: PropTypes.oneOf(['0', '1']).isRequired,
    isImportCompanyAddressesCsvReady: PropTypes.oneOf(['0', '1']).isRequired,
    shouldCompanyListUpdate: PropTypes.bool.isRequired,
    toggleCompanyCsvReadyStatus: PropTypes.func.isRequired,
    toggleShouldCompanyListUpdate: PropTypes.func.isRequired,
    companiesTemplateUrl: PropTypes.string.isRequired,
    addressesTemplateUrl: PropTypes.string.isRequired,
  }

  constructor() {
    super()
    this.state = {
      companies: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      sortBy: 'updatedAt',
      orderBy: 'desc',
      isLoading: false,
      hoverRowId: '',
      q: '',
      currentCompanyCatalogId: '',
      currentCompanyId: '',
      isCatalogPickerOpen: false,
      isAddingCompany: false,
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      actionCompanyName: '',
      isDeleteCompanyConfirmOpen: false,
      isCustomerGroupPickerOpen: false,
      currentCustomerGroup: {
        id: '',
        name: '',
      },
      isCompanyImportOpen: false,
      companyUploadTitle: '',
      companyUploadSubtitle: '',
      companyImportUrl: '',
      isCheckingCompanyImport: false,
      companyImportType: '',
      isCompanyImportUploading: false,
      companyImportFiles: [],
    }
  }

  componentDidMount() {
    this.getCompanies()
    this.unsubscribe = store.subscribe(() => {
      const { shouldCompanyListUpdate } = this.props
      if (shouldCompanyListUpdate) this.getCompanies()
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  handleRowHover = (hoverRowId) => () => {
    this.setState({
      hoverRowId,
    })
  }

  handleRowLeave = () => {
    this.setState({
      hoverRowId: '',
    })
  }

  handleCatalogPickerHide = (reloadList = false) => {
    const {
      pagination,
    } = this.state
    this.setState({
      isCatalogPickerOpen: false,
    })
    if (reloadList) {
      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getCompanies)
    }
  }

  handleCurrentCompayCatalogIdChange = (currentCompanyCatalogId) => {
    this.setState({
      currentCompanyCatalogId,
    })
  }

  handleEditIconClick = (company) => () => {
    this.setState({
      isCatalogPickerOpen: true,
      currentCompanyCatalogId: company.catalogId,
      currentCompanyId: company.companyId,
    })
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getCompanies)
  }

  getCompanies = async () => {
    const { toggleShouldCompanyListUpdate } = this.props
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
      sortBy,
      orderBy,
      q,
    } = this.state
    try {
      const resp = await b3request.companies.getCompanies({
        offset,
        limit,
        q,
        sortBy,
        orderBy,
      })

      this.setState({
        companies: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
      toggleShouldCompanyListUpdate(false)
    }
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getCompanies)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getCompanies)
  }

  handleSort = (sortFiled) => () => {
    const {
      orderBy,
      sortBy,
    } = this.state
    if (sortBy === sortFiled) {
      this.setState({
        orderBy: orderBy === 'desc' ? 'asc' : 'desc',
      }, this.getCompanies)
    } else {
      this.setState({
        sortBy: sortFiled,
      }, this.getCompanies)
    }
  }

  handleAddNewCompanyClick = () => {
    this.setState({
      isAddingCompany: true,
    })
    this.onAddNewCompanyDropdownClose()
  }

  handleAddingCompanyCancel = (reloadList = false) => {
    this.setState({
      isAddingCompany: false,
      currentCustomerGroup: {
        id: '',
        name: '',
      },
    })
    if (reloadList) this.getCompanies()
  }

  toCompany = (company) => () => {
    const {
      history,
    } = this.props
    const { companyId, companyStatus, companyName } = company
    history.push(`/companies/${companyId}?companyStatus=${companyStatus}&companyName=${encodeURIComponent(companyName)}`)
  }

  handleCompanyDelete = (actionsRowId, actionCompanyName) => () => {
    this.setState({
      isDeleteCompanyConfirmOpen: true,
      actionsRowId,
      actionCompanyName,
    })
  }

  handleDeleteCompanyConfirm = async (deleteCustomerGroup) => {
    this.setState({
      isLoading: true,
    })
    const {
      actionsRowId,
    } = this.state
    try {
      if (deleteCustomerGroup === '1') {
        await b3request.companies.deleteCompanyById(actionsRowId)
      } else {
        await b3request.companies.updateCompanyBasicInfoByCompanyId(actionsRowId, {
          companyStatus: '4',
        })
      }
      const {
        pagination,
      } = this.state
      snackbar.success(locales['app.tips.company.deleteCompanySuccessfully'])
      this.setState({
        isDeleteCompanyConfirmOpen: false,
        actionsRowId: '',
        actionCompanyName: '',
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getCompanies)
    } catch (error) {
      snackbar.error(error.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  handleMoreActionsClick = (actionsRowId) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      isDeleteCompanyConfirmOpen: false,
    })
  }

  handleCustomerGroupPickerHide = () => {
    this.setState({
      isCustomerGroupPickerOpen: false,
      currentCustomerGroup: {
        id: '',
        name: '',
      },
    })
  }

  handleConvertFromCustomerGroupClick = () => {
    this.setState({
      isCustomerGroupPickerOpen: true,
    })
    this.onAddNewCompanyDropdownClose()
  }

  handleCurrentCustomerGroupChange = (currentCustomerGroup) => {
    this.setState({
      currentCustomerGroup,
    })
  }

  handleCustomerGroupConfirm = () => {
    this.setState({
      isCustomerGroupPickerOpen: false,
      isAddingCompany: true,
    })
  }

  handleExport = async () => {
    this.setState({
      isLoading: true,
    })
    const { toggleCompanyCsvReadyStatus } = this.props
    try {
      await b3request.companies.beginToExportCompanyInfoToCsv()
      snackbar.info(locales['app.tips.csv.export'])
      toggleCompanyCsvReadyStatus({ stateKey: 'isExportCompanyCsvReady', status: '0' })
    } catch (error) {
      snackbar.error(locales['app.tips.csv.export.failed'])
      toggleCompanyCsvReadyStatus({ stateKey: 'isExportCompanyCsvReady', status: '1' })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleCompanyImportCancel = () => {
    this.setState({
      isCompanyImportOpen: false,
      companyUploadTitle: '',
      companyUploadSubtitle: '',
      companyImportUrl: '',
      companyImportFiles: [],
    })
  }

  handleCompanyImportOpen = (companyImportType) => async () => {
    let companyUploadTitle = ''
    let companyImportUrl = ''
    let companyUploadSubtitle = ''

    const {
      companiesTemplateUrl,
      addressesTemplateUrl,
    } = this.props

    switch (companyImportType) {
      case 'companies':
        companyUploadTitle = 'Company Bulk Creation'
        companyUploadSubtitle = 'Create new companies and company users using the CSV file.'
        companyImportUrl = companiesTemplateUrl
        break

      default:
        companyUploadTitle = 'Company Addresses Bulk Creation'
        companyUploadSubtitle = 'Create new company addresses for existing companies using the CSV file.'
        companyImportUrl = addressesTemplateUrl
        break
    }

    this.setState({
      isCompanyImportOpen: true,
      companyUploadTitle,
      companyUploadSubtitle,
      companyImportUrl,
      companyImportType,
      isCompanyImportUploading: false,
    })
    this.onImportCompaniesClose()
  }

  handleCompanyImportChange = (file) => {
    let companyImportFiles = [file]

    if (!file) companyImportFiles = []
    this.setState({
      companyImportFiles,
    })
  }

  handleCompanyImportConfirm = async (files) => {
    const { companyImportType } = this.state
    const { toggleCompanyCsvReadyStatus } = this.props

    this.setState({
      isCompanyImportUploading: true,
    })

    let uploadKey; let uploadFoo; let
      stateKey

    switch (companyImportType) {
      case 'companies':
        uploadKey = 'companyFile'
        uploadFoo = 'uploadCompaniesInfoCsv'
        stateKey = 'isImportCompaniesCsvReady'
        break
      default:
        uploadKey = 'addressFile'
        uploadFoo = 'uploadCompanyaddressesInfoCsv'
        stateKey = 'isImportCompanyAddressesCsvReady'
        break
    }

    const data = new FormData()
    data.append(uploadKey, files[0].file)

    try {
      const { isReady } = await b3request.companies[uploadFoo](data) || {}
      if (isReady !== undefined) {
        toggleCompanyCsvReadyStatus({ stateKey, status: isReady })
        snackbar.success(locales['app.tips.company.import.uploadSuccess'])
        this.setState({
          isCompanyImportOpen: false,
          companyImportFiles: [],
        })
      }
    } catch (error) {
      snackbar.error(error.message)
    }

    this.setState({
      isCompanyImportUploading: false,
    })
  }

  handleCatalogItemClear = (company) => async () => {
    const {
      pagination,
    } = this.state
    const {
      companyId,
    } = company
    this.setState({
      isLoading: true,
    })
    const catalogId = ''
    try {
      await b3request.companies.updateCompanyBasicInfoByCompanyId(companyId, {
        catalogId,
      })
      snackbar.success(locales['app.tips.company.updatedPriceListSuccessfully'])
    } finally {
      this.setState({
        isLoading: false,
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getCompanies)
    }
  }

  render() {
    const {
      classes,
      isExportCompanyCsvReady,
      isImportCompaniesCsvReady,
      isImportCompanyAddressesCsvReady,
    } = this.props

    const {
      companies,
      isLoading,
      hoverRowId,
      q,
      currentCompanyCatalogId,
      currentCompanyId,
      isCatalogPickerOpen,
      pagination,
      sortBy,
      orderBy,
      isAddingCompany,
      actionsPopoverAnchorEl,
      actionsRowId,
      actionCompanyName,
      isDeleteCompanyConfirmOpen,
      isCustomerGroupPickerOpen,
      currentCustomerGroup,
      isCompanyImportOpen,
      companyUploadTitle,
      companyUploadSubtitle,
      companyImportUrl,
      isCheckingCompanyImport,
      isCompanyImportUploading,
      companyImportFiles,
    } = this.state

    return (
      <B3Card
        title={(
          <Grid container>
            <B3Search
              value={q}
              onChange={this.handleSearchChange}
              onSearch={this.handleSearch}
              placeholder="search company name…"
            />
          </Grid>
        )}
        extra={(
          <ExtraContainer>
            <DropdownGroup
              action={(onOpen) => (
                <ButtonGroup className="first">
                  <React.B3PermissionContainer
                    permissions={['11203', '11206']}
                  >
                    {(props) => (
                      <Tooltip title="Import companies to your store" placement="left-start">
                        <Button
                          {...props}
                          variant="contained"
                          color="primary"
                          onClick={onOpen}
                        >
                          <ImportIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </React.B3PermissionContainer>
                  <React.B3PermissionContainer
                    permissions={['11500']}
                  >
                    {(props) => (
                      <Tooltip title="Export your companies to a CSV file" placement="top-start">
                        <Button
                          {...props}
                          variant="contained"
                          color="primary"
                          onClick={this.handleExport}
                          disabled={isExportCompanyCsvReady === '0'}
                        >
                          <ExportIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </React.B3PermissionContainer>
                </ButtonGroup>
              )}
            >
              {
                ({ onClose }) => {
                  this.onImportCompaniesClose = onClose
                  return (
                    <MenuList>
                      <B3Spin isSpinning={isCheckingCompanyImport}>
                        <MenuItem
                          onClick={this.handleCompanyImportOpen('companies')}
                          disabled={isImportCompaniesCsvReady === '0'}
                        >
                          Import Companies
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleCompanyImportOpen('addresses')}
                          disabled={isImportCompanyAddressesCsvReady === '0'}
                        >
                          Import Company Addresses
                        </MenuItem>
                      </B3Spin>
                    </MenuList>
                  )
                }
              }
            </DropdownGroup>
            <React.B3PermissionContainer
              permissions={['11200', '11106', '11107', '11201']}
            >
              <DropdownGroup
                action={(handleClick) => (
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                  >
                    Add New Company
                  </Button>
                )}
              >
                {
                  ({ onClose }) => {
                    this.onAddNewCompanyDropdownClose = onClose
                    return (
                      <MenuList>
                        <MenuItem onClick={this.handleAddNewCompanyClick}>Add a new company</MenuItem>
                        <MenuItem onClick={this.handleConvertFromCustomerGroupClick}>Convert from a customer group</MenuItem>
                      </MenuList>
                    )
                  }
                }
              </DropdownGroup>
            </React.B3PermissionContainer>
          </ExtraContainer>
        )}
        isLoading={isLoading}
      >
        {
          companies.length === 0 ? <NoData /> : (
            <>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === 'companyName'}
                        direction={orderBy}
                        onClick={this.handleSort('companyName')}
                      >
                        Company Name
                      </TableSortLabel>
                    </TableCell>
                    <React.B3PermissionContainer
                      permissions={['11113', '11300']}
                    >
                      <TableCell>Price List</TableCell>
                    </React.B3PermissionContainer>
                    <TableCell style={{ width: '120px' }}>Status</TableCell>
                    <TableCell style={{ width: '160px' }}>
                      <TableSortLabel
                        active={sortBy === 'updatedAt'}
                        direction={orderBy}
                        onClick={this.handleSort('updatedAt')}
                      >
                        Last Updated
                      </TableSortLabel>
                    </TableCell>
                    {
                      checkOneOfPermissions([['11105'], ['11401']]) && (
                        <TableCell style={{ width: '60px' }}>Actions</TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companies.map((company) => (
                    <TableRow
                      hover
                      key={company.companyId}
                      onMouseEnter={this.handleRowHover(company.companyId)}
                      onMouseLeave={this.handleRowLeave}
                    >
                      <TableCell>
                        <React.B3PermissionContainer
                          permissions={['11105']}
                          no={(
                            <Typography
                              variant="body2"
                              component="span"
                              className={classes.ellipsis}
                            >
                              {company.companyName}
                            </Typography>
                          )}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className={classes.ellipsis}
                          >
                            <Link onClick={this.toCompany(company)}>{company.companyName}</Link>
                          </Typography>
                        </React.B3PermissionContainer>
                      </TableCell>
                      <React.B3PermissionContainer
                        permissions={['11113', '11300']}
                      >
                        <TableCell>
                          <span className={classes.ellipsis}>
                            {company.catalogName}
                          </span>
                          {
                            hoverRowId === company.companyId && (
                              <>
                                <IconButton
                                  className={classes.editIconButton}
                                  onClick={this.handleEditIconClick(company)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                {
                                  company.catalogName && (
                                    <Tooltip
                                      title="clear"
                                      placement="top"
                                    >
                                      <IconButton
                                        className={classes.editIconButton}
                                        onClick={this.handleCatalogItemClear(company)}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }
                              </>
                            )
                          }
                        </TableCell>
                      </React.B3PermissionContainer>
                      <TableCell>
                        <B3Tag
                          color={getCompanyStatusByCode(company.companyStatus).color}
                        >
                          {getCompanyStatusByCode(company.companyStatus).text}
                        </B3Tag>
                      </TableCell>
                      <TableCell>{b3DateFormat.displayFormat(company.updatedAt)}</TableCell>
                      {
                        checkOneOfPermissions([['11105'], ['11401']]) && (
                          <TableCell>
                            <IconButton
                              className={classes.editIconButton}
                              onClick={this.handleMoreActionsClick(company.companyId)}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              anchorEl={actionsPopoverAnchorEl}
                              open={actionsRowId === company.companyId}
                              onClose={this.handleMoreActionsPopoverClose}
                            >
                              <React.B3PermissionContainer
                                permissions={['11105']}
                              >
                                {({
                                  ref,
                                }) => (
                                  <MenuItem
                                    onClick={this.toCompany(company)}
                                    ref={ref}
                                  >
                                    <Typography variant="subtitle2">
                                      View Details
                                    </Typography>
                                  </MenuItem>
                                )}
                              </React.B3PermissionContainer>
                              <React.B3PermissionContainer
                                permissions={['11401']}
                              >
                                {({
                                  ref,
                                }) => (
                                  <MenuItem
                                    onClick={this.handleCompanyDelete(company.companyId, company.companyName)}
                                    ref={ref}
                                  >
                                    <Typography variant="subtitle2">
                                      Delete
                                    </Typography>
                                  </MenuItem>
                                )}
                              </React.B3PermissionContainer>
                            </Menu>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={pagination.totalCount}
                rowsPerPage={pagination.limit}
                page={this.currentPage}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
              />
            </>
          )
        }
        {
          isCatalogPickerOpen && (
            <B3CatalogPicker
              isOpen={isCatalogPickerOpen}
              selectedCatalogId={currentCompanyCatalogId}
              onSelectedCatalogIdChange={this.handleCurrentCompayCatalogIdChange}
              companyId={currentCompanyId}
              onHide={this.handleCatalogPickerHide}
            />
          )
        }
        {
          isCustomerGroupPickerOpen && (
            <CustomerGroupPicker
              isOpen={isCustomerGroupPickerOpen}
              selectedCustomerGroupId={currentCustomerGroup.id}
              onSelectedCustomerGroupChange={this.handleCurrentCustomerGroupChange}
              onConfirm={this.handleCustomerGroupConfirm}
              onHide={this.handleCustomerGroupPickerHide}
            />
          )
        }
        {
          isAddingCompany && (
            <AddNewCompany
              onCancel={this.handleAddingCompanyCancel}
              customerGroup={currentCustomerGroup}
            />
          )
        }
        <DeleteCompanyConfirm
          isOpen={isDeleteCompanyConfirmOpen}
          onClose={this.handleMoreActionsPopoverClose}
          onConfirm={this.handleDeleteCompanyConfirm}
          isDeleting={isLoading}
          companyName={actionCompanyName}
        />
        {
          isCompanyImportOpen && (
            <B3CsvUpload
              files={companyImportFiles}
              dialogTitle={companyUploadTitle}
              subtitle={companyUploadSubtitle}
              templateUrl={companyImportUrl}
              onCancel={this.handleCompanyImportCancel}
              onConfirm={this.handleCompanyImportConfirm}
              onChange={this.handleCompanyImportChange}
              isUpLoading={isCompanyImportUploading}
            />
          )
        }
      </B3Card>
    )
  }
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: 'calc(100% - 40px)',
  },
  editIconButton: {
    padding: 4,
    marginLeft: 4,
    display: 'inline-block',
  },
}

const mapStateToProps = (state) => {
  const {
    company: {
      isExportCompanyCsvReady,
      isImportCompaniesCsvReady,
      isImportCompanyAddressesCsvReady,
      shouldCompanyListUpdate,
      companiesTemplateUrl,
      addressesTemplateUrl,
    },
  } = state
  return {
    isExportCompanyCsvReady,
    isImportCompaniesCsvReady,
    isImportCompanyAddressesCsvReady,
    shouldCompanyListUpdate,
    companiesTemplateUrl,
    addressesTemplateUrl,
  }
}
export default connect(mapStateToProps, { toggleCompanyCsvReadyStatus, toggleShouldCompanyListUpdate })(withStyles(styles)(Companies))
