export const userStatusText = {
  0: 'Inactive',
  1: 'Active',
}

const userStatusColor = {
  0: 'error',
  1: 'info',
}

export default (code) => ({
  text: userStatusText[code],
  color: userStatusColor[code],
})
