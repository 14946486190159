import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  FormControlLabel,
  Checkbox,
  TablePagination,
  styled,
  RadioGroup,
  Radio,
  Box,
} from '@material-ui/core'

import {
  B3Spin,
  NoData,
  B3Search,
} from '../../components'

import b3request from '../../service'
import getAssignmentsByName from '../../utils/getAssignmentsByName'

const assignments = getAssignmentsByName()

const SaleRepLabel = styled('span')({
  wordBreak: 'break-all',
})

export default class SalesRepsList extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    assignedSalesReps: PropTypes.arrayOf(PropTypes.shape({
      salesRepId: PropTypes.string,
      isAssigned: PropTypes.string,
    })).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      salesRepsList: [],
      pagination: {
        offset: 0,
        limit: 15,
        totalCount: 0,
      },
      q: '',
      assignments,
      assignmentStatus: '1',
    }
  }

  componentDidMount() {
    this.getSalesReps()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get hasSalesRepsList() {
    const { salesRepsList } = this.state
    return Array.isArray(salesRepsList) && salesRepsList.length > 0
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getSalesReps)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getSalesReps)
  }

  getSalesReps = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      assignedSalesReps,
    } = this.props

    const {
      pagination,
      pagination: {
        offset,
        limit,
      },
      q,
      assignmentStatus: status,
    } = this.state

    const {
      id,
    } = this.props

    try {
      const resp = await b3request.companies.getCompanySalesReps({
        id,
        params: {
          offset,
          limit,
          q,
          status,
        },
      })
      const salesRepsList = resp.list.map((salesRep) => {
        const cacheSalesRep = assignedSalesReps.find((assignedSalesRep) => assignedSalesRep.salesRepId === salesRep.salesRepId) || {}
        return {
          ...salesRep,
          ...cacheSalesRep,
        }
      })
      this.setState({
        salesRepsList,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      //
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
  }

  handleAssignStatusChange = (event) => {
    const {
      onChange,
    } = this.props
    const {
      pagination,
    } = this.state
    this.setState({
      assignmentStatus: event.target.value,
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
    onChange(null, true)
  }

  handleChangeSalesRepAssigned = (customSalesRep) => () => {
    const {
      salesRepsList,
    } = this.state
    const {
      onChange,
    } = this.props

    const isAssignedReverse = {
      0: '1',
      1: '0',
    }
    const changedSalesRepsList = salesRepsList.map((salesRep) => {
      const { isAssigned } = customSalesRep
      if (salesRep.salesRepId === customSalesRep.salesRepId) {
        const reverseAssigned = isAssignedReverse[isAssigned]
        customSalesRep.isAssigned = reverseAssigned
        salesRep.isAssigned = reverseAssigned
      }
      return {
        ...salesRep,
      }
    })

    this.setState({
      salesRepsList: changedSalesRepsList,
    })

    onChange(customSalesRep)
  }

  render() {
    const {
      isLoading,
      salesRepsList,
      pagination,
      q,
      assignmentStatus,
      assignments,
    } = this.state

    const {
      disabled,
    } = this.props

    return (
      <B3Spin
        isSpinning={isLoading}
      >
        <Grid container>
          <Grid
            item
            xs={6}
          >
            <Box pr={2}>
              <B3Search
                value={q}
                onChange={this.handleSearchChange}
                onSearch={this.handleSearch}
                placeholder="search sales rep name…"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              row
              value={assignmentStatus}
              onChange={this.handleAssignStatusChange}
            >
              {
                assignments.map((assignment) => (
                  <FormControlLabel
                    key={assignment.status}
                    value={assignment.status}
                    label={assignment.label}
                    control={<Radio />}
                  />
                ))
              }
            </RadioGroup>
          </Grid>
        </Grid>
        {
          this.hasSalesRepsList ? (
            <Grid
              container
            >
              <Grid item xs={12} />
              {
                salesRepsList.map((salesRep) => (
                  <Grid
                    item
                    xs={4}
                    key={salesRep.salesRepId}
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={salesRep.isAssigned === '1'}
                          onChange={this.handleChangeSalesRepAssigned(salesRep)}
                          disabled={disabled}
                        />
                      )}
                      label={<SaleRepLabel>{`${salesRep.firstName} ${salesRep.lastName}`}</SaleRepLabel>}
                    />
                  </Grid>
                ))
              }
              <Grid
                item
                xs={12}
              >
                <TablePagination
                  align="left"
                  rowsPerPageOptions={[15, 30, 45]}
                  component="div"
                  count={pagination.totalCount}
                  rowsPerPage={pagination.limit}
                  page={this.currentPage}
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                />
              </Grid>
            </Grid>
          ) : (
            <NoData text="No sales reps." />
          )
        }
      </B3Spin>
    )
  }
}
