import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TablePagination,
  Divider,
  ListItem,
} from '@material-ui/core'

import {
  B3Spin,
  B3Search,
} from '../../components'

import b3request from '../../service'
import { snackbar } from '../../utils'

class SalesRepsPopup extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    groupBy: PropTypes.oneOf(['1', '2']).isRequired,
    popupId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isOpen: true,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      q: '',
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      salesReps: [],
    }
  }

  componentDidMount() {
    this.getSalesReps()
  }

  componentDidUpdate(prevProps) {
    const {
      popupId,
    } = this.props
    const {
      popupId: prePopupId,
    } = prevProps

    if (popupId !== prePopupId) {
      this.getSalesReps({
        q: '',
        pagination: {
          offset: 0,
          limit: 10,
          totalCount: 0,
        },
        salesReps: [],
      })
    }
  }

  getSalesReps = (state = {}) => {
    const requestFunc = async () => {
      this.setState({
        isLoading: true,
      })

      const {
        groupBy,
        popupId,
      } = this.props
      const {
        pagination,
        pagination: {
          offset,
          limit,
        },
        q,
      } = this.state

      const request = groupBy === '1' ? b3request.salesReps.getSalesRepsCompanies : b3request.companies.getCompanySalesReps

      try {
        const resp = await request({
          id: popupId,
          params: {
            offset,
            limit,
            q,
            status: '1',
          },
        })
        this.setState({
          salesReps: resp.list,
          pagination: {
            ...pagination,
            offset: resp.pagination.offset,
            totalCount: resp.pagination.totalCount,
          },
        })
      } catch (error) {
        snackbar.error(error.message)
      } finally {
        this.setState({
          isLoading: false,
        })
      }
    }

    if (state.pagination) {
      this.setState({
        ...state,
      }, requestFunc)
    } else requestFunc()
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getSalesReps)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getSalesReps)
  }

  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getSalesReps)
  }

  render() {
    const {
      isLoading,
      q,
      pagination,
      salesReps,
    } = this.state

    const {
      isOpen,
      onClose,
      groupBy,
    } = this.props

    const dialogTitle = groupBy === '1' ? 'Assigned Company(ies)' : 'Assigned Sales Rep(s)'
    const searchPlaceholder = groupBy === '1' ? 'search company name…' : 'search sales rep'

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={6}>{dialogTitle}</Grid>
            <Grid
              item
              xs={6}
            >
              <B3Search
                value={q}
                onChange={this.handleSearchChange}
                onSearch={this.handleSearch}
                placeholder={searchPlaceholder}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <B3Spin isSpinning={isLoading}>
            <Grid container>
              {
                salesReps.map((salesRep) => (
                  <Grid item xs={12} key={salesRep.companyId || salesRep.salesRepId}>
                    <ListItem divider>
                      {salesRep.companyName || `${salesRep.firstName} ${salesRep.lastName}`}
                    </ListItem>
                  </Grid>
                ))
              }
              <Grid
                item
                xs={12}
              >
                <TablePagination
                  align="left"
                  rowsPerPageOptions={[10, 20]}
                  component="div"
                  count={pagination.totalCount}
                  rowsPerPage={pagination.limit}
                  page={this.currentPage}
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                />
              </Grid>
            </Grid>
          </B3Spin>
        </DialogContent>
        <Divider />
      </Dialog>
    )
  }
}

export default SalesRepsPopup
