import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import CountUp from 'react-countup'
import {
  Grid,
  Link,
  Typography,
} from '@material-ui/core'

import {
  B3Card,
} from '../../components'

import b3request from '../../service'

import {
  snackbar,
} from '../../utils'

class Summaries extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      summairies: [{
        title: 'Companies',
        total: 0,
        url: '/companies',
        permissions: ['11100'],
      }, {
        title: 'Price Lists',
        total: 0,
        url: '',
        permissions: [],
      }, {
        title: 'Sales Reps',
        total: 0,
        url: '/sales-reps',
        permissions: ['13101'],
      }],
      isLoading: true,
    }
  }

  async componentDidMount() {
    try {
      const resp = await b3request.dashboard.getStats()
      if (Array.isArray(resp)) {
        const {
          summairies,
        } = this.state

        this.setState({
          isLoading: false,
          summairies: summairies.map((summary, i) => ({
            ...summary,
            ...resp[i],
          })),
        })
      }
    } catch (error) {
      snackbar.error(error.message)
    }
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  viewDetails = (url) => () => {
    const {
      history: {
        push,
      },
    } = this.props
    push(url)
  }

  render() {
    const {
      summairies,
      isLoading,
    } = this.state
    return (
      <Grid
        container
        spacing={2}
      >
        {
          summairies.map((summary) => (
            <Grid
              item
              xs={12}
              md={4}
              key={summary.title}
            >
              <B3Card
                title={summary.title}
                isLoading={isLoading}
                extra={!!summary.url && (
                  <React.B3PermissionContainer
                    permissions={summary.permissions}
                  >
                    <Link
                      component="button"
                      onClick={this.viewDetails(summary.url)}
                    >
                      view details
                    </Link>
                  </React.B3PermissionContainer>
                )}
              >
                <Typography
                  variant="h2"
                  color="primary"
                >
                  <CountUp end={summary.total} />
                </Typography>
              </B3Card>
            </Grid>
          ))
        }
      </Grid>
    )
  }
}

export default withRouter(Summaries)
