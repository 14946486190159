import {
  TOGGLE_COMPANY_CSV_READY_STATUS,
  TOGGLE_SHOULD_COMPANY_LIST_UPDATE,
  SETTING_COMPANIES_EXTRAFIELDS,
} from '../actionTypes'

import b3request from '../../service'
import { snackbar } from '../../utils'

export const toggleCompanyCsvReadyStatus = ({
  stateKey = 'isExportCompanyCsvReady',
  status = '0',
}) => ({
  type: TOGGLE_COMPANY_CSV_READY_STATUS,
  payload: {
    stateKey,
    status,
  },
})


export const toggleShouldCompanyListUpdate = (status) => ({
  type: TOGGLE_SHOULD_COMPANY_LIST_UPDATE,
  payload: {
    status,
  },
})

export const settingCompaniesExtraFields = (status) => ({
  type: SETTING_COMPANIES_EXTRAFIELDS,
  payload: {
    status,
  },
})

export const getCompaniesExtraFields = () => async (dispatch) => {
  try {
    const resp = await b3request.companies.getCompaniesExtraFields()
    dispatch(settingCompaniesExtraFields(resp))
  } catch (error) {
    snackbar.error('Unknown Error!')
  }
}
