import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

export default function B3RouteComponent({
  setPageTitle,
  pageTitle,
  component: RouteComponent,
  ...restProps
}) {
  useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])
  return <RouteComponent {...restProps} />
}

B3RouteComponent.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
}
