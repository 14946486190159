import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import { noop } from 'lodash'

import {
  Grid,
  styled,
  Checkbox,
  Typography,
} from '@material-ui/core'

import {
  TreeView,
  TreeItem,
} from '@material-ui/lab'

import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'

const StyleCheckBoxGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const RolesTree = (props) => {
  const {
    permission,
    onChange,
    permissionModules,
    checkboxDisabled,
  } = props

  const [expanded, setExpanded] = useState([])

  const getPermissionChecked = (permissionItem) => {
    if (!permissionItem) return true

    const {
      permissionModuleId: permissionModuleIds,
      child: childs,
    } = permissionItem

    const getCheckedStatus = (ids) => (ids.every(
      (id) => (
        permissionModules.includes(id)
      ),
    ))

    if (permissionModuleIds.length) {
      return getCheckedStatus(permissionModuleIds)
    }

    if (childs) return childs.some((child) => (getCheckedStatus(child.permissionModuleId)))

    return false
  }

  const handlePermissionChecked = (permissionItem) => (e) => {
    const { checked } = e.target
    e.preventDefault()
    onChange({
      permission: permissionItem,
      checked,
    })
  }

  const getPermissionTree = (permissionItem, faterPermission) => {
    const isChecked = getPermissionChecked(permissionItem)
    const nodeId = permissionItem.title
    if (isChecked) expanded.push(nodeId)
    else {
      const nodeIdIndex = expanded.indexOf(nodeId)
      if (nodeIdIndex !== -1) {
        expanded.splice(nodeIdIndex, 1)
      }
    }

    return (
      <TreeItem
        key={uuid()}
        nodeId={permissionItem.title}
        label={(
          <StyleCheckBoxGroup>
            <Checkbox
              checked={isChecked}
              onChange={handlePermissionChecked(permissionItem)}
              disabled={checkboxDisabled || !getPermissionChecked(faterPermission)}
            />
            <Typography variant="body1">{permissionItem.title}</Typography>
          </StyleCheckBoxGroup>
        )}
      >
        {
          permissionItem.child && permissionItem.child.map((child) => (getPermissionTree(child, permissionItem)))
        }
      </TreeItem>
    )
  }
  // TODO: fix the hooks warning
  // eslint-disable-next-line
  const renderPermissionTreeItem = useCallback(() => getPermissionTree(permission), [permission])

  const handleNodeToggle = (e, nodeIds) => {
    setExpanded(nodeIds)
  }

  return (
    <Grid item xs={12} md={6} lg={4} key={uuid()}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
      >
        {renderPermissionTreeItem()}
      </TreeView>
    </Grid>
  )
}

RolesTree.propTypes = {
  permission: PropTypes.shape({
    title: PropTypes.string.isRequired,
    child: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    permissionModuleId: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  permissionModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  checkboxDisabled: PropTypes.bool,
}

RolesTree.defaultProps = {
  onChange: noop,
  checkboxDisabled: false,
}

export default RolesTree
