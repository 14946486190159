import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
} from '@material-ui/core'

import { B3Spin } from '..'

const useStyles = makeStyles({
  listIcon: {
    minWidth: 32,
  },
  listText: {
    whiteSpace: 'normal',
  },
})

const B3Nav = (props) => {
  const {
    isShowText,
    menuItems,
    history,
    isSpinning,
  } = props
  const classes = useStyles()

  return (
    <B3Spin
      isSpinning={isSpinning}
      tip="Loading…"
    >
      {
        menuItems.map((menuItem) => {
          const {
            path,
            primary,
            icon: Icon,
          } = menuItem
          return (
            <Fragment key={path}>
              <MenuItem
                button
                selected={history.location.pathname.includes(path)}
                onClick={() => {
                  if (history.location.pathname !== path) history.push(path)
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Icon />
                </ListItemIcon>
                {
                  isShowText && <ListItemText className={classes.listText} primary={primary} />
                }
              </MenuItem>
              <Divider />
            </Fragment>
          )
        })
      }
    </B3Spin>
  )
}

B3Nav.propTypes = {
  isShowText: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      primary: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isSpinning: PropTypes.bool,
}

B3Nav.defaultProps = {
  isShowText: true,
  isSpinning: false,
}

export default withRouter(B3Nav)
