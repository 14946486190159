import {
  TOGGLE_SALES_REP_CSV_READY_STATUS,
  TOGGLE_SHOULD_S_REP_TABLE_UPDATE,
} from '../actionTypes'

const initState = {
  isImportSalesRepCsvReady: '0',
  shouldSalesRepListUpdate: false,
  salesRepTemplateUrl: '',
  isExportSalesRepCsvReady: '1',
}

export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_SALES_REP_CSV_READY_STATUS:
      return {
        ...state,
        [action.payload.stateKey]: action.payload.status,
      }
    case TOGGLE_SHOULD_S_REP_TABLE_UPDATE:
      return {
        ...state,
        shouldSalesRepListUpdate: action.payload.status,
      }
    default:
      return state
  }
}
