import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { noop } from 'lodash'

import {
  TextField,
  Grid,
  Collapse,
  styled,
  Button,
  ButtonGroup,
  makeStyles,
  Box,
  Select,
  MenuItem,
  Input,
  FormControl,
  InputLabel,
} from '@material-ui/core'

import { FilterList } from '@material-ui/icons'

const FilterIcon = styled(FilterList)({
  marginLeft: -4,
  marginRight: 8,
})

const FiltersContainer = styled('div')((props) => ({
  padding: props.theme.spacing(2),
  backgroundColor: props.theme.palette.grey[100],
  fontWeight: 'normal',
}))

const useStyles = makeStyles((theme) => ({
  collapse: {
    marginTop: theme.spacing(),
  },
  formLabel: {
    paddingRight: theme.spacing(),
  },
  alignAright: {
    textAlign: 'right',
  },
  filtersBox: {
    display: 'block',
    width: '100%',
  },
}))

const B3Filter = (props) => {
  const classes = useStyles()
  const {
    filters,
    onChange,
    onCancel,
    onApply,
    statusFilters,
    statusLabel,
    statusValue,
    filtersStatusKey,
  } = props

  const [isShow, toggleIsShow] = useState(false)
  const changeChecked = () => toggleIsShow((isShow) => !isShow)

  // const getStatusChecked = (statusCode) => filters[filtersStatusKey].some((item) => item === statusCode)
  const handleCheckedChange = (e) => {
    filters[filtersStatusKey] = e.target.value
    onChange({
      ...filters,
    })
  }

  const handleTextFieldChange = (key) => (e) => {
    onChange({
      ...filters,
      [key]: e.target.value,
    })
  }

  const firstUpperCase = (str) => str.replace(str[0], str[0].toUpperCase())

  return (
    <>
      <Box ml={1}>
        <Button
          variant="outlined"
          onClick={changeChecked}
        >
          <FilterIcon />
          Filters
        </Button>
      </Box>
      <div
        className={clsx(
          classes.filtersBox,
          {
            [classes.collapse]: isShow,
          },
        )}
      >
        <Collapse in={isShow}>
          <FiltersContainer>
            <Grid
              container
              spacing={4}
              alignItems="center"
            >
              {
                Object.entries(filters).reduce((result, [key, value]) => {
                  if (typeof value === 'object') return result
                  const filter = (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={key}
                    >
                      <TextField
                        fullWidth
                        label={firstUpperCase(key)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                      />
                    </Grid>
                  )
                  result.push(filter)
                  return result
                }, [])
              }
              {
                Object.keys(statusFilters).length ? (
                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        multiple
                        value={filters[filtersStatusKey]}
                        onChange={handleCheckedChange}
                        input={<Input />}
                      >
                        {Object.entries(statusFilters).map(([key, value]) => {
                          const code = statusValue ? value[statusValue] : key
                          const label = statusLabel ? value[statusLabel] : value
                          return (
                            <MenuItem key={key} value={code}>{label}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : ''
              }
              <Grid item xs={8} md={10} />
              <Grid
                item
                xs={4}
                md={2}
                className={classes.alignAright}
              >
                <ButtonGroup size="small">
                  <Button onClick={() => onCancel()}>Clear</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onApply()}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </FiltersContainer>
        </Collapse>
      </div>
    </>
  )
}

B3Filter.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  statusFilters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  statusLabel: PropTypes.string,
  statusValue: PropTypes.string,
  filtersStatusKey: PropTypes.string,
}

B3Filter.defaultProps = {
  filters: {},
  statusFilters: {},
  onChange: noop,
  onCancel: noop,
  onApply: noop,
  statusLabel: '',
  statusValue: '',
  filtersStatusKey: 'status',
}

export default B3Filter
