/**
 * for creating global info snackbar(s)
 * the snackbars will display on the bottom-right of the page
 * if you wanna change the component please just modify the component on the folder `/src/components/B3Snackbar`
 * there are 4 type of snackbars: `error`, `info`, `success` and `warning`
 * @example
 * ……
 * import { snackbar } from 'relative-path/src/utils'
 * ……
 * snackbar.error('error message!')
 * snackbar.info('info message!')
 * snackbar.success('success message!')
 * snackbar.warning('warning message!')
 * ……
 */

import { createSnackbarMessage } from '../store/actions/global'

import store from '../store'

const snackbar = {}
const variants = ['error', 'success', 'info', 'warning']

variants.forEach((variant) => {
  snackbar[variant] = (message = `${variant} without any info.`, duration = 3000) => store.dispatch(createSnackbarMessage({
    variant,
    duration,
    message,
  }))
})

export default snackbar
