import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import uuid from 'uuid'

import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
} from '@material-ui/core'

import {
  B3Card,
  B3Back,
} from '../../components'

import {
  snackbar,
  b3storage,
  permissionMap,
} from '../../utils'

import RolesTree from './RolesTree'

import b3request from '../../service'
import locales from '../../locales/en-US'

const PermissionTrees = memo((props) => {
  const {
    isPermissionMapChecked,
    permissionModules,
    onChange,
    checkboxDisabled,
  } = props

  const renderPermissionTrees = () => (isPermissionMapChecked ? permissionMap.map((permission) => (
    <RolesTree
      key={uuid()}
      onChange={onChange}
      permissionModules={permissionModules}
      permission={permission}
      checkboxDisabled={checkboxDisabled}
    />
  )) : null)

  return (
    <Grid container>
      {renderPermissionTrees()}
    </Grid>
  )
})

PermissionTrees.propTypes = {
  isPermissionMapChecked: PropTypes.bool.isRequired,
  permissionModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  checkboxDisabled: PropTypes.bool.isRequired,
}

class Role extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        state: PropTypes.shape({
          mode: PropTypes.string.isRequired,
          id: PropTypes.string,
        }),
      }),
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super()

    const roleState = b3storage.roleState.val || {}
    const query = props.history.location.state ?? roleState
    const title = query.id ? 'Role Info' : 'Add Role'

    this.state = {
      ...query,
      title,
      isLoading: false,
      roleName: '',
      permissionModules: ['10003'],
      permissionList: [],
      isPermissionMapChecked: false,
      isRoleNameInputError: false,
      isRoleNameInputTouched: false,
    }
  }

  async componentDidMount() {
    const {
      history: {
        location: {
          state,
        },
      },
    } = this.props

    if (state) b3storage.roleState.val = state
    await this.getPermissions()
    await this.getRole()
  }

  componentWillUnmount() {
    this.setState = () => false
    b3storage.roleState.remove()
  }

  get permissions() {
    const {
      permissionModules,
      permissionList,
    } = this.state

    if (!permissionList.length) return []

    return permissionModules.map((permissionModule) => (
      permissionList.find(({
        permissionModuleId,
      }) => (permissionModuleId === permissionModule))
        .id
    ))
  }

  get isSubmitDisabled() {
    const {
      isRoleNameInputTouched,
      isRoleNameInputError,
      mode,
    } = this.state

    const inputValidedSuccess = mode === 'add' ? (
      isRoleNameInputTouched && !isRoleNameInputError
    ) : !isRoleNameInputError

    return !inputValidedSuccess
  }

  checkPermissionMap = () => {
    const {
      permissionList,
    } = this.state

    const map = cloneDeep(permissionMap)
    while (map.length) {
      const permission = map.shift()
      const isContained = permission.permissionModuleId.every((mapId) => (
        !!permissionList.find((item) => item.permissionModuleId === mapId)
      ))
      if (!isContained) return Promise.reject(new Error('No Matching PermissionModule'))
      if (permission.child) map.push(...permission.child)
    }
    return true
  }

  getPermissions = async () => {
    this.setState({
      isLoading: true,
    })

    try {
      const {
        list,
      } = await b3request.roles.getPermissions()
      this.setState({
        permissionList: list,
      })

      await this.checkPermissionMap()
      this.setState({
        isPermissionMapChecked: true,
      })
    } catch (error) {
      snackbar.error(error)
    }

    this.setState({
      isLoading: false,
    })
  }

  getRole = async () => {
    const {
      id,
      permissionList,
    } = this.state

    if (!id) return

    this.setState({
      isLoading: true,
    })
    try {
      const {
        roleName,
        permissions,
      } = await b3request.roles.getRole(id)

      const permissionModules = permissions.map((permissionId) => (
        permissionList.find(
          (permissionItem) => permissionItem.id === permissionId,
        ).permissionModuleId
      ))

      this.setState({
        roleName,
        permissionModules: [...new Set(permissionModules)],
      })
    } catch (error) {
      snackbar.error(error)
    }
    this.setState({
      isLoading: false,
    })
  }

  handlePermissionChecked = ({
    permission,
    checked,
  }) => {
    const {
      permissionModules,
    } = this.state

    const pushPermission = (permissionItem) => {
      permissionModules.push(...permissionItem.permissionModuleId)
      if (permissionItem.child) {
        permissionItem.child.forEach(pushPermission)
      }
    }

    const popPermission = (permissionItem) => {
      permissionItem.permissionModuleId.forEach((permissionModuleId) => {
        const index = permissionModules.indexOf(permissionModuleId)
        if (index !== -1) permissionModules.splice(index, 1)
      })
      if (permissionItem.child) {
        permissionItem.child.forEach(popPermission)
      }
    }

    if (checked) pushPermission(permission)
    else popPermission(permission)

    this.setState({
      permissionModules: [...new Set(permissionModules)],
    })
  }

  handleTextInput = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    })

    if (key === 'roleName') {
      this.validateRoleName(e.target.value)
      this.setState({
        isRoleNameInputTouched: true,
      })
    }
  }

  handleTextChange = (key) => (e) => {
    if (key === 'roleName') this.validateRoleName(e.target.value)
  }

  validateRoleName = (value) => {
    this.setState({
      isRoleNameInputError: !value,
    })
  }

  handleSubmit = async () => {
    const {
      mode,
      id,
      roleName,
    } = this.state

    const {
      history: {
        goBack,
      },
    } = this.props

    let request
    let tipSuccess
    let tipError

    switch (mode) {
      case 'edit':
        request = b3request.roles.updateRole
        tipSuccess = locales['app.tips.roles.updateRoleSuccessfully']
        tipError = locales['app.tips.roles.updateRoleFailed']
        break
      default:
        request = b3request.roles.createRole
        tipSuccess = locales['app.tips.roles.createRoleSuccessfully']
        tipError = locales['app.tips.roles.createRoleFailed']
    }

    const permissionIds = [...new Set(this.permissions)]

    this.setState({
      isLoading: true,
    })

    try {
      await request({
        permissionIds,
        roleName,
      }, id)
      snackbar.success(tipSuccess)
      goBack()
    } catch {
      snackbar.error(tipError)
    }

    this.setState({
      isLoading: false,
    })
  }

  render() {
    const {
      isLoading,
      title,
      mode,
      roleName,
      isRoleNameInputError,
      isPermissionMapChecked,
      permissionModules,
    } = this.state

    return (
      <B3Card
        isLoading={isLoading}
        title={(
          <B3Back
            title={title}
          />
        )}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Role Name"
              disabled={mode === 'view'}
              value={roleName}
              onInput={this.handleTextInput('roleName')}
              onBlur={this.handleTextChange('roleName')}
              error={isRoleNameInputError}
              helperText={
                isRoleNameInputError ? locales['app.validation.errors.required.roleName'] : ''
              }
            />
          </Grid>
          <Box mt={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Permissions
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <PermissionTrees
          isPermissionMapChecked={isPermissionMapChecked}
          permissionModules={permissionModules}
          onChange={this.handlePermissionChecked}
          checkboxDisabled={mode === 'view'}
        />
        {
          mode !== 'view' && (
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={this.isSubmitDisabled}
            >
              {
                mode === 'add' ? 'Add' : 'Save'
              }
            </Button>
          )
        }
      </B3Card>
    )
  }
}

export default Role
