import {
  TOGGLE_COMPANY_CSV_READY_STATUS,
  TOGGLE_SHOULD_COMPANY_LIST_UPDATE,
  SETTING_COMPANIES_EXTRAFIELDS,
} from '../actionTypes'

const initState = {
  isExportCompanyCsvReady: '1',
  isImportCompaniesCsvReady: '0',
  isImportCompanyAddressesCsvReady: '0',
  shouldCompanyListUpdate: false,
  companiesTemplateUrl: '',
  addressesTemplateUrl: '',
  extraFields: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_COMPANY_CSV_READY_STATUS:
      return {
        ...state,
        [action.payload.stateKey]: action.payload.status,
      }
    case TOGGLE_SHOULD_COMPANY_LIST_UPDATE:
      return {
        ...state,
        shouldCompanyListUpdate: action.payload.status,
      }
    case SETTING_COMPANIES_EXTRAFIELDS:
      return {
        ...state,
        extraFields: action.payload.status,
      }
    default:
      return state
  }
}
