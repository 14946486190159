import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  styled,
  CssBaseline,
  Paper,
  TextField,
  Button,
  Link,
} from '@material-ui/core'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, noop } from 'lodash'

import { re } from '../../constants'
import b3request from '../../service'
import { B3Spin, B3Snackbar } from '../../components'
import locales from '../../locales/en-US'
import { getUrlQueries, b3storage, snackbar } from '../../utils'
import { removeSnackbarMessage } from '../../store/actions/global'

const RootContainer = styled('div')((props) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: props.theme.palette.grey[200],
  justifyContent: 'center',
  alignItems: 'center',
}))

const MainContainer = styled(Paper)(({ theme }) => ({
  minWidth: 480,
  minHeight: 360,
  alignItems: 'center',
  padding: theme.spacing(4),
}))

const FormItem = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(2)}px 0`,
  textAlign: 'center',
}))

const LogoContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const registerFields = [{
  name: 'email',
  label: 'Email',
  initialValue: '',
  isRequired: true,
  type: 'text',
}, {
  name: 'username',
  label: 'Contact Name',
  initialValue: '',
  isRequired: false,
  type: 'text',
}, {
  name: 'phone',
  label: 'Phone',
  initialValue: '',
  isRequired: false,
  type: 'text',
}, {
  name: 'password',
  label: 'Create a Password',
  initialValue: '',
  isRequired: true,
  type: 'password',
}, {
  name: 'confirmedPassword',
  label: 'Confirm Password',
  initialValue: '',
  isRequired: true,
  type: 'password',
}]

const registerFieldsSchema = {
  email: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  phone: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber']),
  password: Yup.string()
    .max(16, locales['app.validation.errors.max.password'])
    .min(6, locales['app.validation.errors.min.password'])
    .required(locales['app.validation.errors.required.password']),
  confirmedPassword: Yup.string()
    .max(16, locales['app.validation.errors.max.password'])
    .min(6, locales['app.validation.errors.min.password'])
    .required(locales['app.validation.errors.required.confirmed.password'])
    .oneOf(
      [Yup.ref('password'), null],
      locales['app.validation.errors.password.not.match'],
    ),
}

class Register extends Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,

    removeSnackbarMessage: PropTypes.func,

    snackbarMessages: PropTypes.arrayOf(PropTypes.shape({
      variant: PropTypes.string.isRequired,
      message: PropTypes.node,
    })),
  }

  static defaultProps = {
    removeSnackbarMessage: noop,
    snackbarMessages: [],
  }

  constructor() {
    super()
    this.state = {
      initRegisterValues: {
        email: '',
        username: '',
        phone: '',
        password: '',
        confirmedPassword: '',
      },
    }
  }

  render() {
    const {
      initRegisterValues,
    } = this.state

    const {
      history,
      snackbarMessages,
      removeSnackbarMessage,
    } = this.props

    const handleBackToLogin = () => {
      history.goBack()
    }

    return (
      <>
        <CssBaseline />
        <RootContainer>
          <MainContainer
            elevation={10}
            square
          >
            <LogoContainer>
              <img src="https://www.bundleb2b.com/wp-content/uploads/2020/07/cropped-BundleB2B-Logo-1.png" alt="BundleB2B" />
            </LogoContainer>
            <Formik
              initialValues={initRegisterValues}
              validationSchema={Yup.object().shape(registerFieldsSchema)}
              onSubmit={async (fields, {
                setSubmitting,
                setTouched,
              }) => {
                const storeHash = getUrlQueries('storehash')
                try {
                  const registerInfo = await b3request.users.registerApp({
                    storeHash,
                    ...fields,
                  })
                  snackbar.success(locales['app.tips.users.signUp.success'])
                  setTouched({}, true)
                  if (registerInfo.authToken) {
                    b3storage.authToken.val = registerInfo.authToken
                    history.push('/')
                  }
                  if (registerInfo.permissionModules) {
                    b3storage.permissionModules.val = registerInfo.permissionModules
                  }
                } catch (error) {
                  snackbar.error(error.errMsg, 0)
                }
                setSubmitting(false)
              }}
            >
              {({
                values,
                errors,
                touched,
                setTouched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                dirty,
                status = {
                  errors: {},
                },
              }) => {
                const getFieldProps = (label, name, required, type) => ({
                  label,
                  name,
                  type,
                  value: values[name],
                  onChange: handleChange,
                  onBlur: handleBlur,
                  error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
                  helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
                  required,
                  fullWidth: true,
                })

                return (
                  <B3Spin
                    isSpinning={isSubmitting}
                  >
                    <FormItem>Sign up for your 14 days free trial</FormItem>
                    {
                      registerFields.map((field) => {
                        const fieldsProps = {
                          ...getFieldProps(
                            field.label,
                            field.name,
                            field.isRequired,
                            field.type,
                          ),
                        }
                        return (
                          <FormItem key={field.name}>
                            <TextField
                              {...fieldsProps}
                            />
                          </FormItem>
                        )
                      })
                    }
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!isEmpty(errors) || !dirty}
                    >
                      Sign Up
                    </Button>
                    <FormItem>
                      <Link onClick={() => {
                        handleBackToLogin()
                        setTouched({}, true)
                      }}
                      >
                        Back To Login
                      </Link>
                    </FormItem>
                  </B3Spin>
                )
              }}
            </Formik>
          </MainContainer>
        </RootContainer>
        <B3Snackbar
          messages={snackbarMessages}
          removeSnackbarMessage={removeSnackbarMessage}
        />
      </>
    )
  }
}

export default connect((state) => ({
  snackbarMessages: state.global.snackbarMessages,
}), {
  removeSnackbarMessage,
})(withRouter(Register))
