import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { noop } from 'lodash'

import {
  CssBaseline,
  Paper,
  styled,
} from '@material-ui/core'

import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import {
  B3Nav,
  Header,
  B3Snackbar,
  B3Socket,
  B3ExpiredNotification,
  B3RouteComponent,
} from './components'

import routes from './config/routes'

import {
  toggleNavCollapseGlobal,
  setPageTitle,
  removeSnackbarMessage,
} from './store/actions/global'

import {
  getStoreConfig,
} from './store/actions/settings'

import {
  getCompaniesExtraFields,
} from './store/actions/company'

import {
  checkPermissions,
  b3storage,
  env,
} from './utils'

import b3request from './service'

import './App.scss'

const RootContainer = styled('div')((props) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: props.theme.palette.grey[200],
}))

const MainContainer = styled('div')((props) => ({
  flex: 1,
  display: 'flex',

  '& .sidebar': {
    padding: `${props.theme.spacing()}px 0`,
    width: 240,
    transition: props.theme.transitions.create(['width'], {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
  },
  '& .sidebar-collapse': {
    width: 56,
    transition: props.theme.transitions.create(['width'], {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
  },
}))

const ContentSection = styled('div')((props) => ({
  flex: 1,
  padding: props.theme.spacing(2),
}))

class App extends Component {
  static propTypes = {
    isNavCollapse: PropTypes.bool,
    pageTitle: PropTypes.string,
    toggleNavCollapseGlobal: PropTypes.func,
    setPageTitle: PropTypes.func,
    removeSnackbarMessage: PropTypes.func,
    snackbarMessages: PropTypes.arrayOf(PropTypes.shape({
      variant: PropTypes.string.isRequired,
      message: PropTypes.node,
    })),
    isQtyEnabled: PropTypes.bool,
    isQuotesEnabled: PropTypes.bool,
    isOpenExpirationNotice: PropTypes.bool,
    isIntegrationEnabled: PropTypes.bool,
    isLoadingSettings: PropTypes.bool,
    getStoreConfig: PropTypes.func,
    getCompaniesExtraFields: PropTypes.func,
  }

  static defaultProps = {
    isNavCollapse: false,
    pageTitle: '',
    toggleNavCollapseGlobal: noop,
    removeSnackbarMessage: noop,
    setPageTitle: noop,
    snackbarMessages: [],
    isQtyEnabled: false,
    isQuotesEnabled: true,
    isOpenExpirationNotice: false,
    isIntegrationEnabled: false,
    isLoadingSettings: false,
    getStoreConfig: noop,
    getCompaniesExtraFields: noop,
  }

  async componentDidMount() {
    const {
      getStoreConfig,
      getCompaniesExtraFields,
    } = this.props
    getStoreConfig()
    getCompaniesExtraFields()
    await b3request.storeConfig.refreshStorePayments()

    const {
      timeFormat,
    } = await b3request.storeConfig.getDateFormat()
    b3storage.dateFormat.val = timeFormat || {}
  }

  get routes() {
    const {
      isQtyEnabled,
      isIntegrationEnabled,
      isQuotesEnabled,
    } = this.props
    const routePathIgnore = []
    if (!isQtyEnabled) routePathIgnore.push('/advanced-quantity-management')
    if (!isIntegrationEnabled) routePathIgnore.push('/integration')
    if (!isQuotesEnabled) routePathIgnore.push('/quotes')
    return routes.filter((route) => {
      const isAllowed = checkPermissions(route.permissions)
      return !routePathIgnore.includes(route.path) && isAllowed
    })
  }

  get menuItems() {
    return this.routes.filter((route) => route.isMenuItem === true)
  }

  render() {
    const {
      isNavCollapse,
      pageTitle,
      toggleNavCollapseGlobal,
      setPageTitle,
      snackbarMessages,
      removeSnackbarMessage,
      isLoadingSettings,
      isOpenExpirationNotice,
    } = this.props
    return (
      <>
        <RootContainer>
          { env.socketUrl && <B3Socket /> }
          <CssBaseline />
          <Header
            pageTitle={pageTitle}
            toggleNavCollapseGlobal={toggleNavCollapseGlobal}
          />
          <MainContainer>
            <Paper
              className={clsx(
                'sidebar',
                {
                  'sidebar-collapse': isNavCollapse,
                },
              )}
              square
            >
              <B3Nav
                menuItems={this.menuItems}
                isShowText={!isNavCollapse}
                isSpinning={isLoadingSettings}
              />
            </Paper>
            <ContentSection>
              <Switch>
                {
                  this.routes.map((route) => {
                    const {
                      path,
                      primary,
                      component,
                    } = route
                    return (
                      <Route
                        key={path}
                        path={path}
                        render={(props) => (
                          <B3RouteComponent
                            {...props}
                            setPageTitle={setPageTitle}
                            pageTitle={primary}
                            component={component}
                          />
                        )}
                        exact
                      />
                    )
                  })
                }
                <Redirect to="/dashboard" from="/" exact />
              </Switch>
            </ContentSection>
          </MainContainer>
          <B3Snackbar
            removeSnackbarMessage={removeSnackbarMessage}
            messages={snackbarMessages}
          />
        </RootContainer>
        {
          isOpenExpirationNotice && <B3ExpiredNotification />
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { switchStatus = [] } = state.settings?.storeConfig ?? {}

  const intervalQuantity = switchStatus.filter((status) => status.key === 'interval_quantity')[0] || {}
  const quotes = switchStatus.filter((status) => status.key === 'quotes')[0] || {}
  const openExpirationNotice = switchStatus.filter((status) => status.key === 'open_expiration_notice')[0] || {}
  const openIntegration = switchStatus.filter((status) => status.key === 'open_integration')[0] || {}
  return ({
    pageTitle: state.global.pageTitle,
    isNavCollapse: state.global.isNavCollapse,
    setPageTitle: state.global.setPageTitle,
    snackbarMessages: state.global.snackbarMessages,
    isQtyEnabled: intervalQuantity.isEnabled === '1',
    isQuotesEnabled: quotes.isEnabled === '1',
    isIntegrationEnabled: openIntegration.isEnabled === '1',
    isLoadingSettings: state.settings.isLoadingSettings,
    isOpenExpirationNotice: openExpirationNotice.isEnabled === '1',
  })
}

const mapDispatchToProps = {
  toggleNavCollapseGlobal,
  setPageTitle,
  removeSnackbarMessage,
  getStoreConfig,
  getCompaniesExtraFields,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
