const companyStatusText = {
  0: 'PENDING',
  1: 'APPROVED',
  2: 'REJECTED',
  3: 'INACTIVE',
  4: 'DELETED',
}

const companyStatusColor = {
  0: 'warning',
  1: 'success',
  2: 'error',
  3: 'grey',
  4: 'info',
}

export default (code) => ({
  text: companyStatusText[code],
  color: companyStatusColor[code],
})

export const COMPANYSTATUS = Object.entries(companyStatusText).reduce((companyStatus, [key, value]) => ({
  ...companyStatus,
  [value]: key,
}), {})
