import {
  SETTINGS_GET_STORE_CONFIG_SUCCESS,
  SETTINGS_TOGGLE_CONFIG_LOADING_STATUS,
} from '../actionTypes'

import b3request from '../../service'

import { snackbar } from '../../utils'

import locales from '../../locales/en-US'

const toggleSettingsConfigLoading = (isLoadingSettings = false) => ({
  type: SETTINGS_TOGGLE_CONFIG_LOADING_STATUS,
  payload: {
    isLoadingSettings,
  },
})

export const updateStoreConfig = (storeConfig) => ({
  type: SETTINGS_GET_STORE_CONFIG_SUCCESS,
  payload: {
    storeConfig,
  },
})

export const getStoreConfig = () => async (dispatch) => {
  dispatch(toggleSettingsConfigLoading(true))
  try {
    const resp = await b3request.storeConfig.getStoreConfigs()
    dispatch(updateStoreConfig(resp))
  } catch (error) {
    snackbar.error('Unknown Error!')
  } finally {
    dispatch(toggleSettingsConfigLoading(false))
  }
}

export const handleSwitchStatusChange = (status) => async (dispatch) => {
  dispatch(toggleSettingsConfigLoading(true))
  try {
    await b3request.storeConfig.updateStoreConfigSwitchStatusById(status.id, status.isEnabled === '1' ? '0' : '1')
    snackbar.success(locales['app.tips.settings.updateStoreConfigSuccessfully'])
    dispatch(getStoreConfig())
  } catch (error) {
    snackbar.error(locales['app.tips.settings.updateStoreConfigFailed'])
    dispatch(toggleSettingsConfigLoading(false))
  }
}

export const updateNotificationEmail = (notificationsEmailTo) => async (dispatch) => {
  dispatch(toggleSettingsConfigLoading(true))
  try {
    await b3request.storeConfig.updateStoreConfigNotificationEmail(notificationsEmailTo)
    snackbar.success(locales['app.tips.settings.updateStoreConfigSuccessfully'])
    dispatch(getStoreConfig())
  } catch (error) {
    snackbar.error(locales['app.tips.settings.updateStoreConfigFailed'])
    dispatch(toggleSettingsConfigLoading(false))
  }
}

export const updateDefaultPriceList = (priceListId) => async (dispatch) => {
  dispatch(toggleSettingsConfigLoading(true))
  try {
    await b3request.storeConfig.updateDefaultPriceList(priceListId)
    snackbar.success(locales['app.tips.settings.updateStoreConfigSuccessfully'])
    dispatch(getStoreConfig())
  } catch (error) {
    snackbar.error(locales['app.tips.settings.updateStoreConfigFailed'])
    dispatch(toggleSettingsConfigLoading(false))
  }
}

export const updateDefaultPaymentMethods = (paymentCodes) => async (dispatch) => {
  dispatch(toggleSettingsConfigLoading(true))
  try {
    await b3request.storeConfig.updateDefaultPaymentMethods(paymentCodes)
    snackbar.success(locales['app.tips.settings.updateStoreConfigSuccessfully'])
    dispatch(getStoreConfig())
  } catch (error) {
    snackbar.error(locales['app.tips.settings.updateStoreConfigFailed'])
    dispatch(toggleSettingsConfigLoading(false))
  }
}
