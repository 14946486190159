import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isEqual } from 'lodash'
import { withRouter } from 'react-router-dom'
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  ButtonGroup,
  Button,
} from '@material-ui/core'

import b3request from '../../service'

import {
  B3Spin,
} from '../../components'

import { snackbar } from '../../utils'

import locales from '../../locales/en-US'

class PaymentsMethods extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    getSummary: PropTypes.func.isRequired,
  }

  initPaymentsMethods = []

  constructor() {
    super()
    this.state = {
      paymentsMethods: [],
      isEditing: false,
      isLoading: false,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    this.getPaymentMethodsByCompanyId()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  getPaymentMethodsByCompanyId = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      match,
    } = this.props
    try {
      const paymentsMethods = await b3request.companies.getPaymentMethodsByCompanyId(match.params.id)
      this.initPaymentsMethods = cloneDeep(paymentsMethods)
      this.setState({
        paymentsMethods,
      })
    } catch (error) {
      snackbar.error(error.message)
    }
    this.setState({
      isLoading: false,
    })
  }

  handleEditClick = () => {
    this.setState({
      isEditing: true,
    })
  }

  handleCancelClick = () => {
    this.setState({
      isEditing: false,
      paymentsMethods: cloneDeep(this.initPaymentsMethods),
    })
  }

  handleSubmit = async () => {
    this.setState({
      isSubmitting: true,
    })
    const {
      paymentsMethods,
    } = this.state
    const {
      match,
    } = this.props
    try {
      await b3request.companies.updatePaymentMethodsByCompanyId(match.params.id, {
        payments: paymentsMethods,
      })
      snackbar.success(locales['app.tips.paymentMethods.updatePaymentMethodsSuccessfully'])
      this.initPaymentsMethods = cloneDeep(paymentsMethods)
      const {
        getSummary,
      } = this.props
      getSummary()
    } catch (error) {
      snackbar.error(locales['app.tips.paymentMethods.updatePaymentMethodsFailed'])
    }
    this.setState({
      isSubmitting: false,
      isEditing: false,
    })
  }

  handleCheckboxChange = (paymentId) => () => {
    const {
      paymentsMethods,
    } = this.state
    this.setState({
      paymentsMethods: paymentsMethods.map((paymentsMethod) => {
        if (paymentsMethod.paymentId === paymentId) {
          paymentsMethod.isEnabled = paymentsMethod.isEnabled === '0' ? '1' : '0'
        }
        return paymentsMethod
      }),
    })
  }

  render() {
    const {
      paymentsMethods,
      isEditing,
      isLoading,
      isSubmitting,
    } = this.state
    return isLoading ? <B3Spin /> : (
      <B3Spin isSpinning={isSubmitting || isLoading}>
        <FormGroup>
          {
            paymentsMethods.map((paymentsMethod) => (
              <FormControlLabel
                key={paymentsMethod.paymentId}
                control={(
                  <Checkbox
                    checked={paymentsMethod.isEnabled === '1'}
                    onChange={this.handleCheckboxChange(paymentsMethod.paymentId)}
                  />
                )}
                // hard code `cheque` as `PO`
                label={paymentsMethod.code === 'cheque' ? 'PO(PO)' : `${paymentsMethod.name}(${paymentsMethod.code})`}
                disabled={!isEditing}
              />
            ))
          }
        </FormGroup>
        {
          isEditing ? (
            <ButtonGroup>
              <Button
                onClick={this.handleCancelClick}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={isSubmitting || (isEqual(this.initPaymentsMethods, paymentsMethods))}
              >
                Save
              </Button>
            </ButtonGroup>
          ) : (
            <React.B3PermissionContainer
              permissions={['11302']}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleEditClick}
              >
                Edit
              </Button>
            </React.B3PermissionContainer>
          )
        }
      </B3Spin>
    )
  }
}

export default withRouter(PaymentsMethods)
