import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  styled,
  Typography,
} from '@material-ui/core'
import { B3Card } from '../../../components'
import locales from '../../../locales/en-US'

import EntitySetting from './EntitySetting'
import SyncStatus from './SyncStatus'

const tabs = [{
  id: 1,
  label: locales['acumatica.tabTitle.entitySetting'],
  component: EntitySetting,
}, {
  id: 2,
  label: locales['acumatica.tabTitle.syncStatus'],
  component: SyncStatus,
}]

const StyledTitle = styled(Typography)((props) => ({
  paddingLeft: props.theme.spacing(2),
  paddingRight: props.theme.spacing(2),
  paddingTop: props.theme.spacing(2),
  paddingBottom: props.theme.spacing(),
  fontWeight: props.theme.typography.fontWeightBold,
}))

const TabContent = styled('div')((props) => ({
  padding: props.theme.spacing(2),
}))

const TabComponent = (props) => {
  const {
    id,
    ...restProps
  } = props
  const {
    component: RenderComponent,
  } = tabs.filter((tab) => tab.id === id)[0]

  return (
    <TabContent>
      <RenderComponent {...restProps} />
    </TabContent>
  )
}

TabComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default class Acumatica extends Component {
  constructor() {
    super()
    this.state = {
      selectedTabValue: tabs[0].id,
    }
  }

  handleTabsChange = (e, selectedTabValue) => {
    this.setState({
      selectedTabValue,
    })
  }

  render() {
    const {
      selectedTabValue,
    } = this.state

    return (
      <>
        <StyledTitle>Acumatica</StyledTitle>
        <B3Card
          title={(
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              value={selectedTabValue}
              onChange={this.handleTabsChange}
            >
              {
                tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    value={tab.id}
                    label={tab.label}
                  />
                ))
              }
            </Tabs>
          )}
        >
          <TabComponent
            id={selectedTabValue}
          />
        </B3Card>
      </>
    )
  }
}
