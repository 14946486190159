import React from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  TablePagination,
  ButtonGroup,
  Button,
  withStyles,
  Typography,
} from '@material-ui/core'

import {
  NoData,
  B3Spin,
} from '../../components'

import { checkOneOfPermissions } from '../../utils'

function SalesRepTable(props) {
  const {
    list,
    isLoading,
    classes,
    pagination,
    orderBy,
    groupBy = '1',
    onChangeRowsPerPage,
    onChangePage,
    toggleOrderBy,
    onRemoveSalesRep,
    onEditSalesRep,
    toCompany,
    onShowSalesRepPopUp,
  } = props
  const hasData = Array.isArray(list) && list.length > 0
  const firstColumnHeaderTitle = groupBy === '1' ? 'Sales Rep Name' : 'Company Name'
  const secondColumnHeaderTitle = groupBy !== '1' ? 'Sales Rep(s)' : 'Company(ies)'
  return (
    <B3Spin
      isSpinning={isLoading}
      tip="loading…"
    >
      {
        hasData ? (
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active
                      direction={orderBy}
                      onClick={toggleOrderBy}
                    >
                      { firstColumnHeaderTitle }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    { secondColumnHeaderTitle }
                  </TableCell>
                  <TableCell>Email</TableCell>
                  {
                    checkOneOfPermissions([
                      ['13400'],
                      ['13100', '11108', '13300', '13301'],
                      ['11105'],
                    ]) && (
                      <TableCell style={{ width: '150px' }}>
                        Actions
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                {list.map((salesRep) => (
                  <TableRow
                    hover
                    key={salesRep.salesRepId || salesRep.companyId}
                  >
                    <TableCell>
                      <span className={classes.ellipsis}>
                        { salesRep.companyName || `${salesRep.firstName} ${salesRep.lastName}` }
                      </span>
                    </TableCell>
                    <TableCell>
                      <React.B3PermissionContainer
                        permissions={groupBy === '1' ? ['11108'] : ['13102']}
                        no={(
                          <Typography>
                            { salesRep.companyCount || salesRep.salesRepCount}
                          </Typography>
                        )}
                      >
                        <Button
                          onClick={onShowSalesRepPopUp(salesRep)}
                          disabled={salesRep.companyCount === '0' || salesRep.salesRepCount === '0'}
                          color="primary"
                          size="small"
                        >
                          { salesRep.companyCount || salesRep.salesRepCount}
                        </Button>
                      </React.B3PermissionContainer>

                    </TableCell>
                    <TableCell>
                      { salesRep.email || salesRep.companyEmail }
                    </TableCell>
                    {
                      checkOneOfPermissions([
                        ['13400'],
                        ['13100', '11108', '13300', '13301'],
                        ['11105'],
                      ]) && (
                        <TableCell>
                          {
                            groupBy === '1' && (
                              <ButtonGroup
                                size="small"
                              >
                                <React.B3PermissionContainer
                                  permissions={['13400']}
                                >
                                  {(props) => (
                                    <Button
                                      {...props}
                                      onClick={onRemoveSalesRep(salesRep.salesRepId)}
                                      color="secondary"
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </React.B3PermissionContainer>
                                <React.B3PermissionContainer
                                  permissions={['13100', '11108', '13300', '13301']}
                                >
                                  {(props) => (
                                    <Button
                                      {...props}
                                      onClick={onEditSalesRep(salesRep.salesRepId)}
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </React.B3PermissionContainer>

                              </ButtonGroup>
                            )
                          }
                          {
                            groupBy === '2' && (
                              <React.B3PermissionContainer
                                permissions={['11105']}
                              >
                                <Button
                                  onClick={toCompany(salesRep.companyId)}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                >
                                  Edit Company
                                </Button>
                              </React.B3PermissionContainer>
                            )
                          }
                        </TableCell>
                      )
                    }

                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={pagination.totalCount}
              rowsPerPage={pagination.limit}
              page={pagination.offset / pagination.limit}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </>
        ) : <NoData />
      }
    </B3Spin>
  )
}

SalesRepTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  isLoading: PropTypes.bool,
  classes: PropTypes.shape({
    table: PropTypes.string.isRequired,
    ellipsis: PropTypes.string.isRequired,
    editIconButton: PropTypes.string.isRequired,
  }).isRequired,
  orderBy: PropTypes.oneOf(['desc', 'asc']).isRequired,
  groupBy: PropTypes.oneOf(['1', '2']).isRequired,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  toggleOrderBy: PropTypes.func.isRequired,
  onRemoveSalesRep: PropTypes.func.isRequired,
  onEditSalesRep: PropTypes.func.isRequired,
  toCompany: PropTypes.func.isRequired,
  onShowSalesRepPopUp: PropTypes.func.isRequired,
}

SalesRepTable.defaultProps = {
  isLoading: false,
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: 'calc(100% - 40px)',
  },
  editIconButton: {
    padding: 4,
    marginLeft: 4,
    display: 'inline-block',
  },
}
export default withStyles(styles)(SalesRepTable)
