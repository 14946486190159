import {
  TOGGLE_SALES_REP_CSV_READY_STATUS,
  TOGGLE_SHOULD_S_REP_TABLE_UPDATE,
} from '../actionTypes'

export const toggleSalesRepCsvReadyStatus = ({
  stateKey = 'isImportSalesRepCsvReady',
  status = '0',
}) => ({
  type: TOGGLE_SALES_REP_CSV_READY_STATUS,
  payload: {
    stateKey,
    status,
  },
})

export const toggleShouldSalesRepListUpdate = (status) => ({
  type: TOGGLE_SHOULD_S_REP_TABLE_UPDATE,
  payload: {
    status,
  },
})
