import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  Link,
  withStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  DialogContentText,
  DialogContent,
} from '@material-ui/core'

import { MoreVert as MoreIcon } from '@material-ui/icons'

import {
  B3Card,
  B3Tag,
  B3TableCell as TableCell,
  B3Filter,
  NoData,
  B3ConfirmDialog,
} from '../../components'

import { quotesStatusText } from '../../utils/getQuotesStatusByCode'

import b3request from '../../service'

import {
  getQuotesStatusByCode,
  snackbar,
  currencyFormat,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

class Quotes extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      ellipsis: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      quotesStatusText,
      filters: {
        referenceNumber: '',
        company: '',
        salesRep: '',
        status: [],
      },
      quotes: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      sortBy: 'createdAt',
      orderBy: 'desc',
      isLoading: false,
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      currentQuote: {},
      updateQuoteStatusTo: '',
      isUpdateStatusConfirmShow: false,
      isUpdatingQuoteStatus: false,
    }
  }

  componentDidMount() {
    this.getQuotes()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: { offset, limit },
    } = this.state
    return offset / limit
  }

  get quoteUpdateStatusConfirmationText() {
    const { updateQuoteStatusTo, currentQuote } = this.state
    const actionTexts = {
      7: 'reject',
      0: 'approve',
    }
    return `Are you sure you want to ${actionTexts[updateQuoteStatusTo]} quote: ${currentQuote.referenceNumber}`
  }

  handleFilterChange = (filters) => {
    this.setState({
      filters,
    })
  }

  handleFilterCancle = () => {
    this.setState(
      {
        filters: {
          referenceNumber: '',
          company: '',
          salesRep: '',
          status: [],
        },
      },
      this.handleFilterApply,
    )
  }

  handleFilterApply = () => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: 0,
        },
      },
      this.getQuotes,
    )
  }

  getQuotes = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: { offset, limit },
      sortBy,
      orderBy,
      filters,
    } = this.state
    try {
      const resp = await b3request.quotes.getQuotes({
        offset,
        limit,
        sortBy,
        orderBy,
        ...filters,
      })

      this.setState({
        quotes: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: page * pagination.limit,
        },
      },
      this.getQuotes,
    )
  }

  handleRowsPerPageChange = (event) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: 0,
          limit: event.target.value,
        },
      },
      this.getQuotes,
    )
  }

  handleSort = (sortFiled) => () => {
    const { orderBy, sortBy } = this.state
    const inverseDirection = {
      desc: 'asc',
      asc: 'desc',
    }
    const direction = sortFiled === sortBy ? inverseDirection[orderBy] : orderBy
    this.setState(
      {
        sortBy: sortFiled,
        orderBy: direction,
      },
      this.getQuotes,
    )
  }

  toQuote = (quoteId) => () => {
    const { history } = this.props
    history.push(`/quotes/${quoteId}`)
  }

  toOrder = (url) => () => {
    window.open(url, '_blank')
  }

  handleMoreActionsClick = (actionsRowId) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
    })
  }

  handleStatusConfirmClose = () => {
    const { isUpdatingQuoteStatus } = this.state
    if (!isUpdatingQuoteStatus) {
      this.setState({
        isUpdateStatusConfirmShow: false,
      })
    }
  }

  handleQuoteStatusUpdate = async () => {
    const {
      currentQuote: {
        quoteId,
      },
      updateQuoteStatusTo: status,
    } = this.state
    if (!quoteId) return
    this.setState({
      isUpdatingQuoteStatus: true,
    })
    const { quoteId: updatedQuoteId } = await b3request.quotes.updateQuoteStatusById(quoteId, { status })
    this.setState({
      isUpdatingQuoteStatus: false,
      isUpdateStatusConfirmShow: false,
    })
    if (updatedQuoteId) this.getQuotes()
  }

  showQuoteUpdateStatusConfirmation = (updateQuoteStatusTo, currentQuote) => () => {
    this.setState({
      isUpdateStatusConfirmShow: true,
      updateQuoteStatusTo,
      currentQuote,
    })
    this.handleMoreActionsPopoverClose()
  }

  render() {
    const { classes } = this.props

    const {
      quotes,
      filters,
      quotesStatusText,
      isLoading,
      pagination,
      sortBy,
      orderBy,
      actionsPopoverAnchorEl,
      actionsRowId,
      isUpdateStatusConfirmShow,
      isUpdatingQuoteStatus,
    } = this.state
    return (
      <>
        <B3Card
          isLoading={isLoading}
          title={(
            <B3Filter
              statusFilters={quotesStatusText}
              filters={filters}
              onChange={this.handleFilterChange}
              onCancel={this.handleFilterCancle}
              onApply={this.handleFilterApply}
            />
          )}
        >
          {quotes.length === 0 ? (
            <NoData />
          ) : (
            <>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Reference Number</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Sales Rep</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === 'createdAt'}
                        direction={orderBy}
                        onClick={this.handleSort('createdAt')}
                      >
                        Created at
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === 'grandTotal'}
                        direction={orderBy}
                        onClick={this.handleSort('grandTotal')}
                      >
                        Total
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell style={{ width: 60 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {quotes.map((quote) => (
                    <TableRow hover key={quote.quoteId}>
                      <TableCell>
                        <React.B3PermissionContainer
                          permissions={['12101']}
                          no={(
                            <Typography variant="body1">
                              {quote.referenceNumber}
                            </Typography>
                          )}
                        >
                          <span className={classes.ellipsis}>
                            <Link onClick={this.toQuote(quote.quoteId)}>
                              {quote.referenceNumber}
                            </Link>
                          </span>
                        </React.B3PermissionContainer>
                      </TableCell>
                      <TableCell>
                        <span className={classes.ellipsis}>
                          {quote.companyName}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={classes.ellipsis}>{quote.salesRep}</span>
                      </TableCell>
                      <TableCell>
                        {b3DateFormat.displayFormat(quote.createdAt)}
                      </TableCell>
                      <TableCell>
                        {currencyFormat(quote.totalPrice, quote.money)}
                      </TableCell>
                      <TableCell>
                        <B3Tag color={getQuotesStatusByCode(quote.status).color}>
                          {getQuotesStatusByCode(quote.status).text}
                        </B3Tag>
                      </TableCell>
                      <TableCell>
                        {
                          (quote.status === '2' && quote.backendOrderUrl) && (
                            <>
                              <IconButton
                                onClick={this.handleMoreActionsClick(quote.quoteId)}
                              >
                                <MoreIcon />
                              </IconButton>
                              <Menu
                                anchorEl={actionsPopoverAnchorEl}
                                open={actionsRowId === quote.quoteId}
                                onClose={this.handleMoreActionsPopoverClose}
                              >
                                <MenuItem onClick={this.toOrder(quote.backendOrderUrl)}>
                                  View Order
                                </MenuItem>
                              </Menu>
                            </>
                          )
                        }
                        {
                          (quote.status === '6') && (
                            <React.B3PermissionContainer
                              permissions={['12300']}
                            >
                              <IconButton
                                onClick={this.handleMoreActionsClick(quote.quoteId)}
                              >
                                <MoreIcon />
                              </IconButton>
                              <Menu
                                anchorEl={actionsPopoverAnchorEl}
                                open={actionsRowId === quote.quoteId}
                                onClose={this.handleMoreActionsPopoverClose}
                              >
                                <MenuItem
                                  onClick={this.showQuoteUpdateStatusConfirmation('7', quote)}
                                >
                                  Reject
                                </MenuItem>
                                <MenuItem
                                  onClick={this.showQuoteUpdateStatusConfirmation('0', quote)}
                                >
                                  Approve
                                </MenuItem>
                              </Menu>
                            </React.B3PermissionContainer>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={pagination.totalCount}
                rowsPerPage={pagination.limit}
                page={this.currentPage}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
              />
            </>
          )}
          <B3ConfirmDialog
            isOpen={isUpdateStatusConfirmShow}
            onClose={this.handleStatusConfirmClose}
            onConfirm={this.handleQuoteStatusUpdate}
            confirmText="Yes"
            cancelText="No"
            isCancelDisabled={isUpdatingQuoteStatus}
            isSubmitDisabled={isUpdatingQuoteStatus}
            isSpinning={isUpdatingQuoteStatus}
          >
            <DialogContent>
              <DialogContentText>
                {this.quoteUpdateStatusConfirmationText}
              </DialogContentText>
            </DialogContent>
          </B3ConfirmDialog>
        </B3Card>
      </>
    )
  }
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: 'calc(100% - 40px)',
  },
}

export default withStyles(styles)(Quotes)
