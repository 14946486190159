import b3request from './base'

// get acumatica switches
export const getAcumaticaSwitches = (params) => b3request.get('/api/v2/integrations/acumatica/switches', { params })

// update acumatica switches
export const updateAcumaticSwitch = (params) => b3request.put('/api/v2/integrations/acumatica/switches', params)

// get acumatica logs
export const getAcumaticaLogs = (params) => b3request.get('/api/v2/integrations/acumatica/logs', { params })
