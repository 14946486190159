import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  Link,
  Badge,
  TablePagination,
  styled,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core'

import {
  B3Card,
  NoData,
} from '../../components'

import {
  snackbar,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

import b3request from '../../service'

const NotificationItemWrapper = styled('p')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
})

const NotificationTitleWrapper = styled('span')({
  flex: 1,
})

class Notifications extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      notifications: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      filter: {
        isRead: '',
      },
    }
  }

  componentDidMount() {
    this.getNotifications()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  getNotifications = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      filter: {
        isRead,
      },
      pagination,
      pagination: {
        offset,
        limit,
      },
    } = this.state
    try {
      const resp = await b3request.dashboard.getNotifications({
        isRead,
        offset,
        limit,
      })

      this.setState({
        notifications: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getNotifications)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getNotifications)
  }

  handleReadChange = (event) => {
    const {
      filter,
      pagination,
    } = this.state
    this.setState({
      filter: {
        ...filter,
        isRead: event.target.value,
      },
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getNotifications)
  }

  toNotificationDetail = (id) => () => {
    const {
      history,
    } = this.props
    history.push(`/notifications/${id}`)
  }

  render() {
    const {
      isLoading,
      notifications,
      pagination,
      filter,
    } = this.state
    return (
      <div style={{
        margin: '16px 0',
      }}
      >
        <B3Card
          isLoading={isLoading}
          title="Notifications"
          extra={(
            <RadioGroup
              row
              name="filter"
              value={filter.isRead}
              size="small"
              onChange={this.handleReadChange}
            >
              <FormControlLabel value="" control={<Radio />} label="All" />
              <FormControlLabel value="0" control={<Radio />} label="Unread" />
            </RadioGroup>
          )}
        >
          <div>
            {
              notifications.length === 0 && <NoData />
            }
            {
              notifications.map((notificationItem) => {
                const badgeProps = {}
                if (notificationItem.isRead === '0') {
                  badgeProps.color = 'error'
                  badgeProps.variant = 'dot'
                }
                return (
                  <Fragment key={notificationItem.id}>
                    <NotificationItemWrapper>
                      <NotificationTitleWrapper>
                        <React.B3PermissionContainer
                          permissions={['10001', '10002']}
                          no={(
                            <Typography
                              component="span"
                              variant="body2"
                            >
                              {notificationItem.title}
                            </Typography>
                          )}
                        >
                          <Link
                            onClick={this.toNotificationDetail(notificationItem.id)}
                          >
                            <Badge {...badgeProps}>
                              {notificationItem.title}
                            </Badge>
                          </Link>
                        </React.B3PermissionContainer>
                      </NotificationTitleWrapper>
                      <span>
                        {b3DateFormat.extendedDisplayFormat(notificationItem.createdAt)}
                      </span>
                    </NotificationItemWrapper>
                    <Divider />
                  </Fragment>
                )
              })
            }
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={pagination.totalCount}
              rowsPerPage={pagination.limit}
              page={this.currentPage}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
            />
          </div>
        </B3Card>
      </div>
    )
  }
}

export default withRouter(Notifications)
