import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  DialogContent,
  DialogContentText,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'

import { B3ConfirmDialog } from '../../components'

function DeleteCompanyConfirm(props) {
  const [deleteCustomerGroup, setDeleteCustomerGroup] = useState('1')
  const {
    isOpen,
    onConfirm,
    companyName,
    isDeleting,
  } = props
  const handleDialogClose = () => {
    // can't make the dialog close if ajax is calling.
    if (isDeleting) return false
    return props.onClose()
  }

  const hanleDeleteCustomerGroupChange = (e) => {
    setDeleteCustomerGroup(e.target.value)
  }

  const handleConfrim = () => {
    onConfirm(deleteCustomerGroup)
  }

  return (
    <B3ConfirmDialog
      isOpen={isOpen}
      title={`Choose delete option for company : ${companyName} ?`}
      onClose={handleDialogClose}
      onConfirm={handleConfrim}
      confirmText="Delete"
      isCancelDisabled={isDeleting}
      isSubmitDisabled={isDeleting}
      isSpinning={isDeleting}
    >
      <DialogContent>
        <DialogContentText>
          There are some sharing data between BundleB2B Companies and the assigned BigCommerce Customer Groups, Do you want to delete the assigned BigCommerce Customer Groups by the mean time?
        </DialogContentText>
        <FormControl>
          <RadioGroup
            value={deleteCustomerGroup}
            onChange={hanleDeleteCustomerGroupChange}
          >
            <React.B3PermissionContainer permissions={['11300']}>
              <FormControlLabel
                value="0"
                control={<Radio />}
                disabled={isDeleting}
                label="No, delete BundleB2B Company only."
              />
            </React.B3PermissionContainer>
            <FormControlLabel
              value="1"
              control={<Radio />}
              disabled={isDeleting}
              label="Yes, delete both BundleB2B Company and assigned BigCommmerce Customer Group."
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </B3ConfirmDialog>
  )
}

DeleteCompanyConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
}

export default DeleteCompanyConfirm
