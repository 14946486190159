import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  styled,
  CssBaseline,
  Paper,
  Grid,
  TextField,
  Button,
  Link,
} from '@material-ui/core'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, noop } from 'lodash'

import { B3Spin, B3Snackbar } from '../../components'
import locales from '../../locales/en-US'
import { re } from '../../constants'
import b3request from '../../service'
import { getUrlQueries, b3storage, snackbar } from '../../utils'
import { removeSnackbarMessage } from '../../store/actions/global'

const RootContainer = styled('div')((props) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: props.theme.palette.grey[200],
  justifyContent: 'center',
  alignItems: 'center',
}))

const MainContainer = styled(Grid)(() => ({
  minWidth: 680,
  minHeight: 360,
}))

const Left = styled(Grid)(() => ({
  backgroundImage: 'url(https://www.bundleb2b.com/wp-content/uploads/2020/01/Capture.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}))

const Right = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
}))

const FormItem = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(2)}px 0`,
  width: 280,
  textAlign: 'center',
}))

const LogoContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  padingRight: theme.spacing(4),
  padingLeft: theme.spacing(4),
}))

const loginFields = [{
  name: 'email',
  label: 'Email',
  initialValue: '',
  isRequired: true,
  type: 'text',
}, {
  name: 'password',
  label: 'Password',
  initialValue: '',
  isRequired: true,
  type: 'password',
}]

const loginFieldsSchema = {
  email: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  password: Yup.string()
    .max(16, locales['app.validation.errors.max.password'])
    .min(6, locales['app.validation.errors.min.password'])
    .required(locales['app.validation.errors.required.password']),
}

class Login extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    removeSnackbarMessage: PropTypes.func,

    snackbarMessages: PropTypes.arrayOf(PropTypes.shape({
      variant: PropTypes.string.isRequired,
      message: PropTypes.node,
    })),
  }

  static defaultProps = {
    removeSnackbarMessage: noop,
    snackbarMessages: [],
  }

  constructor() {
    super()
    this.state = {
      initLoginValues: {
        email: '',
        password: '',
      },
      /**
       * '0': login,
       * '1': reset password
       */
      mode: '0',
    }
  }

  get fields() {
    const { mode } = this.state
    if (mode === '0') return loginFields
    return [loginFields[0]]
  }

  get schemas() {
    const { mode } = this.state
    if (mode === '0') return loginFieldsSchema
    const { email } = loginFieldsSchema
    return {
      email,
    }
  }

  get modeChangeText() {
    const { mode } = this.state
    const text = {
      0: 'Reset Password',
      1: 'Back to Login',
    }
    return text[mode]
  }

  get submitText() {
    const { mode } = this.state
    const text = {
      0: 'Login',
      1: 'Reset Password',
    }
    return text[mode]
  }

  handleModeChange = () => {
    const { mode: oldMode } = this.state
    const mode = oldMode === '0' ? '1' : '0'
    this.setState({ mode })
  }

  render() {
    const {
      initLoginValues,
      mode,
    } = this.state

    const {
      history,
      snackbarMessages,
      removeSnackbarMessage,
    } = this.props

    // const toRegister = () => {
    //   history.push('/register')
    // }

    return (
      <>
        <CssBaseline />
        <RootContainer>
          <Paper
            elevation={10}
            square
          >
            <MainContainer container>
              <Left
                xs={6}
                item
              />
              <Right
                xs={6}
                item
              >
                <LogoContainer>
                  <img src="https://www.bundleb2b.com/wp-content/uploads/2020/07/cropped-BundleB2B-Logo-1.png" alt="BundleB2B" />
                </LogoContainer>
                <Formik
                  initialValues={initLoginValues}
                  validationSchema={Yup.object().shape(this.schemas)}
                  onSubmit={async (fields, {
                    setSubmitting,
                    setTouched,
                  }) => {
                    const storeHash = getUrlQueries('storehash')
                    try {
                      if (mode === '0') {
                        const loginInfo = await b3request.users.loginApp({
                          storeHash,
                          ...fields,
                        })
                        if (loginInfo.authToken) {
                          b3storage.authToken.val = loginInfo.authToken
                          history.push('/')
                        }
                        if (loginInfo.permissionModules) {
                          b3storage.permissionModules.val = loginInfo.permissionModules
                        }
                      } else {
                        await b3request.users.forgetPassword({
                          storeHash,
                          ...fields,
                        })
                        snackbar.success(locales['app.tips.users.forgetPassword.sendEmail.success'])
                        this.handleModeChange()
                        setTouched({}, true)
                      }
                    } catch {
                      //
                    }
                    setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    dirty,
                    status = {
                      errors: {},
                    },
                  }) => {
                    const getFieldProps = (label, name, required, type) => ({
                      label,
                      name,
                      type,
                      value: values[name],
                      onChange: handleChange,
                      onBlur: handleBlur,
                      error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
                      helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
                      required,
                      fullWidth: true,
                    })

                    return (
                      <B3Spin
                        isSpinning={isSubmitting}
                      >
                        {mode === '1' && (
                          <FormItem>Enter your email and we will send you a link to reset your password</FormItem>
                        )}
                        {
                          this.fields.map((field) => {
                            const fieldsProps = {
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                                field.type,
                              ),
                            }
                            return (
                              <FormItem key={field.name}>
                                <TextField
                                  {...fieldsProps}
                                />
                              </FormItem>
                            )
                          })
                        }
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={!isEmpty(errors) || !dirty}
                        >
                          {this.submitText}
                        </Button>
                        <FormItem>
                          <Link onClick={() => {
                            this.handleModeChange()
                            setTouched({}, true)
                          }}
                          >
                            {this.modeChangeText}
                          </Link>
                        </FormItem>
                        {/* <FormItem>
                          No Account?
                          <Link onClick={() => {
                            toRegister()
                            setTouched({}, true)
                          }}
                          >
                            Sign up
                          </Link>
                        </FormItem> */}
                      </B3Spin>
                    )
                  }}
                </Formik>
              </Right>
            </MainContainer>
          </Paper>
        </RootContainer>
        <B3Snackbar
          removeSnackbarMessage={removeSnackbarMessage}
          messages={snackbarMessages}
        />
      </>
    )
  }
}

export default connect((state) => ({
  snackbarMessages: state.global.snackbarMessages,
}), {
  removeSnackbarMessage,
})(withRouter(Login))
