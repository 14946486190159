import b3request from './base'

// backend create role
export const createRole = (params) => b3request.post('/api/v2/backend/role', params)

// backend delete role
export const deleteRole = (id) => b3request.delete(`/api/v2/backend/roles/${id}`)

// backend update role
export const updateRole = (params, id) => b3request.put(`/api/v2/backend/roles/${id}`, params)

// backend get roles
export const getRoles = (params) => b3request.get('/api/v2/backend/roles', { params })

// backend get role
export const getRole = (id) => b3request.get(`/api/v2/backend/roles/${id}`)

export const getPermissions = () => b3request.get('/api/v2/backend/permissions')
