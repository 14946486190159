import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Tab,
  Tabs,
  styled,
  ButtonGroup,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import {
  B3Card,
  B3Back,
  B3Spin,
  B3Tag,
  B3ConfirmDialog,
} from '../../components'

import b3request from '../../service'

import Summary from './Summary'

import {
  getCompanyStatusByCode,
  checkPermissions,
  getUrlQueries,
  COMPANYSTATUS,
} from '../../utils'

import BasicInformation from './BasicInformation'
import CompanyUsers from './CompanyUsers'
import SalesRepAssignments from './SalesRepAssignments'
import PaymentsMethods from './PaymentsMethods'
import AddressBook from './AddressBook'

const tabs = [{
  id: 1,
  label: 'Basic Information',
  component: BasicInformation,
  permissions: ['11101'],
}, {
  id: 2,
  label: 'Company User(s)',
  component: CompanyUsers,
  permissions: ['11102'],
}, {
  id: 3,
  label: 'Sales Rep(s) Assignments',
  component: SalesRepAssignments,
  permissions: ['13102'],
}, {
  id: 4,
  label: 'Payment Methods',
  component: PaymentsMethods,
  permissions: ['11104'],
}, {
  id: 5,
  label: 'Address Book',
  component: AddressBook,
  permissions: ['11111', '11112', '11110'],
}]

const TabContent = styled('div')((props) => ({
  padding: props.theme.spacing(2),
}))

const TabComponent = (props) => {
  const {
    id,
    ...restProps
  } = props
  const {
    component: RenderComponent,
  } = tabs.filter((tab) => tab.id === id)?.[0] ?? {}

  return (
    <TabContent>
      {RenderComponent ? <RenderComponent {...restProps} /> : null}
    </TabContent>
  )
}

TabComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Extra = (props) => {
  const {
    companyStatus,
    showCompanyStatusConfirm,
  } = props
  if (companyStatus === COMPANYSTATUS.PENDING) {
    return (
      <ButtonGroup
        size="small"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={showCompanyStatusConfirm(COMPANYSTATUS.APPROVED)}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={showCompanyStatusConfirm(COMPANYSTATUS.REJECTED)}
        >
          Reject
        </Button>
      </ButtonGroup>
    )
  }
  return null
}

Extra.propTypes = {
  companyStatus: PropTypes.oneOf(['', ...Object.values(COMPANYSTATUS)]).isRequired,
  showCompanyStatusConfirm: PropTypes.func.isRequired,
}

export class Company extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    isAddressBookEnabled: PropTypes.bool.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isGettingSummary: false,
      isGettingBasicInfo: false,
      isStatusConfirmShow: false,
      isUpdatingCompanyStatus: false,
      newCompanyStatus: '',
      selectedTabValue: '',
      basicInformation: {
        addressLine1: '',
        addressLine2: '',
        catalogId: '',
        catalogName: '',
        city: '',
        companyEmail: '',
        companyId: '',
        companyName: '',
        companyStatus: '',
        description: '',
        phoneNumber: '',
        state: '',
        updatedAt: '',
        zipCode: '',
        extraFields: [],
      },
      summary: [{
        name: 'users',
        label: 'User(s)',
        count: 0,
        permissions: ['11102'],
      }, {
        name: 'enabledPaymentMethods',
        label: 'Enabled Payment Methods',
        count: 0,
        permissions: ['11104'],
      }, {
        name: 'assignedSalesReps',
        label: 'Assigned Sales Reps',
        count: 0,
        permissions: ['13102'],
      }, {
        name: 'addresses',
        label: 'Addresses',
        count: 0,
        permissions: ['11111', '11112', '11110'],
      }],
    }
  }

  async componentDidMount() {
    this.initCompanyPage()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  get renderedTabs() {
    let renderedTabs = [...tabs]
    const { isAddressBookEnabled } = this.props
    switch (this.companyStatus) {
      case COMPANYSTATUS.INACTIVE: renderedTabs = renderedTabs.splice(1, 1)
        break
      case COMPANYSTATUS.PENDING: renderedTabs = renderedTabs.splice(0, 1)
        break
      default:
    }
    if (!isAddressBookEnabled) renderedTabs = renderedTabs.filter((tab) => tab.id !== 5)

    return renderedTabs.filter((tab) => checkPermissions(tab.permissions))
  }

  get renderedSummary() {
    const { isAddressBookEnabled } = this.props
    const { summary } = this.state
    if (isAddressBookEnabled) return summary
    return summary.filter((item) => item.name !== 'addresses')
  }

  get companyStatus() {
    const {
      basicInformation: {
        companyStatus,
      },
    } = this.state

    return companyStatus || this.getFixedBasicInfoByKey('companyStatus')
  }

  get companyName() {
    const {
      basicInformation: {
        companyName,
      },
    } = this.state

    return companyName || this.getFixedBasicInfoByKey('companyName')
  }

  initCompanyPage = async () => {
    const {
      summary,
    } = this.state

    await this.getSummary()
    if (checkPermissions(['11101'])) await this.getCompanyInfoById()
    this.setState({
      summary: summary.filter((summary) => checkPermissions(summary.permissions)),
      selectedTabValue: this.renderedTabs[0]?.id ?? '',
    })
  }

  getFixedBasicInfoByKey = (key) => {
    const {
      location: {
        search,
      },
    } = this.props

    return decodeURIComponent(getUrlQueries(key, search))
  }

  getSummary = async () => {
    this.setState({
      isGettingSummary: true,
    })

    const {
      match,
    } = this.props

    const {
      summary,
    } = this.state

    const resp = await b3request.companies.getCompanyStatsById(match.params.id)
    this.setState({
      isGettingSummary: false,
      summary: summary.map((summaryItem) => {
        const { count = 0 } = resp.filter((item) => item.name === summaryItem.name)[0] || {}
        summaryItem.count = count
        return summaryItem
      }),
    })
  }

  /**
   * get company basic infomation
   * @function getCompanyInfoById
   * @returns void
   */
  getCompanyInfoById = async () => {
    // show the loading mask and icon first
    this.setState({
      isGettingBasicInfo: true,
    })

    // need to get the company id from react router.
    const {
      match,
    } = this.props

    const {
      basicInformation,
    } = this.state

    // make request
    const resp = await b3request.companies.getCompanyBasicInfoById(match.params.id)
    this.setState({
      basicInformation: {
        ...basicInformation,
        ...resp,
      },
    })
    this.setState({
      isGettingBasicInfo: false,
    })
  }

  handleCompayCatalogIdChange = (catalogId) => {
    const {
      basicInformation,
    } = this.state
    this.setState({
      basicInformation: {
        ...basicInformation,
        catalogId,
      },
    })
  }

  handleTabsChange = (e, selectedTabValue) => {
    this.setState({
      selectedTabValue,
    })
  }

  showCompanyStatusConfirm = (newCompanyStatus) => () => {
    this.setState({
      newCompanyStatus,
      isStatusConfirmShow: true,
    })
  }

  hideCompanyStatusConfirm = () => {
    this.setState({
      newCompanyStatus: '',
      isStatusConfirmShow: false,
    })
  }

  handleCompanyStatusChange = async () => {
    this.setState({
      isUpdatingCompanyStatus: true,
    })
    const {
      basicInformation: {
        companyId,
      },
      newCompanyStatus: companyStatus,
    } = this.state
    await b3request.companies.updateCompanyBasicInfoByCompanyId(companyId, { companyStatus })
    this.setState({
      isUpdatingCompanyStatus: false,
      isStatusConfirmShow: false,
    }, this.getCompanyInfoById)
  }

  render() {
    const {
      match,
    } = this.props
    const {
      selectedTabValue,
      isGettingSummary,
      isGettingBasicInfo,
      basicInformation,
      isStatusConfirmShow,
      newCompanyStatus,
      isUpdatingCompanyStatus,
    } = this.state
    let tabComponentProps = {
      getSummary: this.getSummary,
      companyId: match.params.id,
    }
    switch (selectedTabValue) {
      case 1: {
        tabComponentProps = {
          isGettingBasicInfo,
          basicInformation,
          getCompanyInfoById: this.getCompanyInfoById,
          handleCompayCatalogIdChange: this.handleCompayCatalogIdChange,
        }
        break
      }
      case 2: {
        tabComponentProps = {
          ...tabComponentProps,
          initCompanyPage: this.initCompanyPage,
        }
        break
      }
      default:
    }

    return (
      <B3Card
        title={(
          <TitleWrapper>
            <B3Back title={(this.companyName)} />
            {
              this.companyStatus && (
                <B3Tag
                  color={getCompanyStatusByCode(this.companyStatus).color}
                >
                  {getCompanyStatusByCode(this.companyStatus).text}
                </B3Tag>
              )
            }
          </TitleWrapper>
        )}
        extra={(
          <Extra
            companyStatus={this.companyStatus}
            showCompanyStatusConfirm={this.showCompanyStatusConfirm}
          />
        )}
      >
        <B3Spin
          isSpinning={isGettingSummary}
          tip="loading…"
        >
          <Summary
            counters={this.renderedSummary}
          />
          <Tabs
            value={selectedTabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={this.handleTabsChange}
          >
            {
              selectedTabValue && this.renderedTabs.length && this.renderedTabs.map((tab) => (
                <Tab
                  key={tab.id}
                  value={tab.id}
                  label={tab.label}
                />
              ))
            }
          </Tabs>
        </B3Spin>
        <TabComponent
          id={selectedTabValue}
          {...tabComponentProps}
        />
        {
          isStatusConfirmShow && (
            <B3ConfirmDialog
              onClose={this.hideCompanyStatusConfirm}
              onConfirm={this.handleCompanyStatusChange}
              isSubmitDisabled={isUpdatingCompanyStatus}
              isCancelDisabled={isUpdatingCompanyStatus}
              isSpinning={isUpdatingCompanyStatus}
            >
              <DialogContent>
                <DialogContentText>
                  {
                    `Are you sure you want to ${newCompanyStatus === COMPANYSTATUS.APPROVED ? 'approve' : 'reject'} ${basicInformation.companyName}?`
                  }
                </DialogContentText>
              </DialogContent>
            </B3ConfirmDialog>
          )
        }
      </B3Card>
    )
  }
}

const mapStateToProp = (state) => {
  const { isEnabled } = state.settings.storeConfig.switchStatus.filter((status) => status.key === 'address_book')[0] || {}
  return ({
    isAddressBookEnabled: isEnabled === '1',
  })
}
export default connect(mapStateToProp)(Company)
