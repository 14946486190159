import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  Grid,
  makeStyles,
} from '@material-ui/core'

import { B3Spin } from '..'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'center',
  },
  title: {
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 700,
  },
  extra: {
    marginLeft: theme.spacing(),
  },
  content: {
    padding: theme.spacing(2),
  },
}))

function B3Card(props) {
  const classes = useStyles()
  const {
    children,
    title,
    isLoading,
    extra,
  } = props
  const isShowHeader = title || extra
  return (
    <Paper>
      <B3Spin
        isSpinning={isLoading}
        tip="loading…"
      >
        {
          isShowHeader && (
            <Grid
              container
              className={classes.header}
            >
              <Grid
                item
                className={classes.title}
              >
                {title}
              </Grid>
              <Grid
                item
                className={classes.extra}
              >
                {
                  typeof extra === 'function' ? extra() : extra
                }
              </Grid>
            </Grid>
          )
        }
        <Grid
          container
        >
          <Grid
            item
            xs={12}
            className={classes.content}
          >
            {children}
          </Grid>
        </Grid>
      </B3Spin>
    </Paper>
  )
}

B3Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  isLoading: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

B3Card.defaultProps = {
  children: null,
  title: null,
  isLoading: false,
  extra: null,
}

export default B3Card
