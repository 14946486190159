import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { styled } from '@material-ui/core'

import {
  B3Card,
  B3Back,
} from '../../components'

import b3request from '../../service'
import { snackbar } from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'

const BodyContainer = styled('div')((props) => ({
  '& .b3-qty-report': {
    '& h5': {
      marginTop: 0,
    },

    '& table': {
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',

      '& td': {
        border: `1px solid ${props.theme.palette.grey[100]}`,
        padding: props.theme.spacing(2),
      },

      '& thead td': {
        fontWeight: 700,

        '&:first-child': {
          width: 200,

          '& + td': {
            width: 80,
          },
        },
      },
    },
  },
}))

export class Notification extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      notification: {
        id: '',
        title: '',
        isRead: '',
        body: '',
        createdAt: '',
      },
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  getNotification = async () => {
    try {
      this.setState({
        isLoading: true,
      })

      const {
        match,
      } = this.props

      const {
        id,
      } = match.params
      const notification = await b3request.dashboard.getNotification(id)
      if (notification.isRead !== '1') {
        await b3request.dashboard.markNotificationAsRead(notification.id)
      }
      this.setState({
        isLoading: false,
        notification,
      })
    } catch (error) {
      snackbar.error(error.message)
    }
  }

  render() {
    const {
      notification: {
        title,
        body,
        createdAt,
      },
      isLoading,
    } = this.state
    return (
      <B3Card
        isLoading={isLoading}
        title={<B3Back title={title} />}
        extra={b3DateFormat.extendedDisplayFormat(createdAt)}
      >
        <BodyContainer dangerouslySetInnerHTML={{ __html: body }} />
      </B3Card>
    )
  }
}

export default Notification
