import {
  GLOBAL_TOGGLE_NAV_COLLAPSE,
  GLOBAL_SET_PAGE_TITLE,
  GLOBAL_CREATE_SNACKBAR_MESSAGE,
  GLOBAL_REMOVE_SNACKBAR_MESSAGE,
  TOGGLE_GLOBAL_LOADING,
} from '../actionTypes'

export const toggleNavCollapseGlobal = () => ({
  type: GLOBAL_TOGGLE_NAV_COLLAPSE,
})

export const setPageTitle = (pageTitle) => ({
  type: GLOBAL_SET_PAGE_TITLE,
  payload: {
    pageTitle,
  },
})

export const createSnackbarMessage = (payload) => ({
  type: GLOBAL_CREATE_SNACKBAR_MESSAGE,
  payload,
})

export const removeSnackbarMessage = (payload) => ({
  type: GLOBAL_REMOVE_SNACKBAR_MESSAGE,
  payload,
})

export const toggleGlobalLoading = (isGlobalLoading = false) => ({
  type: TOGGLE_GLOBAL_LOADING,
  payload: {
    isGlobalLoading,
  },
})
