import b3request from './base'
import pureApiList from './pureApiList'

// get user info
export const getUserById = (id) => b3request.get(`/api/v2/users/${id}`)

// update User Info
export const updateUserInfo = (id, params) => b3request.put(`/api/v2/users/${id}`, params)

// create User
export const createUser = (params) => b3request.post('/api/v2/users', params)

// check backend token status
export const checkTokenStatus = (params) => b3request.get('/api/v2/backend/session/status', { params })

// backend user login
export const loginApp = (params) => b3request.post('/api/v2/backend/session', params)

// backend user sign up
export const registerApp = (params) => b3request.post(pureApiList.REGISTER, params)

// login out
export const logOut = (params) => b3request.delete('/api/v2/backend/session', params)

// backend forget password
export const forgetPassword = (params) => b3request.post('/api/v2/backend/password/forget', params)

// backend create user
export const createAdminUser = (params) => b3request.post('/api/v2/backend/user', params)

// backend delete user
export const deleteAdminUser = (id) => b3request.delete(`/api/v2/backend/users/${id}`)

// backend update user
export const updateAdminUser = (id, params) => b3request.put(`/api/v2/backend/users/${id}`, params)

// backend get users
export const getAdminUsers = (params) => b3request.get('/api/v2/backend/users', { params })

// backend get user
export const getAdminUser = (id) => b3request.get(`/api/v2/backend/users/${id}`)
