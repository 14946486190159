import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Grid,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Link,
  styled,
  IconButton,
  Tooltip,
} from '@material-ui/core'

import {
  GetApp as ExportIcon,
} from '@material-ui/icons'

import {
  B3Card,
  B3Search,
  B3Filter,
  NoData,
} from '../../components'

import b3request from '../../service'
import {
  snackbar,
  currencyFormat,
} from '../../utils'

import b3DateFormat from '../../utils/b3DateFormat'
import OrdersExport from './OrdersExport'

const OrderTable = styled(Table)({
  tableLayout: 'fixed',
})

class Orders extends Component {
  static propTypes = {
    isExportOrdersReady: PropTypes.oneOf(['0', '1']).isRequired,
  }

  /**
   * cols: use for the table render
   */
  cols = [{
    key: 'bcOrderId',
    label: 'Order ID',
    width: '120px',
    sortable: true,
    /**
     * need to link Order ID to BC's order page
     */
    render(row) {
      return (
        <Link
          onClick={() => {
            window.open(row.url, '_blank')
          }}
        >
          {row[this.key]}
        </Link>
      )
    },
  }, {
    key: 'companyName',
    label: 'Company',
    width: 'auto',
    sortable: true,
  }, {
    key: 'status',
    label: 'Status',
    width: '150px',
    render(row) {
      return row.customStatus || row[this.key]
    },
  }, {
    key: 'totalIncTax',
    label: 'Total',
    width: '150px',
    sortable: true,
    /**
     * need to format currency by BC's default currency settings
     */
    render(row) {
      return currencyFormat(row[this.key], row.money)
    },
  }, {
    key: 'items',
    label: 'Items',
    width: '80px',
    sortable: true,
  }, {
    key: 'createdAt',
    label: 'Created',
    width: '160px',
    sortable: true,
    /**
     * need to formate time from timestamp responsed.
     */
    render(row) {
      return b3DateFormat.extendedDisplayFormat(row.createdAt)
    },
  }]

  constructor() {
    super()
    this.state = {
      orderStatus: [],
      isLoading: false,
      q: '',
      orderBy: 'desc',
      sortBy: 'createdAt',
      orders: [],
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      filters: {
        orderId: '',
        company: '',
        statusCode: [],
      },
      isExportPanelOpen: false,
    }
  }

  /**
   * need to load initial data at first time
   */
  componentDidMount() {
    this.getOrderStatuses()
    this.getOrderList()
  }

  /**
   * we don't cancel the request when component unmount, so need to cancle setState to avoid the `unmounted component` error in console.
   */
  componentWillUnmount() {
    this.setState = () => false
  }

  /**
   * caclulate the current page by pagination data
   */
  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  async getOrderStatuses() {
    const { orderStatuses } = await b3request.orders.getOrderStatuses()

    this.setState({
      orderStatus: orderStatuses,
    })
  }

  /**
   * handle input change
   */
  handleSearchChange = (q) => {
    this.setState({
      q,
    })
  }

  /**
   * search event
   * need to reset `offset` to `0` to make sure the responsed data is from the first page.
   */
  handleSearch = () => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    }, this.getOrderList)
  }

  /**
   * pagination page number change event
   */
  handlePageChange = (event, page) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: page * pagination.limit,
      },
    }, this.getOrderList)
  }

  handleRowsPerPageChange = (event) => {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
        limit: event.target.value,
      },
    }, this.getOrderList)
  }

  /**
   * sort change
   * need to deal width direction and sort key
   * @param {string} the column key
   */
  handleSort = (sortFiled) => () => {
    const { orderBy, sortBy } = this.state
    const inverseDirection = {
      desc: 'asc',
      asc: 'desc',
    }
    const direction = sortFiled === sortBy ? inverseDirection[orderBy] : orderBy
    this.setState(
      {
        sortBy: sortFiled,
        orderBy: direction,
      },
      this.getOrderList,
    )
  }

  /**
   * the main method of getting order list data.
   */
  getOrderList = async () => {
    this.setState({
      isLoading: true,
    })
    try {
      const {
        q,
        orderBy,
        sortBy,
        pagination,
        pagination: {
          offset,
          limit,
        },
        filters: {
          orderId,
          company,
          statusCode,
        },
      } = this.state
      const resp = await b3request.orders.getOrderList({
        q,
        orderBy,
        sortBy,
        offset,
        limit,
        orderId,
        company,
        statusCode,
      })

      this.setState({
        orders: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleFilterChange = (filters) => {
    this.setState({
      filters,
    })
  }

  handleFilterCancle = () => {
    this.setState(
      {
        filters: {
          orderId: '',
          company: '',
          statusCode: [],
        },
      },
      this.handleFilterApply,
    )
  }

  handleFilterApply = () => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: 0,
        },
      },
      this.getOrderList,
    )
  }

  handleOpenExportPanel = () => {
    this.setState({
      isExportPanelOpen: true,
    })
  }

  hideExportPanel = () => {
    this.setState({
      isExportPanelOpen: false,
    })
  }

  render() {
    const {
      isLoading,
      q,
      orders,
      pagination,
      sortBy,
      orderBy,
      filters,
      orderStatus,
      isExportPanelOpen,
    } = this.state

    const {
      isExportOrdersReady,
    } = this.props

    return (
      <B3Card
        isLoading={isLoading}
        title={(
          <Grid container>
            <B3Search
              value={q}
              onChange={this.handleSearchChange}
              onSearch={this.handleSearch}
              placeholder="search order…"
            />
            <B3Filter
              statusFilters={orderStatus}
              filters={filters}
              onChange={this.handleFilterChange}
              onCancel={this.handleFilterCancle}
              onApply={this.handleFilterApply}
              statusLabel="customLabel"
              statusValue="statusCode"
              filtersStatusKey="statusCode"
            />
          </Grid>
        )}
        extra={(
          <React.B3PermissionContainer
            permissions={['14500']}
          >
            <Tooltip
              title="Export Orders"
              placement="top-end"
            >
              <IconButton
                color="primary"
                disabled={isExportOrdersReady === '0'}
                onClick={this.handleOpenExportPanel}
              >
                <ExportIcon />
              </IconButton>
            </Tooltip>
          </React.B3PermissionContainer>
        )}
      >
        {
          orders.length === 0 ? <NoData /> : (
            <OrderTable>
              <TableHead>
                <TableRow>
                  {
                    this.cols.map((col) => (
                      <TableCell
                        key={col.key}
                        style={{ width: col.width }}
                      >
                        {
                          col.sortable ? (
                            <TableSortLabel
                              active={sortBy === col.key}
                              direction={orderBy}
                              onClick={this.handleSort(col.key)}
                            >
                              {col.label}
                            </TableSortLabel>
                          ) : (
                            <>{col.label}</>
                          )
                        }
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    hover
                    key={order.bcOrderId}
                  >
                    {
                      this.cols.map((col) => (
                        <TableCell
                          key={col.key}
                        >
                          {
                            col.render ? col.render(order) : order[col.key]
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))}
              </TableBody>
            </OrderTable>
          )
        }
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pagination.totalCount}
          rowsPerPage={pagination.limit}
          page={this.currentPage}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
        />
        {
          isExportPanelOpen && (
            <OrdersExport
              orderStatus={orderStatus}
              onClose={this.hideExportPanel}
            />
          )
        }
      </B3Card>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    orders: {
      isExportOrdersReady,
    },
  } = state
  return {
    isExportOrdersReady,
  }
}

export default connect(mapStateToProps)(Orders)
