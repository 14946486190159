import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Typography,
  styled,
  Divider,
  Switch,
  Input,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core'

import {
  B3Card,
} from '../../components'

import locales from '../../locales/en-US'
import b3request from '../../service'

import {
  getStoreConfig,
  handleSwitchStatusChange,
  updateStoreConfig,
  updateNotificationEmail,
  updateDefaultPriceList,
  updateDefaultPaymentMethods,
} from '../../store/actions/settings'

import {
  checkPermissions,
} from '../../utils'

const AddressEditingSubContainer = styled('div')((props) => ({
  paddingLeft: props.theme.spacing(),
}))

const SalesRepEditingSubContainer = styled('div')((props) => ({
  paddingLeft: props.theme.spacing(),
}))

const StyledTitle = styled(Typography)((props) => ({
  paddingLeft: props.theme.spacing(2),
  paddingRight: props.theme.spacing(2),
  paddingTop: props.theme.spacing(2),
  paddingBottom: props.theme.spacing(),
  fontWeight: props.theme.typography.fontWeightBold,
}))

const ItemContainer = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: props.theme.spacing(),
}))

const FormContainer = styled(ItemContainer)({
  justifyContent: 'flex-start',
})

const StyledInput = styled(Input)({
  width: '50%',
  maxWidth: 300,
  margin: '0 4px',
})
const StyledSelect = styled(Select)({
  width: '50%',
  maxWidth: 300,
  margin: '0 4px',
})

const AddressBookSwitchDialog = ({
  isOpen,
  onClose,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
  >
    <DialogContent>
      <DialogContentText>
        To enable/disable this feature, please contact us at
        <Link href="mailto:support@bundleb2b.com"> support@bundleb2b.com. </Link>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
)
AddressBookSwitchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

class Settings extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    storeConfig: PropTypes.shape({
      notificationsEmailTo: PropTypes.string.isRequired,
      switchStatus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        key: PropTypes.string,
        isEnabled: PropTypes.oneOf(['0', '1']),
      })),
      priceList: PropTypes.arrayOf(PropTypes.shape({
        pricelistId: PropTypes.number,
        pricelistName: PropTypes.string,
      })),
      defaultPriceList: PropTypes.shape({
        pricelistId: PropTypes.string.isRequired,
        pricelistName: PropTypes.string.isRequired,
      }),
      defaultPaymentMethods: PropTypes.arrayOf(PropTypes.shape({
        paymentCode: PropTypes.string,
        paymentTitle: PropTypes.string,
      })),
    }).isRequired,
    getStoreConfig: PropTypes.func.isRequired,
    handleSwitchStatusChange: PropTypes.func.isRequired,
    updateStoreConfig: PropTypes.func.isRequired,
    updateNotificationEmail: PropTypes.func.isRequired,
    updateDefaultPriceList: PropTypes.func.isRequired,
    updateDefaultPaymentMethods: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isEmailTouched: false,
      emailError: '',
      isAddressBookDialogShow: false,
      updateSettingsPermissions: ['16101', '16102', '16103', '16104'],
      isPermissionsAlowed: false,
      storePayments: [],
      isPaymentMethodsChanged: false,
      selectedPaymentMethodCodes: [],
    }
  }

  componentDidMount() {
    const {
      updateSettingsPermissions,
    } = this.state
    const { getStoreConfig } = this.props
    getStoreConfig()

    this.setState({
      isPermissionsAlowed: checkPermissions(updateSettingsPermissions),
    })
    this.getStorePayments()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  getSwitchStatusBykey = (key) => {
    const {
      storeConfig: {
        switchStatus = [],
      } = {},
    } = this.props
    return switchStatus.filter((status) => status.key === key)[0] || {}
  }

  get emailNotifications() {
    return this.getSwitchStatusBykey('email_notifications')
  }

  get quotes() {
    return this.getSwitchStatusBykey('quotes')
  }

  get addressBook() {
    return this.getSwitchStatusBykey('address_book')
  }

  get addressSalesRep() {
    return this.getSwitchStatusBykey('address_sales_rep')
  }

  get addressAdmin() {
    return this.getSwitchStatusBykey('address_admin')
  }

  get companyAutoApprove() {
    return this.getSwitchStatusBykey('company_auto_approval')
  }

  get quoteAutoApproval() {
    return this.getSwitchStatusBykey('quote_auto_approval')
  }

  get defaultPaymentCodes() {
    const {
      storeConfig,
    } = this.props
    const defaultPaymentCodes = storeConfig.defaultPaymentMethods.map((defaultPaymentMethod) => defaultPaymentMethod.paymentCode)
    return defaultPaymentCodes
  }

  validateEmail = (email) => {
    const reg = /^([A-Za-z0-9_+-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
    if (!reg.test(email)) {
      return locales['app.validation.errors.invalid.email']
    }
    return ''
  }

  handleEmailChange = (e) => {
    const {
      storeConfig,
      updateStoreConfig,
    } = this.props
    const emailError = this.validateEmail(e.target.value)
    updateStoreConfig({
      ...storeConfig,
      notificationsEmailTo: e.target.value,
    })
    this.setState({
      isEmailTouched: true,
      emailError,
    })
  }

  handleAddressBookDialogShow = () => {
    this.setState({
      isAddressBookDialogShow: true,
    })
  }

  handleAddressBookDialogClose = () => {
    this.setState({
      isAddressBookDialogShow: false,
    })
  }

  handleDefaultPriceListChange = (event) => {
    const {
      updateDefaultPriceList,
      storeConfig,
      updateStoreConfig,
    } = this.props
    updateStoreConfig({
      ...storeConfig,
      defaultPriceList: {
        pricelistId: event.target.value,
        priceListName: '',
      },
    })

    updateDefaultPriceList(event.target.value)
  }

  handleDefaultPaymentMethodsChange = (event) => {
    const {
      storeConfig,
      updateStoreConfig,
    } = this.props
    const { storePayments } = this.state
    const { value } = event.target
    const selectedPaymentMethods = storePayments.filter((payment) => value.includes(payment.paymentCode))
    this.setState({
      isPaymentMethodsChanged: true,
      selectedPaymentMethodCodes: value,
    })
    updateStoreConfig({
      ...storeConfig,
      defaultPaymentMethods: selectedPaymentMethods,
    })
  }

  getStorePayments = async () => {
    const { list } = await b3request.storeConfig.getStorePayments()
    this.setState({
      storePayments: list,
    })
  }

  render() {
    const {
      isEmailTouched,
      emailError,
      isAddressBookDialogShow,
      isPermissionsAlowed,
      updateSettingsPermissions,
      storePayments,
      isPaymentMethodsChanged,
      selectedPaymentMethodCodes,
    } = this.state
    const {
      storeConfig,
      isLoading,
      handleSwitchStatusChange,
      updateNotificationEmail,
      updateDefaultPaymentMethods,
    } = this.props
    return (
      <>
        <StyledTitle>
          Company Management
        </StyledTitle>
        <B3Card
          isLoading={isLoading}
        >
          <ItemContainer variant="h1" component="h3">
            For New Company Requests:
          </ItemContainer>
          <ItemContainer>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.emailNotifications.isEnabled === '1'}
                  onChange={() => handleSwitchStatusChange(this.emailNotifications)}
                  disabled={!isPermissionsAlowed}
                />
                )}
              label="Enable email notification"
            />
          </ItemContainer>
          {
            (this.emailNotifications.isEnabled === '1') && (
              <FormContainer>
                <Typography>Send emails to:</Typography>
                <StyledInput
                  value={storeConfig.notificationsEmailTo}
                  onChange={this.handleEmailChange}
                  disabled={!isPermissionsAlowed}
                />
                <React.B3PermissionContainer
                  permissions={updateSettingsPermissions}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={!!emailError || !isEmailTouched}
                    onClick={() => updateNotificationEmail(storeConfig.notificationsEmailTo)}
                  >
                    Save
                  </Button>
                </React.B3PermissionContainer>

              </FormContainer>
            )
          }

          <ItemContainer>
            {
              (isEmailTouched && !!emailError) && (
                <Typography color="error">{emailError}</Typography>
              )
            }
          </ItemContainer>
          <ItemContainer>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.companyAutoApprove.isEnabled === '1'}
                  onChange={() => handleSwitchStatusChange(this.companyAutoApprove)}
                  disabled={!isPermissionsAlowed}
                />
              )}
              label="Enable automatic approval"
            />
          </ItemContainer>
          <ItemContainer variant="h1" component="h3">
            Set Default Price List:
          </ItemContainer>
          <FormContainer>
            <StyledSelect
              value={storeConfig.defaultPriceList.pricelistId ? storeConfig.defaultPriceList.pricelistId : ''}
              onChange={this.handleDefaultPriceListChange}
              disabled={!isPermissionsAlowed}
            >
              <MenuItem key="none" value=""><em>None</em></MenuItem>
              {
              storeConfig.priceList.map((option) => (
                <MenuItem key={option.pricelistId} value={option.pricelistId}>{option.pricelistName}</MenuItem>
              ))
            }
            </StyledSelect>
          </FormContainer>
          <ItemContainer variant="h1" component="h3">
            Set Default Payment Methods:
          </ItemContainer>
          <FormContainer>
            <StyledSelect
              multiple
              value={this.defaultPaymentCodes}
              onChange={this.handleDefaultPaymentMethodsChange}
              disabled={!isPermissionsAlowed}
              renderValue={(selected) => {
                const PaymentsList = storePayments.reduce(
                  (result, currentPayment) => {
                    if (selected.includes(currentPayment.paymentCode)) result.push(currentPayment.paymentCode === 'cheque' ? 'PO' : currentPayment.paymentTitle)
                    return result
                  }, [],
                )
                return PaymentsList.join(', ')
              }}
            >
              {storePayments.map((payment) => (
                <MenuItem key={payment.paymentCode} value={payment.paymentCode}>
                  <Checkbox checked={this.defaultPaymentCodes.includes(payment.paymentCode)} />
                  <ListItemText primary={payment.paymentCode === 'cheque' ? 'PO' : payment.paymentTitle} />
                </MenuItem>
              ))}
            </StyledSelect>
            <React.B3PermissionContainer
              permissions={updateSettingsPermissions}
            >
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={!isPaymentMethodsChanged}
                onClick={() => {
                  updateDefaultPaymentMethods(selectedPaymentMethodCodes)
                  this.setState({
                    isPaymentMethodsChanged: false,
                  })
                }}
              >
                Save
              </Button>
            </React.B3PermissionContainer>
          </FormContainer>
        </B3Card>
        <StyledTitle>
          Address Book Management
        </StyledTitle>
        <B3Card
          isLoading={isLoading}
        >
          <ItemContainer>
            <Typography>Enable</Typography>
            <Switch
              size="small"
              checked={this.addressBook.isEnabled === '1'}
              onChange={this.handleAddressBookDialogShow}
              disabled={!isPermissionsAlowed}
            />
          </ItemContainer>
          {
            this.addressBook.isEnabled === '1' && (
              <>
                <Divider />
                <AddressEditingSubContainer>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.addressAdmin.isEnabled === '1'}
                        onChange={() => handleSwitchStatusChange(this.addressAdmin)}
                        disabled={!isPermissionsAlowed}
                      />
                    )}
                    label="Allow company admin users to edit addresses"
                  />
                </AddressEditingSubContainer>
                <AddressEditingSubContainer>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.addressSalesRep.isEnabled === '1'}
                        onChange={() => handleSwitchStatusChange(this.addressSalesRep)}
                        disabled={!isPermissionsAlowed}
                      />
                    )}
                    label="Allow sales reps to edit addresses"
                  />
                </AddressEditingSubContainer>
              </>
            )
          }
          <AddressBookSwitchDialog
            isOpen={isAddressBookDialogShow}
            onClose={this.handleAddressBookDialogClose}
          />
        </B3Card>
        <StyledTitle>
          Sales Representative Quotes
        </StyledTitle>
        <B3Card
          isLoading={isLoading}
        >
          <ItemContainer>
            <Typography>Enable</Typography>
            <Switch
              size="small"
              checked={this.quotes.isEnabled === '1'}
              onChange={() => handleSwitchStatusChange(this.quotes)}
              disabled={!isPermissionsAlowed}
            />
          </ItemContainer>
          {
            this.quotes.isEnabled === '1' && (
              <>
                <Divider />
                <SalesRepEditingSubContainer>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.quoteAutoApproval.isEnabled === '1'}
                        onChange={() => handleSwitchStatusChange(this.quoteAutoApproval)}
                        disabled={!isPermissionsAlowed}
                      />
                          )}
                    label="Allow automatic approval on all quotes"
                  />
                </SalesRepEditingSubContainer>
                <ItemContainer>
                  When checked, sales reps can create and publish new quotes for assigned companies without submitting for manager approval
                </ItemContainer>
              </>
            )
          }
        </B3Card>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoadingSettings,
  storeConfig: state.settings.storeConfig,
})

const mapDispatchToProps = {
  getStoreConfig,
  handleSwitchStatusChange,
  updateStoreConfig,
  updateNotificationEmail,
  updateDefaultPriceList,
  updateDefaultPaymentMethods,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
