import {
  SETTINGS_GET_STORE_CONFIG_SUCCESS,
  SETTINGS_TOGGLE_CONFIG_LOADING_STATUS,
} from '../actionTypes'

const initState = {
  isLoadingSettings: false,
  storeConfig: {
    switchStatus: [],
    notificationsEmailTo: '',
    defaultPriceList: {
      pricelistId: '',
      priceListName: '',
    },
    priceList: [],
    defaultPaymentMethods: [],
  },
}

export default (state = initState, action) => {
  switch (action.type) {
    case SETTINGS_GET_STORE_CONFIG_SUCCESS:
      return {
        ...state,
        storeConfig: action.payload.storeConfig,
      }
    case SETTINGS_TOGGLE_CONFIG_LOADING_STATUS:
      return {
        ...state,
        isLoadingSettings: action.payload.isLoadingSettings,
      }
    default:
      return state
  }
}
