import React, { Component } from 'react'
import Summaries from './Summaries'
import Notifications from './Notifications'

export default class Dashboard extends Component {
  render() {
    return (
      <>
        <React.B3PermissionContainer
          permissions={['10003']}
        >
          <Summaries />
        </React.B3PermissionContainer>
        <React.B3PermissionContainer
          permissions={['10000']}
        >
          <Notifications />
        </React.B3PermissionContainer>
      </>
    )
  }
}
