import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Formik,
} from 'formik'
import { isEqual, isEmpty } from 'lodash'

import * as Yup from 'yup'

import {
  DialogContent,
  TextField,
  Grid,
  styled,
  Typography,
  Box,
} from '@material-ui/core'

import {
  B3ConfirmDialog,
  B3Spin,
  StandardB3CountryGroup,
} from '../../components'

import b3request from '../../service'

import { snackbar } from '../../utils'

import locales from '../../locales/en-US'

import { re } from '../../constants'

import getTextFieldPropsByCode from '../../utils/getTextFieldPropsByCode'

const FormContainer = styled(Grid)({
  minWidth: 400,
  marginBottom: 16,
})

/**
 * define company infomation form fields
 *
 * @typedef {Object} formFieldSchema
 * @property {string} name key of each field
 * @property {string} label display name
 * @property {string} initialValue initial value of each field
 * @property {boolen} isRequired if the field value is required
 * @property {object} validator Yup object for validataion
 * @property {number} xs the gird of Mui for layout
 */

/**
 * @const
 * @type {Array.<formFieldSchema>} company infomation fields value
 * @todo confirm the max length of the each field with backend
 */
const companyInfomationFields = [{
  name: 'companyName',
  label: 'Company Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.companyName']),
  xs: 6,
}, {
  name: 'phoneNumber',
  label: 'Phone Number',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber'])
    .required(locales['app.validation.errors.required.phoneNumber']),
  xs: 6,
}, {
  name: 'companyEmail',
  label: 'Company Email Address',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  xs: 12,
}, {
  name: 'addressLine1',
  label: 'Address Line 1',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required()
    .max(255),
  xs: 12,
}, {
  name: 'addressLine2',
  label: 'Address Line 2',
  initialValue: '',
  validator: Yup.string()
    .max(255),
  xs: 12,
},
{
  name: 'country',
  label: 'Country',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required()
    .max(255),
  xs: 6,
}, {
  name: 'state',
  label: 'State',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required()
    .max(255),
  xs: 6,
}, {
  name: 'city',
  label: 'City',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required()
    .max(255),
  xs: 6,
}, {
  name: 'zipCode',
  label: 'Zip Code',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .required()
    .max(255),
  xs: 6,
}]

/**
 * @const
 * @type {Array.<formFieldSchema>} company admin fields value
 * @todo confirm the max length of the each field with backend
 */
const companyAdminFields = [{
  name: 'adminEmail',
  label: 'Email',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .matches(re.email, locales['app.validation.errors.invalid.email'])
    .required(locales['app.validation.errors.required.email']),
  xs: 6,
}, {
  name: 'adminPhoneNumber',
  label: 'Contact Phone Number',
  initialValue: '',
  isRequired: false,
  validator: Yup.string()
    .matches(re.phone, locales['app.validation.errors.invalid.phoneNumber']),
  xs: 6,
}, {
  name: 'adminFirstName',
  label: 'First Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.firstName']),
  xs: 6,
}, {
  name: 'adminLastName',
  label: 'Last Name',
  initialValue: '',
  isRequired: true,
  validator: Yup.string()
    .max(255)
    .required(locales['app.validation.errors.required.lastName']),
  xs: 6,
}]

/**
 * generate form validation schema shape
 * add `companyStatus` field
 * `companyStatus` field will not display on the UI
 * it's only used for API to know the company is created by B3 APP
 *
 * @const {object} companyFieldsSchemaShape
 */
const getCompanyFieldsSchemaShape = (fields) => [
  ...fields,
].reduce((shape, currentItem) => {
  shape[currentItem.name] = currentItem.validator
  return shape
}, {
  companyStatus: Yup.string()
    .max(255)
    .required(),
})

const companyFieldsSchemaShape = getCompanyFieldsSchemaShape([
  ...companyInfomationFields,
  ...companyAdminFields,
])

/**
 * generate form initial fields value
 * the initial value of `companyStatus` should be `1`
 *
 * @const {object} companyFieldsInitialValues
 */
const getCompanyFieldsInitialValues = (fields) => ([...fields].reduce((values, currentItem) => {
  values[currentItem.name] = currentItem.initialValue
  return values
}, {
  companyStatus: '1',
}))

const companyFieldsInitialValues = getCompanyFieldsInitialValues([
  ...companyInfomationFields,
  ...companyAdminFields,
])

class AddNewCompany extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    customerGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    extraFields: PropTypes.arrayOf(PropTypes.shape({
      dataType: PropTypes.string,
      fieldName: PropTypes.string,
      isRequired: PropTypes.string,
      labelName: PropTypes.string,
    })).isRequired,
  }

  constructor(props) {
    super()
    this.state = {
      isAdminEmailValid: false,
      isCheckingAdminEmail: false,
      companyFieldsSchemaShape,
      companyFieldsInitialValues: {
        ...companyFieldsInitialValues,
        companyName: props.customerGroup.name || '',
      },
      companyExtraFields: [],
      extraFields: [],
      countries: [],
      states: [],
    }
  }

  componentDidMount() {
    this.getCountries()
  }

  static getDerivedStateFromProps(props, state) {
    const {
      extraFields,
      customerGroup: {
        name: companyName = '',
      },
    } = props

    if (isEqual(extraFields, state.extraFields)) return null

    const companyExtraFields = [
      ...extraFields.map((item) => {
        const {
          fieldName: name,
          labelName,
          dataType,
          isRequired,
        } = item
        const textFieldProps = getTextFieldPropsByCode(dataType)
        const label = labelName.replace(labelName[0], labelName[0].toUpperCase())

        const typeValidate = dataType === '1' ? 'number' : 'string'
        const validator = isRequired === '1'
          ? Yup[typeValidate]().required(`${label} is required`)
          : Yup[typeValidate]()

        return {
          name,
          label,
          initialValue: '',
          isRequired: !!+isRequired,
          validator,
          xs: dataType === '2' ? 12 : 6,
          textFieldProps,
        }
      }),
    ]

    const getFields = (func, ...args) => func([
      ...companyInfomationFields,
      ...companyAdminFields,
      ...companyExtraFields,
      ...args,
    ])

    const companyFieldsSchemaShape = getFields(getCompanyFieldsSchemaShape)

    const companyFieldsInitialValues = {
      ...getFields(getCompanyFieldsInitialValues),
      companyName,
    }

    return {
      extraFields,
      companyExtraFields,
      companyFieldsSchemaShape,
      companyFieldsInitialValues,
    }
  }

  get isAddNew() {
    const { customerGroup } = this.props || {}
    return !customerGroup.id
  }

  get snackbarSuccessInfo() {
    return this.isAddNew ? 'Add new company successfully!' : 'Convert company from customer group successfully!'
  }

  get dialogTitle() {
    return this.isAddNew ? 'Add a new company' : 'Convert company from customer group'
  }

  getCountries = async () => {
    let countries = JSON.parse(window.sessionStorage.getItem('b2bcountries'))
    if (!countries) {
      const data = await b3request.companies.getCountries()
      countries = data.list
      window.sessionStorage.setItem('b2bcountries', JSON.stringify(countries))
    }
    this.setState({
      countries,
    })
  }

  render() {
    const {
      onCancel,
      extraFields,
    } = this.props
    const {
      isAdminEmailValid,
      isCheckingAdminEmail,
      companyFieldsInitialValues,
      companyFieldsSchemaShape,
      companyExtraFields,
      countries,
      states,
    } = this.state

    return (
      <Formik
        initialValues={companyFieldsInitialValues}
        enableReinitialize
        validationSchema={Yup.object().shape(companyFieldsSchemaShape)}
        onSubmit={async (values, { setSubmitting }) => {
          const {
            customerGroup,
          } = this.props

          const request = this.isAddNew
            ? b3request.companies.createCompany
            : b3request.companies.convertCompanyFromCustomerGroup

          const extraFieldsKeys = extraFields.map((item) => item.fieldName)
          const params = Object.keys(values).filter((key) => !extraFieldsKeys.includes(key)).reduce((result, key) => {
            result[key] = values[key]
            return result
          }, {})
          params.extraFields = extraFieldsKeys.map((fieldName) => ({
            fieldName,
            fieldValue: values[fieldName],
          }))

          if (!this.isAddNew) params.customerGroupId = customerGroup.id

          const resp = await request(params)

          if (resp.companyId) {
            onCancel(true)
            snackbar.success(this.snackbarSuccessInfo)
          }
          setSubmitting(false)
        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setStatus,
            status = {
              errors: {},
            },
          }) => {
            /**
             * Generate field props
             * @function getFieldProps
             * @param {string} label
             * @param {string} name
             * @param {boolean} required
             */
            const getFieldProps = (label, name, required, textFieldProps = {}) => ({
              label,
              name,
              value: values[name],
              onChange: handleChange,
              onBlur: handleBlur,
              error: touched[name] && (Boolean(errors[name]) || Boolean(status.errors[name])),
              helperText: touched[name] ? (errors[name] || status.errors[name]) : '',
              required,
              fullWidth: true,
              disabled: isCheckingAdminEmail || isSubmitting || (!this.isAddNew && name === 'companyName'),
              ...textFieldProps,
            })

            const handleDialogClose = () => {
              // can't make the dialog close if ajax is calling.
              if (isSubmitting) return false
              return onCancel()
            }

            const setFieldsValue = (fields) => {
              Object.keys(fields).forEach((key) => {
                setFieldValue(key, fields[key])
              })
            }
            return (
              <B3ConfirmDialog
                isOpen
                onClose={handleDialogClose}
                title={this.dialogTitle}
                confirmText={this.isAddNew ? 'Add' : 'convert'}
                onConfirm={handleSubmit}
                isCancelDisabled={isSubmitting}
                isSubmitDisabled={isSubmitting || !isEmpty(errors) || !isEmpty(status.errors) || !dirty}
                isSpinning={isSubmitting}
              >
                <DialogContent>
                  <B3Spin
                    isSpinning={isCheckingAdminEmail}
                    tip="checking admin email…"
                  >
                    <Typography
                      variant="subtitle2"
                    >
                      Company Information
                    </Typography>
                    <FormContainer
                      container
                      spacing={1}
                    >
                      {
                        companyInfomationFields.map((field) => {
                          if (values[field.name] === undefined) return null

                          const fieldProps = {
                            ...getFieldProps(field.label, field.name, field.isRequired, field.textFieldProps || {}),
                            countries,
                            states,
                            field,
                          }

                          if (['country', 'state'].includes(field.name)) {
                            const newFieldProps = {
                              ...fieldProps,
                              onOpen: (e) => {
                                setFieldTouched(e.name, true)
                              },
                            }
                            return (
                              <StandardB3CountryGroup
                                key={field.name}
                                field={field}
                                fieldProps={newFieldProps}
                                countryValue={values.country}
                                countryChange={(countryName) => {
                                  const country = countries.filter((item) => item.countryName === countryName)[0] || {}
                                  setFieldValue('state', '')
                                  setFieldValue('country', country.countryName)
                                  this.setState({
                                    states: country.states,
                                  })
                                }}
                                states={states}
                                stateValue={values.state}
                                selectStateChange={(stateName) => {
                                  const state = states.filter((item) => item.stateName === stateName)[0]
                                  setFieldValue('state', state.stateName)
                                }}
                                textStateChange={(e) => {
                                  setFieldValue('state', e.target.value)
                                }}
                                stateHelperText={(touched.state && errors.state) ? errors.state : ''}
                              />
                            )
                          }

                          return (
                            <Grid item key={field.name} xs={field.xs}>
                              <TextField {...getFieldProps(field.label, field.name, field.isRequired)} />
                            </Grid>
                          )
                        })
                      }
                    </FormContainer>
                    <Typography
                      variant="subtitle2"
                    >
                      Company Admin
                    </Typography>
                    <FormContainer
                      container
                      spacing={1}
                    >
                      {
                        // need to deal with adminEmail field
                        companyAdminFields.map((field) => {
                          const adminFiledsProps = field.name === 'adminEmail'
                            ? {
                              // normal props
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                              ),
                              // rewrite onBlur prop of adminEmail field
                              onBlur: async (e) => {
                                // use default blur method to validate the email first with email schema
                                handleBlur(e)
                                if (errors.adminEmail || values.adminEmail === '') {
                                  return false
                                }
                                // if email is validated by schema, should make ajax call to validate if the email is existed in another company
                                this.setState({
                                  isCheckingAdminEmail: true,
                                })
                                // ajax call
                                let isAdminEmailValid = false
                                try {
                                  const {
                                    isValid,
                                    userInfo: {
                                      // fields' default value is empty
                                      firstName = '',
                                      lastName = '',
                                      phoneNumber = '',
                                    },
                                  } = await b3request.companies.validateUserEmail(values.adminEmail, '1')
                                  // set form fields value by the response of ajax call
                                  setFieldsValue({
                                    adminFirstName: firstName,
                                    adminLastName: lastName,
                                    adminPhoneNumber: phoneNumber,
                                  })

                                  isAdminEmailValid = isValid === '1'
                                } finally {
                                  this.setState({
                                    isAdminEmailValid,
                                    isCheckingAdminEmail: false,
                                  })
                                }

                                // deal with status errors
                                const statusErrors = status.errors
                                if (isAdminEmailValid) {
                                  delete statusErrors.adminEmail
                                } else {
                                  statusErrors.adminEmail = 'email exists in another company'
                                }
                                setStatus({
                                  errors: statusErrors,
                                })
                                return true
                              },
                            }
                            : {
                              ...getFieldProps(
                                field.label,
                                field.name,
                                field.isRequired,
                              ),
                              disabled: !isAdminEmailValid || isCheckingAdminEmail || isSubmitting,
                            }
                          return (
                            <Grid item key={field.name} xs={field.xs}>
                              <TextField
                                {...adminFiledsProps}
                              />
                            </Grid>
                          )
                        })
                      }
                    </FormContainer>
                    {
                      companyExtraFields.length !== 0 && (
                        <>
                          <Box mb={1}>
                            <Typography
                              variant="subtitle2"
                            >
                              Extra Fields
                            </Typography>
                          </Box>

                          <FormContainer
                            container
                            spacing={2}
                          >
                            {
                              companyExtraFields.map((field) => (values[field.name] !== undefined ? (
                                <Grid item key={field.name} xs={field.xs}>
                                  <TextField {...getFieldProps(
                                    field.label,
                                    field.name,
                                    field.isRequired,
                                    field.textFieldProps || {},
                                  )}
                                  />
                                </Grid>
                              ) : null))
                            }
                          </FormContainer>
                        </>
                      )
                    }
                  </B3Spin>
                </DialogContent>
              </B3ConfirmDialog>
            )
          }
        }
      </Formik>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    company: {
      extraFields,
    },
  } = state
  return {
    extraFields,
  }
}

export default connect(mapStateToProps)(AddNewCompany)
