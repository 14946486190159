import uuid from 'uuid/v1'

import {
  GLOBAL_TOGGLE_NAV_COLLAPSE,
  GLOBAL_SET_PAGE_TITLE,
  GLOBAL_CREATE_SNACKBAR_MESSAGE,
  GLOBAL_REMOVE_SNACKBAR_MESSAGE,
  TOGGLE_GLOBAL_LOADING,
} from '../actionTypes'

const initState = {
  isNavCollapse: 'ontouchstart' in document,
  pageTitle: '',
  snackbarMessages: [],
  isGlobalLoading: false,
}
export default (state = initState, action) => {
  switch (action.type) {
    case GLOBAL_TOGGLE_NAV_COLLAPSE:
      return {
        ...state,
        isNavCollapse: !state.isNavCollapse,
      }
    case GLOBAL_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload.pageTitle,
      }
    case GLOBAL_CREATE_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarMessages: [
          ...state.snackbarMessages,
          {
            id: uuid(),
            ...action.payload,
          },
        ],
      }
    case GLOBAL_REMOVE_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarMessages: state.snackbarMessages.filter((item) => item.id !== action.payload.id),
      }
    case TOGGLE_GLOBAL_LOADING:
      return {
        ...state,
        isGlobalLoading: action.payload.isGlobalLoading,
      }
    default:
      return state
  }
}
