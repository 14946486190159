import React from 'react'
import PropTypes from 'prop-types'
import {
  Snackbar,
} from '@material-ui/core'

import B3SnackbarContent from './B3SnackbarContent'

const B3Snackbar = (props) => {
  const {
    messages,
    removeSnackbarMessage,
  } = props
  const isOpen = Boolean(messages.length)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
    >
      <div>
        {
          messages.map((item) => (
            <B3SnackbarContent
              key={item.id}
              {...item}
              onClose={removeSnackbarMessage}
            />
          ))
        }
      </div>
    </Snackbar>
  )
}

B3Snackbar.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    message: PropTypes.node,
  })).isRequired,
  removeSnackbarMessage: PropTypes.func,
}

B3Snackbar.defaultProps = {
  removeSnackbarMessage: () => {},
}

export default B3Snackbar
