import { merge } from 'lodash'
import DateFormatter from './php-date-format'
import b3storage from '../b3storage'

const fmt = new DateFormatter()

const formatCreator = (displayType, handler) => (timestamp, isDateStr = false) => {
  const dateFormat = merge({
    display: 'Y M jS',
    export: 'M jS Y',
    extendedDisplay: 'M jS Y @ g:i A',
    offset: 0,
  }, b3storage.dateFormat.val)
  const display = dateFormat[displayType]

  if (!timestamp) return ''

  const dateTime = isDateStr ? timestamp : (Number.parseInt(timestamp, 10)) * 1000
  const localDate = new Date(dateTime)
  const localTime = localDate.getTime()
  const localOffset = localDate.getTimezoneOffset() * 60000
  const utcTime = localTime + localOffset + dateFormat.offset * 1000
  const zoneDate = new Date(utcTime)

  return fmt[handler](zoneDate, display)
}

const displayFormat = formatCreator('display', 'formatDate')
const extendedDisplayFormat = formatCreator('extendedDisplay', 'formatDate')
const displayParse = formatCreator('display', 'parseDate')
const extendedDisplayParse = formatCreator('extendedDisplay', 'parseDate')

export default {
  displayFormat,
  extendedDisplayFormat,
  displayParse,
  extendedDisplayParse,
}
