export const quotesStatusText = {
  0: 'NEW',
  1: 'SENT',
  2: 'ORDERED',
  3: 'EXPIRED',
  6: 'AWAITING APPROVAL',
  7: 'REJECTED',
}

const quotesStatusColor = {
  0: 'info',
  1: 'purple',
  2: 'success',
  3: 'error',
  6: 'warning',
  7: 'grey',
}

export default (code) => ({
  text: quotesStatusText[code],
  color: quotesStatusColor[code],
})
