const env = {
  mock: {
    baseUrl: 'https://dev.bundleb2b.net/apidoc-server/app/mock/17',
    socketUrl: '',
    qtyTemplateUrl:
      'https://s3-us-west-2.amazonaws.com/bundleb2b-v2.0-qty/qty_management_template.xls',
  },
  dev: {
    baseUrl: 'https://dev-v2.bundleb2b.net/api',
    socketUrl: 'wss://dev-v2.bundleb2b.net/ws',
    qtyTemplateUrl:
      'https://s3-us-west-2.amazonaws.com/bundleb2b-v2.0-qty/qty_management_template.xls',
  },
  staging: {
    baseUrl: 'https://staging-v2.bundleb2b.net',
    socketUrl: 'wss://staging-v2.bundleb2b.net/ws',
    qtyTemplateUrl:
      'https://s3-us-west-2.amazonaws.com/bundleb2b-v2.0-qty/qty_management_template.xls',
  },
  product: {
    baseUrl: 'https://api.bundleb2b.net',
    socketUrl: 'wss://api.bundleb2b.net/ws',
    qtyTemplateUrl:
      'https://s3-us-west-2.amazonaws.com/bundleb2b-v2.0-qty/qty_management_template.xls',
  },
}

export default env[process.env.REACT_APP_ENV]
