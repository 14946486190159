import {
  TOGGLE_EXPORT_ORDERS_READY_STATUS,
} from '../actionTypes'

export const toggleExportOrdersReadyStatus = ({
  stateKey = 'isExportOrdersReady',
  status = '0',
}) => ({
  type: TOGGLE_EXPORT_ORDERS_READY_STATUS,
  payload: {
    stateKey,
    status,
  },
})
