import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Typography,
  withStyles,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import {
  MoreVert as MoreIcon,
} from '@material-ui/icons'

import {
  B3Card,
  B3Tag,
  B3TableCell as TableCell,
  NoData,
  B3ConfirmDialog,
} from '../../components'

import b3request from '../../service'

import { userStatusText } from '../../utils/getUserStatusBycode'
import {
  getUserStatusBycode,
  snackbar,
  checkOneOfPermissions,
} from '../../utils'
import locales from '../../locales/en-US'

import UserForm from './UserForm'

class Users extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      table: PropTypes.string.isRequired,
      editIconButton: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor() {
    super()

    this.state = {
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      users: [],
      isLoading: false,
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
      isUserFormOpen: false,
      isDeleteUserDialogShow: false,
      isDeletingUser: false,
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  componentWillUnmount() {
    this.setState = () => false
  }

  get currentPage() {
    const {
      pagination: {
        offset,
        limit,
      },
    } = this.state
    return offset / limit
  }

  getUsers = async () => {
    this.setState({
      isLoading: true,
    })
    const {
      pagination,
      pagination: { offset, limit },
    } = this.state
    try {
      const resp = await b3request.users.getAdminUsers({
        offset,
        limit,
      })
      this.setState({
        users: resp.list,
        pagination: {
          ...pagination,
          offset: resp.pagination.offset,
          totalCount: resp.pagination.totalCount,
        },
      })
    } catch (error) {
      snackbar.error(error.message)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  handlePageChange = (event, page) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: page * pagination.limit,
        },
      },
      this.getUsers,
    )
  }

  handleRowsPerPageChange = (event) => {
    const { pagination } = this.state
    this.setState(
      {
        pagination: {
          ...pagination,
          offset: 0,
          limit: event.target.value,
        },
      },
      this.getUsers,
    )
  }

  handleUserFromShow = (actionsRowId = '') => () => {
    this.setState({
      isUserFormOpen: true,
      actionsRowId,
    })
  }

  handleUserFromHide = (refresh) => {
    const { pagination } = this.state
    this.setState({
      isUserFormOpen: false,
    })
    if (refresh) {
      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
        actionsRowId: '',
      }, this.getUsers)
    }
  }

  handleMoreActionsClick = (actionsRowId) => (e) => {
    this.setState({
      actionsPopoverAnchorEl: e.currentTarget,
      actionsRowId,
    })
  }

  handleMoreActionsPopoverClose = () => {
    this.setState({
      actionsPopoverAnchorEl: null,
      actionsRowId: '',
    })
  }

  getActionStatusText = (status) => {
    const key = status === '0' ? '1' : '0'
    return userStatusText[key]
  }

  handleToggleUserStatus = ({
    id,
    status,
    roleId,
  }) => async () => {
    const {
      pagination,
    } = this.state

    this.setState({
      isLoading: true,
    })

    try {
      await b3request.users.updateAdminUser(id, {
        status: ['0', '1'].filter((code) => code !== status)[0],
        roleId,
      })
      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getUsers)
    } catch (error) {
      snackbar.error(error)
    }
    this.setState({
      isLoading: false,
      actionsRowId: '',
    })
  }

  handleDeleteUserShow = (actionsRowId) => async () => {
    this.setState({
      actionsRowId,
      isDeleteUserDialogShow: true,
    })
  }

  handleDeleteUserCancel = () => {
    this.setState({
      isDeleteUserDialogShow: false,
      actionsRowId: '',
    })
  }

  handleUserDelete = async () => {
    this.setState({
      isDeletingUser: true,
    })
    const {
      actionsRowId,
      pagination,
    } = this.state

    try {
      await b3request.users.deleteAdminUser(actionsRowId)
      snackbar.success(locales['app.tips.users.deleteUsersSuccessfully'])
      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
      }, this.getUsers)
    } catch (error) {
      snackbar.error(locales['app.tips.users.deleteUsersFailed'])
    }
    this.setState({
      isDeleteUserDialogShow: false,
      isDeletingUser: false,
      actionsRowId: '',
    })
  }

  render() {
    const { classes } = this.props
    const {
      users,
      isLoading,
      pagination,
      actionsPopoverAnchorEl,
      actionsRowId,
      isUserFormOpen,
      isDeleteUserDialogShow,
      isDeletingUser,
    } = this.state

    return (
      <B3Card
        isLoading={isLoading}
        extra={(
          <React.B3PermissionContainer
            permissions={['15102', '15200']}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleUserFromShow()}
            >
              Add New User
            </Button>
          </React.B3PermissionContainer>
        )}
      >
        {users.length === 0 ? (
          <NoData />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '35%' }}>Email</TableCell>
                <TableCell style={{ width: '35%' }}>User Role</TableCell>
                <TableCell>Status</TableCell>
                {
                  checkOneOfPermissions([['15101', '15300'], ['15400']]) && (
                    <TableCell style={{ width: 100 }}>Actions</TableCell>
                  )
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>
                    <span>
                      {user.email}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>{user.roleName}</span>
                  </TableCell>
                  <TableCell>
                    <B3Tag color={getUserStatusBycode(user.status).color}>
                      {getUserStatusBycode(user.status).text}
                    </B3Tag>
                  </TableCell>
                  {
                    checkOneOfPermissions([['15101', '15300'], ['15400']]) && (
                      <TableCell>
                        {
                          user.isOwner !== '1' && (
                            <>
                              <IconButton
                                className={classes.editIconButton}
                                onClick={this.handleMoreActionsClick(user.id)}
                              >
                                <MoreIcon />
                              </IconButton>
                              <Menu
                                anchorEl={actionsPopoverAnchorEl}
                                open={actionsRowId === user.id}
                                onClose={this.handleMoreActionsPopoverClose}
                              >
                                <React.B3PermissionContainer
                                  permissions={['15101', '15300']}
                                >
                                  {({
                                    ref,
                                  }) => (
                                    <>
                                      <MenuItem
                                        onClick={this.handleUserFromShow(user.id)}
                                        ref={ref}
                                      >
                                        <Typography variant="subtitle2">
                                          Edit
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={this.handleToggleUserStatus(user)}
                                        ref={ref}
                                      >
                                        <Typography variant="subtitle2">
                                          {this.getActionStatusText(user.status)}
                                        </Typography>
                                      </MenuItem>
                                    </>
                                  )}
                                </React.B3PermissionContainer>
                                <React.B3PermissionContainer
                                  permissions={['15400']}
                                >
                                  {({
                                    ref,
                                  }) => (
                                    <MenuItem
                                      onClick={this.handleDeleteUserShow(user.id)}
                                      ref={ref}
                                    >
                                      <Typography variant="subtitle2">
                                        Delete
                                      </Typography>
                                    </MenuItem>
                                  )}
                                </React.B3PermissionContainer>
                              </Menu>
                            </>
                          )
                        }
                      </TableCell>
                    )
                  }

                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pagination.totalCount}
          rowsPerPage={pagination.limit}
          page={this.currentPage}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
        />

        {
          isUserFormOpen && (
            <UserForm
              id={actionsRowId}
              onCancel={this.handleUserFromHide}
            />
          )
        }

        {
          isDeleteUserDialogShow && (
            <B3ConfirmDialog
              onClose={this.handleDeleteUserCancel}
              onConfirm={this.handleUserDelete}
              isSubmitDisabled={isDeletingUser}
              isCancelDisabled={isDeletingUser}
              isSpinning={isDeletingUser}
              confirmText="Delete"
            >
              <DialogContent>
                <DialogContentText>
                  {locales['app.tips.users.deleteConfirm']}
                </DialogContentText>
              </DialogContent>
            </B3ConfirmDialog>
          )
        }
      </B3Card>
    )
  }
}

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  editIconButton: {
    padding: 4,
    marginLeft: 4,
    display: 'inline-block',
  },
}

export default withStyles(styles)(Users)
