import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  CardHeader,
  Avatar,
  styled,
} from '@material-ui/core'

import CountUp from 'react-countup'

const ColorAvatar = styled(Avatar)((props) => ({
  backgroundColor: props.theme.palette.primary.main,
}))

const GridItem = ({
  avatarLetter,
  title,
  xs,
}) => (
  <Grid
    item
    xs={xs}
  >
    <CardHeader
      avatar={(
        <ColorAvatar>
          <CountUp
            start={0}
            end={avatarLetter > 99 ? 99 : avatarLetter}
            suffix={avatarLetter > 99 ? '+' : ''}
          />
        </ColorAvatar>
      )}
      title={title}
    />
  </Grid>
)

GridItem.propTypes = {
  avatarLetter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  xs: PropTypes.number.isRequired,
}

function Summary(props) {
  const {
    counters,
  } = props
  const xs = counters.length === 3 ? 4 : 3
  return (
    <Grid
      container
    >
      {
        counters.map((counter) => (
          <GridItem
            key={counter.name}
            xs={xs}
            title={counter.label}
            avatarLetter={counter.count}
          />
        ))
      }
    </Grid>
  )
}

Summary.propTypes = {
  counters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  })).isRequired,
}

export default Summary
