import b3request from './base'

// get store configs
export const getStoreConfigs = () => b3request.get('/api/v2/store-configs')

// update store config switch status
export const updateStoreConfigSwitchStatusById = (id, isEnabled) => b3request.put(`/api/v2/store-configs/switch-status/${id}`, { isEnabled })

// update store config notification email
export const updateStoreConfigNotificationEmail = (email) => b3request.put('/api/v2/store-configs/notifications-email', { email })

// update default priceList of store config
export const updateDefaultPriceList = (pricelistId) => b3request.put('/api/v2/store-config/price-list', { pricelistId })

export const getExpiredNotification = () => b3request.get('/api/v2/stores/global-notification')

export const getDateFormat = () => b3request.get('/api/v2/stores/date-format')

export const getStorePayments = () => b3request.get('/api/v2/store/payments')

export const updateDefaultPaymentMethods = (paymentCodes) => b3request.put('/api/v2/store-config/payment-methods', { paymentCodes })

export const refreshStorePayments = () => b3request.get('/api/v2/payments/refresh')
